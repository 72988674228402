import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { AutomationsAction } from "@models/automations/automations_action";
import { ChartFilter } from "@models/automations/chart_filter";
import { Conditional } from "@models/automations/conditional";
import { Delay } from "@models/automations/delay";
import { Filter } from "@models/automations/filter";
import { Flow } from "@models/automations/flow";
import { FlowTemplate } from "@models/automations/flow_template";
import { Node } from "@models/automations/node";
import { NodePath } from "@models/automations/node_path";
import { Observer } from "@models/automations/observer";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { Observable, Subject } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { environment } from "@environments/environment";

@Injectable()
export class FlowService {
  private flowSource$ = new Subject<Flow>();
  private flowIndexSource$ = new Subject<Flow[]>();
  private flowTemplateSource$ = new Subject<FlowTemplate[]>();
  private flow: Flow = new Flow();

  constructor(
    private httpClient: HttpClient,
    private deserializeService: DeserializeService,
  ) {}

  getSource() {
    return this.flowSource$;
  }

  getIndexSource() {
    return this.flowIndexSource$;
  }

  destroy(flowId: number) {
    return this.httpClient.delete<Flow>(`${environment.apiUrl}/v2/automations/flows/${flowId}`);
  }

  build(flowTemplate: FlowTemplate) {
    this.httpClient.post<Flow>(`${environment.apiUrl}/v2/automations/flows/build`, flowTemplate).subscribe(async (response) => {
      const flowResponse = this.deserializeService.deserialize(response);
      this.flowSource$.next(flowResponse);
    });
    return this.flowSource$;
  }

  duplicate(flowId: number, params = {}) {
    this.httpClient.post<Flow>(`${environment.apiUrl}/v2/automations/flows/${flowId}/duplicate`, params).subscribe(async (response) => {
      const flowResponse = this.deserializeService.deserialize(response);
      this.flowSource$.next(flowResponse);
    });
    return this.flowSource$;
  }

  update(flow: Flow, options?: any) {
    const params = {
      description: flow.description,
      include: options ? options.include : "",
      name: flow.name,
      state: flow.state,
    };

    this.httpClient.put<Flow>(`${environment.apiUrl}/v2/automations/flows/${flow.id}`, params).subscribe(async (response) => {
      const flowResponse = this.deserializeService.deserialize(response);
      this.flowSource$.next(flowResponse);
    });
    return this.flowSource$;
  }

  show(flowId: number, params?: any) {
    const headers = new HttpHeaders({ "Cache-Control": "no-cache, no-store, must-revalidate" });
    this.httpClient.get<Flow>(`${environment.apiUrl}/v2/automations/flows/${flowId}`, { headers, params })
      .subscribe(async (response) => {
        const flowResponse = this.deserializeService.deserialize(response);
        flowResponse.nodesV2 = this.buildNodes(response, flowId);
        this.flowSource$.next(flowResponse);
      });
    return this.flowSource$;
  }

  index(params?: any) {
    this.httpClient.get<Flow[]>(`${environment.apiUrl}/v2/automations/flows`, { params }).subscribe(async (response: any) => {
      let flows = this.deserializeService.deserialize(response.data);
      let actions = this.deserializeService.deserialize(response.included);

      flows = flows.map((flow) => Object.assign(new Flow(), flow));

      flows.forEach((flow) => {
        flow.flowActions.forEach((action) => {
          Object.assign(action, actions.find((x) => x.id === action.id));
        });
        flow.nodesV2 = this.buildNodes(response, flow.id);
      }, this);

      this.flowIndexSource$.next(flows);
    }, (resp) => {
      this.flowIndexSource$.next(resp);
    });
    return this.flowIndexSource$;
  }

  library(params?: any) {
    this.httpClient.get<FlowTemplate[]>(`${environment.apiUrl}/v2/automations/flow_templates`,
      { params }).subscribe(async (flowsResponse) => {
      let flows = this.deserializeService.deserialize(flowsResponse);

      flows = flows.map((flow) => Object.assign(new FlowTemplate(), flow));

      this.flowTemplateSource$.next(flows);
    });
    return this.flowTemplateSource$;
  }

  create(flow?: Flow) {
    this.httpClient.post<Flow>(`${environment.apiUrl}/v2/automations/flows`, flow).subscribe(async (response) => {
      const flowResponse = this.deserializeService.deserialize(response);
      this.flowSource$.next(flowResponse);
    });
    return this.flowSource$;
  }

  getChartData(chartFilter: ChartFilter) {
    return this.httpClient.get(`${environment.apiUrl}/v2/automations/flows/chart`, { params: chartFilter as any });
  }

  getPreviewContent(emailType: string, id: number): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}/v2/automations/automations_emails/${id}/show_preview`, {});
  }

  getConfirmationFlow(params?: any): Observable<Flow> {
    const path = `${environment.apiUrl}/v2/automations/flows/confirmation_flow`;
    return this.httpClient.get<Flow>(path, params)
      .pipe(mergeMap(async (response) => {
        const flowResponse = this.deserializeService.deserialize(response);
        return Object.assign(this.flow, flowResponse);
      }));
  }

  private buildNodes(response, flowId): any[] {
    return this.deserializeNodes(this.getNodes(response, flowId), response);
  }

  // The response has both nodes and nodables this finds only the nodes.
  private getNodes(response, flowId) {
    return response.included.filter((x) => x.type === "node" && x.attributes.flowId === Number(flowId));
  }

  // Makes Nodes and nodables based on the nodable type, then assigns them to each other.
  private deserializeNodes(nodes, response): any[] {
    return nodes.map((node) => {
      node.attributes.path = new NodePath(node.attributes.path);
      const newNode = Object.assign(new Node(), node.attributes);
      const nodableData = node.relationships.nodable.data;

      if (nodableData) {
        const nodableJson = response.included.find(
          (x) => x.id === nodableData.id && x.type === nodableData.type,
        );
        const newFilter = Object.assign(new Filter(), nodableJson.attributes.filters);
        nodableJson.attributes.filters = newFilter;
        const nodable = Object.assign(
          this.buildEmptyNodable(nodableData.type), nodableJson.attributes,
        );
        newNode.nodable = nodable;
        nodable.node = newNode;
      }

      return newNode;
    });
  }

  // We need an empty model to assign to, this does that for you based on nodable type.
  private buildEmptyNodable(nodableType: string) {
    switch (nodableType) {
      case "observer":
        return new Observer();
      case "delay":
        return new Delay();
      case "conditional":
        return new Conditional();
      case "triggeredEmail":
      case "automationsEmail":
      case "automationsMessage":
      case "message":
        return new AutomationsAction();
      default:
        return null;
    }
  }
}
