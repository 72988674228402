import { Injectable } from "@angular/core";
import { AuthorizationService } from "@app/shared/services/authorization.service";
import { InstagramService } from "@app/social/services/instagram.service";
import { UserAuthsService } from "@app/social/services/user_auths.service";
import { FacebookPage } from "@models/social/facebook_page";
import { SocialAccount } from "@models/social/social_account";
import { TwitterAuth } from "@models/social/twitter_auth";
import {
  BehaviorSubject, forkJoin, Observable, of,
} from "rxjs";
import { catchError, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SocialAccountsService {
  private socialAccountsSource$ = new BehaviorSubject<SocialAccount[]>(null);

  constructor(
    private userAuthsService: UserAuthsService,
    private authorizationService: AuthorizationService,
    private instagramService: InstagramService,
  ) {}

  // if the behavioral has been set for the social accounts, returns it immediately
  getSocialAccounts(): Observable<SocialAccount[]> {
    return new Observable((subscriber) => {
      if (this.socialAccountsSource$.getValue()) {
        subscriber.next(this.socialAccountsSource$.getValue());
        subscriber.complete();
      }
      this.socialForkJoin().pipe(take(1)).subscribe((rawSocialData) => {
        const accounts: SocialAccount[] = this.processSocialAccounts(rawSocialData);
        // so next call to subscriber does not call database
        this.socialAccountsSource$.next(accounts);
        subscriber.next(accounts); // current return value
        subscriber.complete();
      });
    });
  }

  setSocialAccounts(): void {
    this.socialForkJoin().pipe(take(1)).subscribe((rawSocialData) => {
      const accounts: SocialAccount[] = this.processSocialAccounts(rawSocialData);
      this.socialAccountsSource$.next(accounts);
    });
  }

  removeSocialAccount(platformName: string): void {
    const accountData = this.socialAccountsSource$.getValue();
    if (accountData) {
      this.socialAccountsSource$.next(
        accountData.filter(({ platform }) => platform !== platformName),
      );
    }
  }

  processSocialAccounts(
    rawSocialData: {
      facebook: Record<string, any>,
      instagram: Record<string, any>,
      twitter: Record<string, any>,
    },
  ): SocialAccount[] {
    return Object.entries(
      rawSocialData,
    ).filter(
      ([_, data]: [string, any]) => data && !data.isError,
    ).reduce((result: SocialAccount[], [key, data]) => {
      switch (key) {
        case "instagram":
          return result.concat(this.instagramSocialAccount(data));
        case "facebook":
          return result.concat(this.facebookSocialAccounts(data));
        case "twitter":
          return result.concat(this.twitterSocialAccounts(data));
        default:
          return result;
      }
    }, []);
  }

  // TODO: at some point, these endpoints should be
  // cleaned up and unified to remove the need for forkJoin
  socialForkJoin(): Observable<any> {
    const insta$ = (
      this.instagramService.getAccount().pipe(
        catchError((err) => of({ isError: true, error: err })),
      )
    );
    const twitter$ = this.authorizationService.getAuthorization("twitter").pipe(catchError((err) => of({ isError: true, error: err })));
    const facebook$ = this.authorizationService.getAuthorization("facebook").pipe(catchError((err) => of({ isError: true, error: err })));
    return forkJoin({ instagram: insta$, twitter: twitter$, facebook: facebook$ });
  }

  instagramSocialAccount(instagramAccount): SocialAccount[] {
    instagramAccount.primary = true;
    instagramAccount.selected = false;
    instagramAccount.platform = "instagram";
    return [instagramAccount];
  }

  facebookSocialAccounts(data): SocialAccount[] {
    const facebookPages = data?.pages as FacebookPage[];
    const primaryId = data?.page?.id;
    const hasAuthAndPages = !!data?.enabled && facebookPages && facebookPages.length > 0;
    return hasAuthAndPages ? this.processedFacebookPages(facebookPages, primaryId) : [];
  }

  // important! in the future, we may allow multiple
  // twitter auth thus primary will not necc be the first auth
  private twitterSocialAccounts(authData): SocialAccount[] {
    const twitterAuth = authData as TwitterAuth;
    if (!twitterAuth) {
      return [];
    }
    return this.processedTwitterAccounts([twitterAuth]);
  }

  private processedTwitterAccounts(twitterAuths: TwitterAuth[]): SocialAccount[] {
    return twitterAuths.map((auth, index) => ({
      id: auth.uid,
      primary: index === 0,
      name: auth.profile.name,
      nickname: auth.profile.nickname,
      image: auth.profile.image,
      platform: "twitter",
      selected: false,
    })) as SocialAccount[];
  }

  private processedFacebookPages(
    facebookPages: FacebookPage[],
    primaryId: string,
  ): SocialAccount[] {
    return facebookPages.map((page) => ({
      id: page.id,
      primary: page.id === primaryId,
      name: page.name,
      image: page.image,
      platform: "facebook",
      selected: false,
    })) as SocialAccount[];
  }
}
