import { Component, Input } from "@angular/core";
import { NavLink } from "@models/navlink";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@models/store";

@Component({
  selector: "sb-nav",
  styleUrls: ["./sb-nav.component.scss"],
  templateUrl: "./sb-nav.component.html",
})
export class SbNavComponent {
  @Input()
  store: Store;

  @Input()
  links: NavLink[];

  @Input()
  onlyEmailLists: boolean;

  @Input()
  showCreateButton: boolean = false;

  currentYear: number = new Date().getFullYear();

  hover: string;
  hidingMenu: boolean;
  showNavbar: boolean;

  constructor(
    private router: Router,
    public modalService: NgbModal,
  ) {}

  linkActive(url: string): boolean {
    return url === this.router.url;
  }

  navigate(url: string) {
    this.router.navigateByUrl(url);
  }

  clickEvent(link: NavLink): void {
    if (this.hover && this.hover == link.label) {
      this.hover = null;
    } else if (!link.url) {
      this.hover = link.label;
    } else if (!link.url || link.disabled) {
      return;
    } else if (link.external) {
      this.hover = null;
      window.open(link.url, '_blank');
    } else {
      this.hover = null;
      this.router.navigateByUrl(link.url);
    }
  }

  mouseEnterEvent(link: NavLink): void {
    this.hidingMenu = false;
    if (link.children && link.children.length > 0 && !link.disabled) {
      this.hover = link.label;
    } else {
      this.hover = null;
    }
  }

  mouseLeaveEvent(): void {
    this.hidingMenu = true;
    setTimeout(() => {
      if (this.hidingMenu) {
        this.hover = null;
      }
    }, 500);
  }
}
