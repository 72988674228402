import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { mergeMap } from "rxjs/operators";
import { PolicyDocument } from '@models/unique_coupon_campaigns/policy_document';
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class S3UploadApiService {
  private base = `${environment.apiUrl}/v2/s3_uploads`;

  constructor(
    private http: HttpClient,
    private deserializeService: DeserializeService,
  ) {}

  checkCsvDownloadStatus(id: string) {
    const path = `${this.base}/${id}/check_csv_download_status`;

    return this.http.get(path);
  }

  checkCsvUploadStatus(id: string) {
    const path = `${this.base}/${id}/check_csv_upload_status`;

    return this.http.get(path);
  }

  policyDocument(data): Observable<PolicyDocument> {
    const path = `${this.base}/policy_document`;

    return this.http.get<PolicyDocument>(path, { params: data }).pipe(
      mergeMap(async (response) => this.deserializeService.deserialize(response)),
    );
  }

  // prefer to use #uploadFile below here because it was already in use
  upload(url, data) {
    return this.http.post(url, data);
  }

  // gets policyDoc from backend, uploads file, returns url for file/asset via Observable
  // acl values info https://docs.aws.amazon.com/AmazonS3/latest/dev/acl-overview.html#canned-acl
  // pathKey decoder
  //  ecb => springmail
  //  ucc => universal_coupon_campaign
  //  sh => social_hub
  uploadFile(
    file: File,
    pathKey = "ecb",
    aclValue: "private" | "public-read" = "public-read",
    queryParams: string = ""): Observable<any> {
    const params = this.policyParams(pathKey, aclValue);
    const filename = `${Date.now()}${file.name}`;
    return new Observable<any>((subscriber) => {
      this.policyDocument(params).subscribe((policyDocument: PolicyDocument) => {
        this.http.post<any>(policyDocument.uri,
          this.generateForm(policyDocument, file, filename, aclValue))
          .subscribe((_response: any) => {
            return subscriber.next({
              url: this.genS3FileUrl(policyDocument, filename + queryParams),
              policyDocument,
              filename,
            });
          }, (errorResponse) => {
            return subscriber.error(errorResponse);
          });
      });
    });
  }

  uploadImage(file: File): Observable<any> {
    if (file?.name && this.isImageExtensionAllowed(file.name)) {
      const format = this.genImageCompressionFormat(file.name);
      return this.uploadFile(file, "ecb", "public-read", `?format=${format}&width=600`);
    }

    return new Observable<any>((subscriber) => {
      return subscriber.error("Only JPG, JPEG, PNG, WEBP and GIF files are allowed.");
    });
  }

  // generates policy body object with file and pathKey
  private policyParams(
    pathKey: string,
    aclValue: string
  ): Record<string, string> {
    return {
      path_key: pathKey,
      acl: aclValue,
    };
  }

  private isImageExtensionAllowed(filePath: string): boolean {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.webp)$/i;
    return !!allowedExtensions.exec(filePath);
  }

  private genImageCompressionFormat(filePath: string): string {
    const gifExtension = /(\.gif)$/i;
    if (!!gifExtension.exec(filePath)) {
      return "gif";
    } else {
      return "png";
    }
  }

  private genS3Key(policyDocument: PolicyDocument, filename: string): any {
    return policyDocument.key + filename;
  }

  // returns the url at which the uploaded s3 item will be found
  private genS3FileUrl(policyDocument: PolicyDocument, filename: string): string {
    return `${this.genPolicyUri(policyDocument)}/${this.genS3Key(policyDocument, filename)}`;
  }

  private genPolicyUri(policyDocument: PolicyDocument): string {
    return policyDocument.cloudfrontDomain;
  }

  private generateForm(policyDoc: PolicyDocument,
    file: any, filename: string,
    aclValue: string): FormData {
    const formData = new FormData();
    formData.append("AWSAccessKeyId", policyDoc.accessKey);
    formData.append(
      "Content-Type",
      file.type !== "" ? file.type : "application/octet-stream"
    );
    formData.append("acl", aclValue);
    formData.append("filename", filename);
    formData.append("key", this.genS3Key(policyDoc, filename));
    formData.append("policy", policyDoc.policy);
    formData.append("signature", policyDoc.signature);
    formData.append("file", file);
    return formData;
  }
}
