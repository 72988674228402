import { Base } from "./base";
import { AuthorizationProfile } from "@models/authorization_profile";

export class Authorization extends Base {
  provider: string;
  storeId: number;
  enabled: boolean;
  connecting: boolean;
  connected: boolean;
  authable: boolean;
  valid: boolean;
  working: boolean;
  pictureUrl: string;
  adsEnabled: boolean;
  messengerEnabled: boolean;
  profile: AuthorizationProfile;
  // TODO: model these!
  pages: any[];
  page: any;
  adAccount: any;
  adAccounts: any[];
  errors: string[];
}
