<ng-container>
  <div class="modal-body">
    <div class="container-fluid mb-5">
      <div class="row justify-content-center">
        <div class="col-10">
          <p *ngFor="let alert of alerts">
            <ngb-alert [type]="alert.type">{{alert.message}}</ngb-alert>
          </p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12 d-flex flex-column align-items-center text-center mt-3">
          <h2 class="pb-0">{{ maxSelect === 1 ? 'Select a Product' : 'Select Products' }}</h2>
          <p *ngIf="maxSelect > 1"
             class="small-text mr-4 align-self-end">Products Selected: ({{selectedProducts.length}})</p>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col">
          <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs nav-justified">
            <li *ngFor="let tab of tabs" [ngbNavItem]="tab">
              <a ngbNavLink>{{ tab | titlecase }}</a>
              <ng-template ngbNavContent>
                <div class="bottom-cage">
                  <div *ngIf="tab === 'search'">
                    <div class="d-flex align-items-center position-relative pt-4 px-4">
                      <input class="form-control" [(ngModel)]="search" (input)="searchProducts($event)">
                      <i *ngIf="search.length === 0" class="fas fa-search text-muted search-icon"></i>
                      <i *ngIf="search.length > 0" class="fas fa-times-circle text-muted search-icon"
                         (click)="search = ''"></i>
                    </div>

                    <div class="px-4 pt-2">
                      <table class="table table-striped border-bottom">
                        <tr *ngFor="let product of filteredProducts.slice((productFilter.page - 1) * productFilter.perPage, productFilter.page * productFilter.perPage)" class="hover-bg-success"
                            (click)="isSelected(product) ? clearSelection(product) : selectProduct(product)">
                          <td *ngIf="maxSelect > 1">{{selectedPosition(product)}}</td>
                          <td *ngIf="maxSelect === 1">
                            <i *ngIf="isSelected(product)" class="fas fa-check text-primary"></i>
                          </td>
                          <td><img [src]="product.imageUrl" class="img-zoom"></td>
                          <td>{{product.name}}</td>
                          <td>{{product.sku}}</td>
                          <td *ngIf="maxSelect > 1">
                            <i class="fas"
                               [class]="isSelected(product) ? 'fa-minus-circle text-danger' : 'fa-plus-circle text-primary'"></i>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <ngb-pagination
                      *ngIf="filteredProducts.length > 0"
                      [(page)]="productFilter.page"
                      [pageSize]="productFilter.perPage"
                      [collectionSize]="filteredProducts.length"
                      [maxSize]="5"
                      size="sm"
                      class="d-flex justify-content-center mb-4"
                    ></ngb-pagination>
                  </div>
                  <div class="p-4" *ngIf="tab !== 'search' && productsForTab(tab).length > 0; else noProductsWarning">
                    <table class="table table-striped border-bottom">
                      <tr *ngFor="let product of productsForTab(tab)" class="hover-bg-success"
                          (click)="isSelected(product) ? clearSelection(product) : selectProduct(product)">
                        <td *ngIf="maxSelect > 1">{{selectedPosition(product)}}</td>
                        <td *ngIf="maxSelect === 1">
                          <i *ngIf="isSelected(product)" class="fas fa-check text-primary"></i>
                        </td>
                        <td><img [src]="product.imageUrl" class="img-zoom"></td>
                        <td>{{product.name}}</td>
                        <td>{{product.sku}}</td>
                        <td *ngIf="maxSelect > 1">
                          <i class="fas"
                             [class]="isSelected(product) ?  'fa-minus-circle text-danger' : 'fa-plus-circle text-primary'"></i>
                        </td>
                      </tr>
                    </table>
                  </div>

                  <ng-template #noProductsWarning>
                    <div class="text-center my-3 text-blue" *ngIf="tab !== 'search'">
                      <i class="fas fa-exclamation-triangle fa-3x"></i>
                      <p class="mt-2">Your products have no {{tab}} in the last 30 days.</p>
                    </div>
                  </ng-template>
                </div>
              </ng-template>
            </li>
          </ul>

          <div *ngIf="!productsByMetricLoading; else loadingSpinner" [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer bg-secondary">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6 d-flex justify-content-center">
          <button (click)="close()" class="btn btn-outline-primary px-5">
            Cancel
          </button>
        </div>
        <div class="col-6 d-flex justify-content-center">
          <button (click)="accept()" class="btn btn-primary px-5">
            Accept
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loadingSpinner>
  <div class="d-flex justify-content-center my-5">
    <i class="fas fa-gear fa-spin fa-2x text-primary"></i>
  </div>
</ng-template>

<ng-template #productTemplate let-product="result" let-search="term">
  <div class="display-flex flex-row">
    <img [src]="product.imageUrl" height="50" class="mr-2">
    <ngb-highlight [result]="product.name" [term]="search"></ngb-highlight>
  </div>
</ng-template>
