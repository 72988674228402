import { CanDeactivate } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

export interface CanComponentDeactivateUnsavedChanges {
  canDeactivateChangesSaved: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard
  implements CanDeactivate<CanComponentDeactivateUnsavedChanges>
{
  canDeactivate(component: CanComponentDeactivateUnsavedChanges): boolean {
    if (component.canDeactivateChangesSaved()) {
      return true;
    }
    // eslint-disable-next-line no-restricted-globals
    return confirm(
      "WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes."
    );
  }
}
