<div class="w-100" *ngIf="!loading">
  <button #dateRangePopover="ngbPopover"
          [ngbPopover]="dateRangeContent" [autoClose]="'outside'"
          [placement]="popoverPlacement"
          class="btn btn-secondary font-weight-normal w-100">
    <div *ngIf="hideDate" class="m-auto d-flex justify-content-center">
      <fa-icon [icon]="['fas', 'calendar']"></fa-icon>
      <span class="ml-1">Date</span>
    </div>
    <div *ngIf="!hideDate" class="d-inline-flex justify-content-between align-items-center">
      <fa-icon *ngIf="labelColor" [icon]="['fas', 'square']" [style.color]="labelColor" class="mr-1"></fa-icon>
      <span>
        <span *ngIf="!startInfinity">{{ startDate | date: 'shortDate' }}</span>
        <span *ngIf="startInfinity">-&nbsp;<fa-icon [icon]="['fas', 'infinity']"></fa-icon>&nbsp;</span>
        <strong> to </strong>
        <span *ngIf="!endInfinity">{{ endDate | date: 'shortDate' }}</span>
        <span>&nbsp;<fa-icon *ngIf="endInfinity" [icon]="['fas', 'infinity']"></fa-icon>&nbsp;</span>
        <span *ngIf="!startInfinity && !endInfinity"> -
          <strong>{{ (endDate.getTime() - startDate.getTime()) / 86400000 | number:'1.0-0' }} days</strong>
        </span>
      </span>
      <fa-icon [icon]="['fas', v2Toggle ? 'caret-down' : 'chevron-down']" class="green-color ml-1"></fa-icon>
    </div>
  </button>
  <ng-template #dateRangeContent>
    <div class="d-flex flex-column align-items-center p-3">
      <div class="btn-group btn-group-sm w-100">
        <button (click)="setWindow('seven')"
                [ngClass]="windowIsSelected('seven') ? 'btn btn-primary' : 'btn btn-secondary'">7 Days
        </button>
        <button (click)="setWindow('thirty')"
                [ngClass]="windowIsSelected('thirty') ? 'btn btn-primary' : 'btn btn-secondary'">30 Days
        </button>
        <button (click)="setWindow('ninety')"
                [ngClass]="windowIsSelected('ninety') ? 'btn btn-primary' : 'btn btn-secondary'">90 Days
        </button>
        <button (click)="setWindow('year')"
                [ngClass]="windowIsSelected('year') ? 'btn btn-primary' : 'btn btn-secondary'">Year
        </button>
      </div>

      <div class="my-3 d-flex justify-content-between">
        <div class="mr-2" style="width: 128px;">
          <label>Start</label>
          <div class="input-group">
            <input #dpFromDate class="form-control pr-0 border-right-0" placeholder="dd/mm/yyyy" name="dpFromDate"
                   [value]="(!fromInfinity && fromDate) ? formatter.format(fromDate) : ''"
                   (focusout)="fromDate = validateInput(fromDate, dpFromDate.value)"
                   (keyup.enter)="fromDate = validateInput(fromDate, dpFromDate.value)">
            <div class="input-group-append">
              <span class="input-group-text bg-white text-black">
                <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
              </span>
            </div>
          </div>
        </div>

        <div class="ml-2" style="width: 128px;">
          <label>End</label>
          <div class="input-group">
            <input #dpToDate class="form-control pr-0 border-right-0" placeholder="dd/mm/yyyy" name="dpToDate"
                   [value]="(!toInfinity && toDate) ? formatter.format(toDate || today) : ''"
                   (focusout)="toDate = validateInput(toDate, dpToDate.value)"
                   (keyup.enter)="toDate = validateInput(toDate, dpToDate.value)">
            <div class="input-group-append">
              <span class="input-group-text bg-white text-black">
                <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
              </span>
            </div>
          </div>
        </div>
      </div>

      <ngb-datepicker class="border-0" name="datepicker" [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate"
        [navigation]="'arrows'">
      </ngb-datepicker>

      <ng-template #t let-date let-focused="focused">
        <span class="custom-day border-bottom border-right border-light" [class.focused]="focused" [class.range]="isRange(date)"
          [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
          (mousedown)="onDateSelection(date)"
          (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
      </ng-template>

      <div class="mt-2 d-flex w-100">
        <button (click)="onCancel(dateRangePopover)" class="btn w-100 btn-secondary mr-2">Cancel</button>
        <button (click)="onConfirm(dateRangePopover)" [disabled]="!fromDate || !toDate" class="btn w-100 btn-primary ml-2">Confirm</button>
      </div>
    </div>
  </ng-template>
</div>
