<div *ngIf="!loading && !inputHidden" class="w-100 border d-flex justify-content-between">
  <input class="form-control bg-white cursor-pointer" placeholder="Time" [value]="displayTime()" readonly
         #timePickerPopover="ngbPopover"
         placement="top"
         [ngbPopover]="timePickerContent"
         [autoClose]="'outside'"
         (hidden)="onChange()">

  <fa-icon [icon]="icon" class="input-icon" size="{{iconSize}}"></fa-icon>
</div>

<fa-icon [icon]="icon" size="{{iconSize}}" #timePickerPopover="ngbPopover" *ngIf="inputHidden"
  placement="top" class="cursor-pointer"
  [ngbPopover]="timePickerContent"
  [autoClose]="'outside'">
</fa-icon>

<ng-template #timePickerContent>
  <div class="d-flex p-2">
    <div class="btn-group btn-group-vertical p-1 m-0">
      <ng-container *ngFor="let h of hourButtons">
        <button (click)="setHour(h)"
                [class.btn-dark]="hour === h"
                [class.btn-outline-dark]="hour !== h"
                class="btn btn-custom rounded m-0 my-1 pt-2">{{ h }}</button>
      </ng-container>
    </div>
    <div class="btn-group btn-group-vertical p-1 m-0">
      <ng-container *ngFor="let m of minuteButtons">
        <button (click)="setMinute(m)"
                [class.btn-dark]="minute === m"
                [class.btn-outline-dark]="minute !== m"
                class="btn btn-custom rounded m-0 my-1 pt-2">{{ m }}</button>
      </ng-container>
    </div>
    <div class="btn-group btn-group-vertical p-1 m-0">
      <button (click)="setPeriod('AM')"
              [class.btn-dark]="period === 'AM'"
              [class.btn-outline-dark]="period !== 'AM'"
              class="btn btn-custom rounded m-0 my-1 pt-2">AM</button>
      <button (click)="setPeriod('PM')"
              [class.btn-dark]="period === 'PM'"
              [class.btn-outline-dark]="period !== 'PM'"
              class="btn btn-custom rounded m-0 my-1 pt-2">PM</button>
    </div>
  </div>
</ng-template>
