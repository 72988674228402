import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "humanize",
})
export class HumanizePipe implements PipeTransform {
  transform(item: string | number) {
    const toReturn = parseInt(item.toString(), 10);
    if (Math.abs(toReturn) > 999999) {
      return (`${Math.sign(toReturn) * Math.floor(Math.abs(toReturn) / 100000) / 10}M`);
    } if (Math.abs(toReturn) > 9999) {
      return (`${Math.sign(toReturn) * Math.floor(Math.abs(toReturn) / 1000)}K`);
    } if (Math.abs(toReturn) > 999) {
      return (`${Math.sign(toReturn) * Math.floor(Math.abs(toReturn) / 100) / 10}K`);
    }
    return item.toString();
  }
}
