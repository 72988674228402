import {
  Component, EventEmitter, Input, Output,
} from "@angular/core";
import { FacebookGeoLocation } from "@models/social/facebook_geo_location";
import { FacebookRestrictions } from "@models/social/facebook_restrictions";
import { NgbActiveModal, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "facebook-restrictions",
  styleUrls: ["./facebook_restrictions.component.scss"],
  templateUrl: "./facebook_restrictions.component.html",
})
export class FacebookRestrictionsComponent {
  restrictionsDisplay = "";
  modalRef: NgbModalRef;
  @Output()
  onRestrictionsChange = new EventEmitter<FacebookRestrictions>();

  @Input()
  restrictions: FacebookRestrictions = {
    ageMin: null,
    countries: [],
    regions: [],
    cities: [],
    zips: [],
  };

  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal) {}

  open(content): void {
    this.modalRef = this.modalService.open(content, { ariaLabelledBy: "facebook-restrictions-modal", size: "lg" });
  }

  close(): void {
    this.modalRef.dismiss();
  }

  accept(): void {
    this.setRestrictionsDisplay();
    this.onRestrictionsChange.emit(this.restrictions);
    this.modalRef.dismiss();
  }

  removeLocation(locationGroup: "countries" | "regions" | "cities" | "zips",
    deleteLocation: FacebookGeoLocation): void {
    const locations = this.restrictions[locationGroup];
    const deleteIndex = locations.findIndex((location) => location.id === deleteLocation.id);
    locations.splice(deleteIndex, 1);
  }

  // builds a string to display all restrictions
  private setRestrictionsDisplay(): void {
    this.restrictionsDisplay = Object.entries(this.restrictions)
      .filter(([_, value]) => typeof value === "number" || value?.length > 0).reduce((result, [key, value]) => {
        if (key === "ageMin") {
          return `${result}age: +${value}; `;
        }
        const joinedLocationNames = value.map(({ name }) => name).join(", ");
        return `${result}${key}: ${joinedLocationNames};`;
      }, "");
  }
}
