import { Injectable } from "@angular/core";
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class DateParserFormatterService extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    const date = new Date(value);
    return {
      day: date.getUTCDate(),
      month: date.getUTCMonth() + 1,
      year: date.getUTCFullYear(),
    };
  }

  format(date: NgbDateStruct): string {
    return date ? new Date(date.year, date.month - 1, date.day).toLocaleDateString() : null;
  }
}
