import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class AdrollConfigurationService {
  private uri = `${environment.apiUrl}/v2/adroll_configurations`;
  private readonly headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({
      Accept: "application/json",
    });
  }

  create(params): Observable<any> {
    return this.http.post<any>(this.uri, params);
  }

  show(params?: any): Observable<any> {
    return this.http.get<any>(this.uri, { params });
  }

  update(params): Observable<any> {
    const { headers } = this;
    return this.http.put<any>(this.uri, params, { headers });
  }
}
