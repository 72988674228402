<div class="modal-body">
  <div class="container-fluid">

    <div *ngIf="generatingAiContent" class="position-fixed fixed-left w-100 h-100 loading-ai-container">
      <div>
        <fa-icon [icon]="['fas', 'cog']" [spin]="true" size="3x" class="green-color"></fa-icon>
        <p class="white-color">AI can take from a few seconds to a minute to get results.</p>
      </div>
    </div>

    <div class="row" *ngIf="postSuccess">
      <div class="col-12" >
        <ngb-alert [type]="'success'">Your post has been scheduled!</ngb-alert>
      </div>
    </div>
    <div class="row" *ngIf="errors.length > 0">
      <div class="col-12" *ngFor="let error of errors; let i = index;">
        <ngb-alert [type]="'danger'" (close)="this.errors.splice(i, 1);">{{error}}</ngb-alert>
      </div>
    </div>
    <div class="row" *ngIf="productLinkInstructions">
      <div class="col-12">
        <ngb-alert [type]="'info'" (closed)="productLinkInstructions = false">
          Please verify the link placement and {{ postDatetime ? 'schedule' : 'post' }} when ready
        </ngb-alert>
      </div>
    </div>
    <div class="row">
      <div class="col-5 pr-3">
        <div class="mb-2">
          Post to
        </div>
        <div class="mb-2">
          <social-account-selector
              [socialAccounts]="socialAccounts"
              [checkIgPostPermission]="true"
              (primaryFacebookAccount)="primaryFacebookAccount = $event"
              (primaryTwitterAccount)="primaryTwitterAccount = $event"
              (primaryInstagramAccount)="primaryInstagramAccount = $event"
              (socialAccountsChange)="onSelectedAccountsChange($event)">
          </social-account-selector>
        </div>

        <div class="d-flex justify-content-between mb-2">
          <span>Post text</span>
          <span>
            <fa-icon [icon]="['fab', 'facebook-square']" class="text-facebook"></fa-icon>
            63,206
            <span>
              <fa-icon [icon]="['fab', 'instagram-square']" class="text-instagram pl-2"></fa-icon>
            2,200
            </span>
          </span>
        </div>
        <div class="form-group">
          <textarea class="form-control" rows="5" [(ngModel)]="socialBlastParams.content" (keyup)="calculateContentLength()" required></textarea>
          <div class="container">
            <div class="row mt-1">
              <div class="col-auto mr-auto p-0">
                <button type="button" class="btn btn-secondary btn-sm" (click)="openAIDesigner()" *ngIf="aiDesignerEnabled">
                  <fa-icon [icon]="['fas', 'user-robot']" class="pr-2"></fa-icon>Generate text with AI
                </button>
              </div>
              <div class="col-auto p-0 mt-1">
                <span [ngClass]="{'text-danger font-weight-bold': contentLength > maxPostLength}">{{ contentLength }} / {{ maxPostLength | number }}</span>
              </div>

              <div class="alert alert-danger mt-1" *ngIf="aiTookForever" role="alert">
                <strong>Oops!</strong> It looks like our AI Designer is not working right now. Please try again later.
              </div>
            </div>
          </div>
        </div>

        <div class="row no-gutters max-height-300 overflow-x-hidden overflow-y-auto">
          <div class="col-12 border p-2 mb-2 rounded" *ngFor="let file of attachedFiles; let i = index;">
            <div class="row">
              <div class="col-4">
                <img class="img-fluid img-thumbnail"
                     *ngIf="!file.imageContentType.includes('video'); else videoTemplate"
                     [src]="file.imageUrl" alt="">
                <ng-template #videoTemplate>
                  <video controls class="embed-responsive">
                    <source class="embed-item"
                            [src]="file.imageUrl"
                            [type]="file.imageContentType">
                  </video>
                </ng-template>
              </div>
              <div class="col-8">
                <div class="d-flex justify-content-between">
                  <p>{{file.fileName}}</p>
                  <fa-icon [icon]="['fas', 'times-circle']" class="text-left cursor-pointer" (click)="deleteAttachment(i)"></fa-icon>
                </div>
                <p>{{file.imageFileSize / 1000 | number: ".1" }} kb</p>
                <form class="mb-0" *ngIf="!(file.imageContentType.includes('video') || file.imageContentType.includes('gif'))">
                  <!-- toggle link (product image only) -->
                  <div class="form-group form-checkbox d-flex align-items-center" *ngIf="file.imageContentType === 'product_image'">
                    <input type="checkbox" id="customCheck{{i}}" class="mr-1"
                           [(ngModel)]="socialBlastParams.socialBlastAttachmentsAttributes[i].displayProductImageLink"
                           (ngModelChange)="onToggleProductLink($event)"
                           [ngModelOptions]="{standalone: true}">
                    <label class="m-0" for="customCheck{{i}}">Product Link in text</label>
                  </div>
                  <!-- add alt text -->
                  <div class="form-group">
                    <label>alt text (optional)
                      <input class="form-control"
                             [(ngModel)]="socialBlastParams.socialBlastAttachmentsAttributes[i].altText"
                             [ngModelOptions]="{standalone: true}">
                    </label>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <image-upload [displayUploadedImage]="false" [accept]="'*'"
                      [prompt]="'drag and drop image, video or GIF or click to select'"
                      (upload)="onUpload($event.files)">
        </image-upload>
        <div *ngIf="assetLoadProgress > 0" class="margin-top-5">
          <ngb-progressbar
            [value]="assetLoadProgress" [striped]="true" height="1rem"
            [type]="'success'" class="w-50">
          </ngb-progressbar>
        </div>
        <button (click)="openProductSelectModal()"
                class="mt-3 btn btn-outline-secondary w-100 rounded">
          <fa-icon [icon]="['fas', 'link']" class="pr-2"></fa-icon> Add Linked Product (optional)
        </button>
        <button (click)="openTwitterLocationsModal()"
                class="mt-3 btn btn-outline-secondary rounded w-100">
          <fa-icon [icon]="['fas', 'map-marker-alt']" class="pr-2"></fa-icon> Add Location (optional)
        </button>

        <facebook-restrictions
            [restrictions]="facebookRestrictions"
            (onRestrictionsChange)="updateFbRestrictionParams($event)">
        </facebook-restrictions>
      </div>

      <div class="col-7 px-4 pb-4 pt-0 overflow-auto max-height-700">
        <ng-container *ngIf="!loading; else spinnerTemplate">
          <instagram-post-preview *ngIf="primaryInstagramAccount?.selected"
                                  [instagramUser]="primaryInstagramAccount"
                                  [content]="socialBlastParams.content"
                                  [isPostingSingleImage]="attachedFiles.length > 1"
                                  [attachedFile]="attachedFiles[0]"
                                  [(croppingWarning)]="croppingWarning"></instagram-post-preview>

          <facebook-post-preview *ngIf="primaryFacebookAccount && facebookPreviewAccount()"
                                 [facebookPage]="facebookPreviewAccount()"
                                 [content]="socialBlastParams.content"
                                 [attachedFiles]="attachedFiles"
                                 [(croppingWarning)]="croppingWarning"></facebook-post-preview>
        </ng-container>

        <ng-template #spinnerTemplate>
          <div class="height-300 mt-140 text-center">
            <fa-icon [icon]="['fas', 'cog']" [spin]="true" size="5x" class="green-color"></fa-icon>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer bg-secondary">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <button (click)="close()" class="btn btn-secondary px-5">
          Cancel
        </button>
      </div>
      <div class="col d-flex justify-content-end align-items-center">
        <input class="form-control" name="createSocialPostDatePicker" [minDate]="startDateMinDate" hidden
            ngbDatepicker #createSocialPostDatePicker="ngbDatepicker" restoreFocus="false" (dateSelect)="onDatetimeChange($event)">

        <p *ngIf="pickerDatetime" class="text-white pr-3 mb-0 font-weight-bold">
          {{ pickerDatetime | date: "short" }}
        </p>

        <fa-icon [icon]="['fas', 'calendar-alt']" size="2x" class="text-white mr-2 cursor-pointer"
           (click)="createSocialPostDatePicker.toggle()"></fa-icon>

        <time-picker class="w-50" [seedTime]="'10:00 AM'" (selectedTimeChange)="onPickerTimeChange($event)" [(selectedTime)]="pickerTime" [inputHidden]="true"
          class="text-white mr-3" [ngClass]="{ 'disabled-container' : !pickerDatetime }"
          [icon]="['fas', 'clock']" [iconSize]="'2x'"></time-picker>

        <button (click)="postOrUpdate()" class="btn btn-success px-5" [disabled]="posting || contentLength > maxPostLength">
          {{socialBlastParams.postDatetime ? 'Schedule' : 'Post Now'}}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #linkVerify let-modal>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-10 d-flex flex-column align-items-center text-center my-3">
          <h2>Product Link Missing</h2>
        </div>
        <div class="col-10 mb-1">
          <p>
            Do you want to link <span class="green-color">{{getLinkedProduct().fileName}}</span> to your store?
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer bg-secondary">
    <div class="container-fluid">
      <div class="row" style="font-weight: bold; font-size: larger">
        <div class="col-6 d-flex justify-content-center">
          <button type="button" class="btn btn-secondary" (click)="modal.close('ok')">No</button>
        </div>
        <div class="col-6 d-flex justify-content-center">
          <button type="button" class="btn btn-primary" (click)="modal.close('reinsert')">Yes</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #dateTimeSelect>
  <dl-date-time-picker
      startView="day"
      maxView="day"
      minView="minute"
      minuteStep="5"
      (change)="onPostDatetimeChange($event)"
      [(ngModel)]="pickerDatetime">
  </dl-date-time-picker>
</ng-template>
