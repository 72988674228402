<ng-container>
  <div class="modal-body">
    <div class="container-fluid mb-5">
      <div class="row justify-content-center">
        <div class="col-10">
          <p *ngFor="let alert of alerts">
            <ngb-alert [type]="alert.type">{{alert.message}}</ngb-alert>
          </p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-10 d-flex flex-column align-items-center text-center my-3">
          <h2>Select a Product</h2>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col">
          <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs nav-justified">
            <li [ngbNavItem]="1">
              <a ngbNavLink>Search</a>
              <ng-template ngbNavContent>
                <div class="d-flex align-items-center position-relative mt-4">
                  <input class="form-control"
                         [resultTemplate]="productTemplate"
                         [(ngModel)]="search"
                         (selectItem)="selectSearchedProduct($event)"
                         [inputFormatter]="formatInput"
                         [ngbTypeahead]="searchProducts">
                  <fa-icon *ngIf="search.length === 0" [icon]="['fas', 'search']" class="text-muted search-icon"></fa-icon>
                  <fa-icon *ngIf="search.length > 0" [icon]="['fas', 'times-circle']" class="text-muted search-icon" (click)="search = ''"></fa-icon>
                </div>
                <div class="row animate-fade-in" *ngIf="searching">
                  <div class="col-12 text-center">
                    <fa-icon [icon]="['fas', 'cog']" [spin]="true" size="3x" class="green-color"></fa-icon>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>Revenue</a>
              <ng-template ngbNavContent>
                <div *ngFor="let product of productsByMetric.revenue"
                     class="d-flex align-items-center cursor-pointer mt-1"
                     (click)="isSelected(product) ? clearSelection(product) : selectProduct(product)">
                  <img [src]="product.imageUrl" height="55" width="55" class="object-fit-contain object-position-center mr-2">{{product.name}}&nbsp;
                  <fa-icon *ngIf="isSelected(product)" [icon]="['fas', 'check']" class="green-color"></fa-icon>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink>Views</a>
              <ng-template ngbNavContent>
                <div *ngFor="let product of productsByMetric.views"
                     class="d-flex align-items-center cursor-pointer mt-1"
                     (click)="isSelected(product) ? clearSelection(product) : selectProduct(product)">
                  <img [src]="product.imageUrl" height="55" width="55" class="object-fit-contain object-position-center mr-2">{{product.name}}&nbsp;
                  <fa-icon *ngIf="isSelected(product)" [icon]="['fas', 'check']" class="green-color"></fa-icon>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="4">
              <a ngbNavLink>Abandons</a>
              <ng-template ngbNavContent>
                <div *ngFor="let product of productsByMetric['cart_abandons']"
                     class="d-flex align-items-center cursor-pointer mt-1"
                     (click)="isSelected(product) ? clearSelection(product) : selectProduct(product)">
                  <img [src]="product.imageUrl" height="55" width="55" class="object-fit-contain object-position-center mr-2">{{product.name}}&nbsp;
                  <fa-icon *ngIf="isSelected(product)" [icon]="['fas', 'check']" class="green-color"></fa-icon>
                </div>
              </ng-template>
            </li>
          </ul>
          <div *ngIf="!productsByMetricLoading; else loadingSpinner" [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
      <div *ngIf="!selectedProductLoading; else loadingSpinner">
        <ng-container *ngIf="selectedProducts.length > 0">
          <div class="w-100 border-bottom mt-4 pr-1">Selected</div>
          <div *ngFor="let product of selectedProducts" class="d-flex align-items-center">
            <img height="55" width="55" class="object-fit-contain object-position-center mr-2" [src]="product.imageUrl || product.childImageUrls[0]">{{product.name}}&nbsp;
            <fa-icon [icon]="['fas', 'times-circle']" class="text-danger cursor-pointer" (click)="clearSelection(product)"></fa-icon>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="modal-footer bg-secondary">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6 d-flex justify-content-center">
          <button (click)="close()" class="btn btn-secondary px-5">
            Cancel
          </button>
        </div>
        <div class="col-6 d-flex justify-content-center">
          <button (click)="accept()" class="btn btn-primary px-5">
            Accept
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loadingSpinner>
  <div class="d-flex justify-content-center my-5">
    <fa-icon [icon]="['fas', 'cog']" [spin]="true" size="2x" class="green-color"></fa-icon>
  </div>
</ng-template>

<ng-template #productTemplate let-product="result" let-search="term">
  <div class="display-flex flex-row">
    <img [src]="product.imageUrl" height="50" class="mr-2">
    <ngb-highlight [result]="product.name" [term]="search"></ngb-highlight>
  </div>
</ng-template>
