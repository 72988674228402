export class Filter {
  segmentIds: [];
  excludedSegmentIds: [];
  categoryIds: [];
  excludedCategoryIds: [];
  dataSource: string;
  skus: [];
  excludedSkus: [];
  emailListIds: [];
  excludedEmailListIds: [];
  value: {
    $lt: number;
    $gt: number;
    $lte: number;
    $gte: number;
    $eq: number;
    $neq: number;
    selected: string;
  };

  count: {
    $lt: number;
    $gt: number;
    $lte: number;
    $gte: number;
    $eq: number;
    $neq: number;
    selected: string;
  };

  purchase: {
    present: boolean;
    orderDateTime: {
      $gt: {
        period: string;
        ago: number;
        time: any;
        start: boolean;
      };
      $lt: {
        period: string;
        ago: number;
        time: any;
      };
      selected: string;
    };
    count: {
      $lt: number;
      $gt: number;
      $eq: number;
      $gte: number;
      $lte: number;
      $neq: number;
      selected: string;
    };
    value: {
      $lt: number;
      $gt: number;
      $lte: number;
      $gte: number;
      $eq: number;
      $neq: number;
      selected: string;
    };
    type: string;
    skus: [];
    excludedSkus: [];
    categoryIds: [];
    excludedCategoryIds: [];
  };

  engagement: {
    clicks: {
      present: boolean;
      clickDateTimeGt: {
        period: string;
        ago: number;
      };
    };
    opens: {
      present: boolean;
      openDateTimeGt: {
        period: string;
        ago: number;
      };
    };
    purchase: {
      present: boolean;
      orderDateTime: {
        $gt: {
          period: string;
          ago: number;
          time: any;
          start: boolean;
        };
        $lt: {
          period: string;
          ago: number;
          time: any;
        };
        selected: string;
      };
      value: {
        $lt: number;
        $gt: number;
        $lte: number;
        $gte: number;
        $eq: number;
        $neq: number;
        selected: string;
      };
    };
  };

  or: Filter[];
  and: Filter[];

  constructor(params?: Partial<Filter>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}
