import { Filter } from "./filter";
import { Node } from "./node";

export class Conditional {
  id: number;
  falseNodeId?: number;
  filters: Filter;
  flowId: number;
  trueNodeId?: number;
  node: Node;
}
