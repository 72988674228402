<div class="row">
  <div class="col-md-6">
    <h3>Subscriber growth</h3>
  </div>
  <div class="col-md-6" *ngIf="activeSubscribersCount > 0 && !loadingSubscriberGrowthGraph">
    <date-range (startDateChange)="startDate = $event;" (endDateChange)="endDate = $event;getChartData()"
                [startDate]="startDate"
                [endDate]="endDate" class="w-100">
    </date-range>
  </div>
  <div class="col-12" *ngIf="activeSubscribersCount > 0 && !loadingSubscriberGrowthGraph">
    <div class="text-center pr-3 pt-1 subscriber-growth-loading" *ngIf="!data || loadingSubscriberGrowthGraph">
      <fa-icon [icon]="['fas', 'cog']" size="3x" [spin]="true" class="blue-color"></fa-icon>
    </div>
    <canvas *ngIf="data" height="200" baseChart [datasets]="data" [type]="'line'" [options]="options">
    </canvas>
    <div class="text-center">{{ activeSubscribersCount | number }} active subscribers</div>
  </div>
  <div class="col-12 pt-5 text-center" *ngIf="activeSubscribersCount === 0 && !loadingSubscriberGrowthGraph">
    <img src="https://springbot-assets.s3.amazonaws.com/images/dashboard/subscriber-growth-chart.png" class="no-subscribers-img">
    <div class="no-subscribers-wrapper">
      <div class="alert alert-info">
        <p>You don't have any subscribers yet!</p>
        <button class="btn btn-primary" routerLink="/segments/email_lists/edit/all_subscribers/add">Import Subscribers</button>
      </div>
    </div>
  </div>
</div>
