import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { FacebookService } from "@app/social/services/facebook.service";
import { FacebookGeoLocation } from "@models/social/facebook_geo_location";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "facebook-location-selector",
  styleUrls: ["./facebook_location_selector.component.scss"],
  templateUrl: "./facebook_location_selector.component.html",
})
export class FacebookLocationSelectorComponent implements AfterViewInit {
  @Input()
  label: string;

  @Input()
  locationType: "zip" | "country" | "city" | "region";

  @Input()
  prompt: string;

  @Output()
  notify: EventEmitter<FacebookGeoLocation> = new EventEmitter<FacebookGeoLocation>();

  searchSubject: Subject<string> = new Subject();
  @ViewChildren("searchInput")
  searchInput: QueryList<ElementRef>;

  selected: any;
  searchText = "";
  locationResults: FacebookGeoLocation[] = [];
  currentLocation: FacebookGeoLocation;
  active = false;

  constructor(private eRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private fbService: FacebookService) {}

  @HostListener("document:click", ["$event"])
  clickout(event) {
    if (event.target.id !== `prompt-${this.locationType}`
      && !this.eRef.nativeElement.contains(event.target)) {
      this.active = false;
    }
  }

  ngOnInit(): void {
    this.searchSubject.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe((searchTextUpdate) => {
      this.searchText = searchTextUpdate;
      this.searchLocations();
    });
  }

  ngAfterViewInit(): void {
    // focuses entry field after being added via ngIf
    this.searchInput.changes.subscribe((queryList) => {
      queryList.last?.nativeElement.focus();
    });
  }

  updateSearch(keyUpEvent): void {
    if (["Enter", "ArrowDown", "ArrowUp"].includes(keyUpEvent.code) || this.searchText.length === 0) {
      return; // stops triggering of search if user is navigating list
    }
    this.currentLocation = null;
    this.searchSubject.next(keyUpEvent.target.value);
  }

  searchLocations(): void {
    this.fbService.searchLocations(this.locationType, this.searchText).subscribe((resp) => {
      if (!this.currentLocation) { // keeps the values from changing if user is selecting
        this.locationResults = resp;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  selectLocation(location?: FacebookGeoLocation): void {
    const selectedLocation = location || this.currentLocation || this.locationResults[0];
    if (selectedLocation) {
      this.notify.emit(selectedLocation);
      this.clearForm();
    }
  }

  nextLocation(): void {
    if (this.locationResults.length === 0) {
      return;
    }
    const nextIndex = this.currentLocationIndex() + 1;
    if (nextIndex < this.locationResults.length) {
      this.currentLocation = this.locationResults[nextIndex];
    }
  }

  previousLocation(): void {
    if (!this.currentLocation) {
      return;
    }
    const previousIndex = this.currentLocationIndex() - 1;
    if (previousIndex >= 0) {
      this.currentLocation = this.locationResults[previousIndex];
    }
  }

  clearForm(): void {
    this.searchText = "";
    this.currentLocation = null;
    this.locationResults = [];
    this.changeDetectorRef.detectChanges();
  }

  displayString(location: FacebookGeoLocation): string {
    switch (this.locationType) {
      case "country":
        return location.name;
      case "region":
        return `${location.name}, ${location.countryCode}`;
      default: // city and zip
        return `${location.name}, ${location.region}, ${location.countryCode}`;
    }
  }

  private currentLocationIndex(): number {
    return this.locationResults.findIndex((location) => location.id === this.currentLocation?.id);
  }
}
