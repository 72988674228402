<sb-header></sb-header>

<sb-nav [links]="mainNavLinks" [onlyEmailLists]="onlyEmailLists" [store]="store"></sb-nav>

<div class="sb-router-outlet">
  <router-outlet></router-outlet>
</div>

<sb-footer *ngIf="store" [storeId]="store.id">
</sb-footer>

