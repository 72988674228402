import { TriggeredEmail } from "@models/triggered_emails/triggered_email";
import { TriggeredEmailConfiguration } from "@models/triggered_emails/triggered_email_configuration";
import { FlowAction } from "./flow_action";
import { FlowNode } from "./flow_node";
import { FlowsMessage } from "./flows_message";
import { FlowsTriggeredEmail } from "./flows_triggered_email";
import { Node } from "./node";

// TODO: Add message and triggered email classes
export class Flow {
  description: string;
  errors: string[];
  id: number;
  name: string;
  state: string;
  flowActions: FlowAction[];
  nodes?: FlowNode[];
  nodesV2?: Node[];
  flowsTriggeredEmails: FlowsTriggeredEmail[];
  flowsMessages: FlowsMessage[];
  triggeredEmailConfiguration: TriggeredEmailConfiguration;
  triggeredEmails: TriggeredEmail[];
  maxPathLength?: number;
  emailCount?: number;
  messageCount?: number;
  smsCount?: number;
  activatedAt: Date;

  emailMetrics: any;
  messageMetrics: any;
}
