import { Injectable, TemplateRef } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MenuButtonsService {
  private $templateSource = new Subject<TemplateRef<any>>();

  getSource() {
    return this.$templateSource;
  }

  setMenuButtons(templateRef: TemplateRef<any>) {
    this.$templateSource.next(templateRef);
  }
}
