import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EmailTemplate } from "@models/email_templates/email_template";
import { PaginateCollection } from "@models/paginate_collection";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { environment } from "@environments/environment";

interface SubTemplateResponse {
  baseCartTemplate: string;
  baseRecoTemplate: string;
}

@Injectable()
export class EmailTemplateService {
  private emailTemplate: EmailTemplate = new EmailTemplate();
  private base = `${environment.apiUrl}/v2/email_templates`;

  constructor(
    private httpClient: HttpClient,
    private deserializeService: DeserializeService,
  ) {}

  index(params?: any): Observable<PaginateCollection<EmailTemplate>> {
    return this.httpClient.get<any>(this.base, { params })
      .pipe(mergeMap(async (response) => {
        const list: EmailTemplate[] = this.deserializeService.deserialize({ data: response.data });

        return new PaginateCollection(
          parseInt(response.pagination.total_count, 10),
          parseInt(response.pagination.page, 10),
          parseInt(response.pagination.per_page, 10),
          list,
        );
      }));
  }

  show(id: string): Observable<EmailTemplate> {
    return this.httpClient.get<EmailTemplate>(`${this.base}/${id}`)
      .pipe(mergeMap(async (response) => {
        const templateResponse = this.deserializeService.deserialize(response);
        return Object.assign(this.emailTemplate, templateResponse);
      }));
  }

  create(template: EmailTemplate): Observable<EmailTemplate> {
    return this.httpClient.post<EmailTemplate>(
      this.base,
      template,
    ).pipe(mergeMap(async (response) => this.deserializeService.deserialize(response)));
  }

  update(template: EmailTemplate): Observable<EmailTemplate> {
    return this.httpClient.put<EmailTemplate>(`${this.base}/${template.id}`, template)
      .pipe(mergeMap(async (response) => {
        const templateResponse = this.deserializeService.deserialize(response);
        return Object.assign(this.emailTemplate, templateResponse);
      }));
  }

  destroy(templateId: string) {
    return this.httpClient.delete(`${this.base}/${templateId}`);
  }

  defaultSubTemplates(): Observable<SubTemplateResponse> {
    return this.httpClient.get<SubTemplateResponse>(`${this.base}/default_sub_templates`);
  }
}
