export class Base<T = any> {
  id: string;
  meta: any;

  constructor(params?: Partial<T>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}
