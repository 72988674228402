import {
  Component, EventEmitter, Input, Output, SimpleChanges,
} from "@angular/core";
import { SocialAccount } from "@models/social/social_account";
import { SocialBlastAttachment } from "@models/social/social_blast_attachment";

@Component({
  selector: "instagram-post-preview",
  styleUrls: ["./instagram_post_preview.component.scss"],
  templateUrl: "./instagram_post_preview.component.html",
})
export class InstagramPostPreviewComponent {
  singleImageWarning = true;
  productLinkWarning = false;
  @Input()
  instagramUser: SocialAccount;

  @Input()
  content: string;

  // will only post the first of the group of images due to limitations
  @Input()
  isPostingSingleImage: boolean;

  @Input()
  attachedFile: SocialBlastAttachment;

  @Input()
  croppingWarning: boolean;

  @Output()
  croppingWarningChange = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.attachedFile) {
      this.productLinkWarning = !!this.attachedFile?.productLandingUrl;
    }
  }

  displayContent(): string {
    const landingUrl = this.attachedFile?.productLandingUrl;
    if (landingUrl) {
      return this.content.replace(landingUrl, "");
    }
    return this.content || "Enter post content";
  }
}
