export const environment = {
  production: false,
  apiUrl: "https://qa.springbot.com",
  heapApplicationId: "3721941110",
  gaTrackingId: "UA-208946-33",
  reCAPTCHASiteKey: "6LcTkfoSAAAAANAnruAhlpJIlMgeQ3Qvkpiu3Jws",
  intercomAppId: "t0oty1ty",
  bugsnagApiKey: "557aa80e7554505d8de94f52ae4a294a",
  appVersion: require('../../package.json').version,
};
