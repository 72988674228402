import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

interface StatusObj {
  updated: boolean;
  errors: any; // has to be any
}

@Injectable()
export class FormStatusService {
  statusSource = new BehaviorSubject<StatusObj>({ updated: false, errors: [] });
  currentStatus = this.statusSource.asObservable();

  // eslint-disable-next-line
  constructor() {}

  updateStatus(status: StatusObj) {
    this.statusSource.next(status);
  }
}
