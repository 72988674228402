import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { BroadcastMessage } from "@models/broadcast_message";

// Allows broadcasting changes/events to multiple browser tabs on the same device.
@Injectable({
  providedIn: 'root'
})
export class BroadcastService {

  broadcastChannel: any;
  onMessage = new Subject<any>();

  constructor() {
    this.initialize();
  }

  initialize() {
    const name: string = window.location.hostname;
    this.broadcastChannel = new BroadcastChannel(name);
    this.broadcastChannel.onmessage = (message) => this.onMessage.next(message.data);
  }

  publish(message: BroadcastMessage): void {
    this.broadcastChannel.postMessage(message);
  }

  listen(type: string): Observable<BroadcastMessage> {
    return this.onMessage.pipe(
        filter(message => message.type === type)
    );
  }
}