<ng-container>
  <div class="modal-body">
    <div class="container-fluid mb-1">
      <div class="row">
        <div class="col-12 d-flex flex-column align-items-center">
          <i class="fa fa-robot fa-2x mt-2" aria-hidden="true"></i>
          <h2 class="mt-1 mb-2">
            AI Designer
          </h2>
        </div>
      </div>

      <div class="text-center loading-container pt-5" *ngIf="loadingInit">
        <fa-icon [icon]="['fas', 'cog']" [spin]="true" size="3x" class="green-color"></fa-icon>
      </div>

      <div class="alert alert-danger" *ngIf="!remoteWorking && !loadingInit">
        <strong>Oops!</strong> It looks like our AI Designer is not working right now. Please try again later.
      </div>
      <div class="alert alert-danger" *ngIf="remainingRequests < 1 && !loadingInit">
        <strong>Oops!</strong> Looks like you've run out of AI generations for this month.
        <br><a (click)="activeModal.close(false);" routerLink="/billing">Upgrade</a> for more.
      </div>

      <ngb-accordion #askChatGPTAccordion="ngbAccordion" activeIds="ngb-panel-0" *ngIf="!carouselOnly && remainingRequests >= 1 && !loadingInit" [closeOthers]="true" [activeIds]="'searchContentSection'">
        <ngb-panel title="Search content" id="searchContentSection" [disabled]="aiTookForever">
          <ng-template ngbPanelContent>
            <form class="form">
              <label for="sectionPrompt">
                Describe the content you'd like here (e.g., 'A promotional section for summer shoes with a call-to-action button.')
              </label>
              <textarea name="sectionPrompt" [(ngModel)]="sectionPrompt" class="form-control pr-0">
              </textarea>

              <div *ngIf="showImages">
                <label class="mt-4 mb-0">Do you want to include a featured image, or background image?</label>
                <div class="mb-3 row mt-0">
                  <div class="col-6 p-1">
                    <div class="card p-1 h-100">
                      <div class="text-center">
                        <label class="form-label">Featured Image</label>
                        <small class="ml-1">(Optional)</small>
                      </div>
                      <div class="file btn btn-primary upload-button">
                        <span *ngIf="!loadingFeaturedImage">Choose file</span>
                        <fa-icon [icon]="['fas', 'cog']" *ngIf="loadingFeaturedImage" [spin]="true" size="1x" class="white-color"></fa-icon>
                        <input type="file" accept="image/*"
                                id="featuredImageFileSelector"
                                (change)="handleImageSelect($event.target.files[$event.target.files.length - 1], 'featuredImageUrl')">
                      </div>
                      <img [src]="featuredImageUrl" class="w-100 mt-1" *ngIf="featuredImageUrl">
                    </div>
                  </div>
                  <div class="col-6 p-1">
                    <div class="card p-1 h-100">
                      <div class="text-center">
                        <label class="form-label">Background Image</label>
                        <small class="ml-1">(Optional)</small>
                      </div>
                      <div class="file btn btn-primary upload-button">
                        <span *ngIf="!loadingBackgroundImage">Choose file</span>
                        <fa-icon [icon]="['fas', 'cog']" *ngIf="loadingBackgroundImage" [spin]="true" size="1x" class="white-color"></fa-icon>
                        <input type="file" accept="image/*"
                                id="backgroundImageFileSelector"
                                (change)="handleImageSelect($event.target.files[$event.target.files.length - 1], 'backgroundImageUrl')">
                      </div>
                      <img [src]="backgroundImageUrl" class="w-100 mt-1" *ngIf="backgroundImageUrl">
                    </div>
                  </div>
                </div>
              </div>
              <div class="alert alert-danger mt-3" *ngIf="errorMsg" role="alert">
                Something went wrong. Please try again.
              </div>
              <div class="d-flex justify-content-end">
                <div>
                  <button type="button"
                    (click)="searchContent(askChatGPTAccordion);"
                    class="btn btn-success mt-2">
                    Generate
                  </button>
                </div>
              </div>
            </form>
          </ng-template>
        </ngb-panel>
        <ngb-panel title="Results" id="chatGPTResultsSection">
          <ng-template ngbPanelContent>
            <div class="text-center loading-container pt-4" *ngIf="submitting && !aiTookForever">
              <fa-icon [icon]="['fas', 'cog']" [spin]="true" size="3x" class="green-color"></fa-icon>
              <br/><span class="mt-3 d-block">
                AI can take from a few seconds to a minute to get results.
              </span>
            </div>

            <div class="text-center" *ngIf="aiTookForever">
              We're sorry but something went wrong. Please <span class="btn-link cursor-pointer" (click)="tryAgain(askChatGPTAccordion)">try again.</span>
            </div>
            
            <content-selector-carousel *ngIf="!submitting && htmlContents.length > 0" [htmlContents]="htmlContents" (selectedContent)="selectedContentCarousel($event)"></content-selector-carousel>

          </ng-template>
        </ngb-panel>
      </ngb-accordion>

      <div *ngIf="carouselOnly" class="pt-4 pb-2">
        <content-selector-carousel *ngIf="!submitting && htmlContents.length > 0" [htmlContents]="htmlContents" (selectedContent)="selectedContentCarousel($event)"></content-selector-carousel>
      </div>
    </div>
    <div class="text-right" *ngIf="remainingRequests <= 20 && !loadingInit">
      <small class="text-right">
        <strong>
          Monthly AI Limit: {{ remainingRequests }} Generations Remaining.
        </strong><br>
        (Current subscription: {{requestLimit}} per month).
        <a (click)="cancel()" routerLink="/billing">Upgrade</a> for more.
      </small>
    </div>
  </div>

  <div class="modal-footer bg-secondary">
    <div class="d-flex justify-content-end">
      <div class="mr-2">
        <button type="button"
                [disabled]="loadingFeaturedImage || loadingBackgroundImage"
                (click)="cancel()"
                class="btn btn-danger">
          Cancel
        </button>
      </div>
      <div>
        <button type="button"
                (click)="ChooseOrWaitForContent()"
                [disabled]="aiTookForever || loadingBackgroundImage || loadingFeaturedImage || (!generatedMJMLWorkerId && submitting) || (!submitting && htmlContents.length <= 0)"
                *ngIf="remainingRequests >= 1 && remoteWorking && !loadingInit"
                class="btn btn-success">
          <span *ngIf="submitting">Continue editing</span>
          <span *ngIf="!submitting">Choose</span>
        </button>
      </div>
    </div>
  </div>
</ng-container>
