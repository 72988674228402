import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DeserializeService {
  constructor() {}

  deserialize(o: any, cb?: Function) {
    if (o === Object(o) && !Array.isArray(o) && typeof o !== 'function') {
      let n = {};
      Object.keys(o)
          .forEach((k) => {
            if (k == "attributes" || k == "relationships") {
              n = Object.assign(n, this.deserialize(o[k]));
            } else if (k == "data") {
              n = this.deserialize(o[k]);
            } else if (k == "included" && o[k].length > 0) {
              n[`${o[k][0]['type']}s`] = this.deserialize(o[k]);
            } else {
              n[this.toCamel(k)] = this.deserialize(o[k]);
            }
          });
      return n;
    } else if (Array.isArray(o)) {
      return o.map((i) => {
        return this.deserialize(i);
      });
    }

    if (!!cb) {
      return cb(undefined, o);
    } else {
      return o;
    }
  }

  toCamel(s: string) {
    return s.replace(/([-_][a-z])/ig, ($1) => {
      return $1.toUpperCase()
          .replace('-', '')
          .replace('_', '');
    });
  }
}
