import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { AWSFileService } from "@app/content_editor/components/email_content_editor/services/aws_file.service";
import { S3UploadApiService } from "@app/shared/services/s3_upload_api.service";
import { Product } from "@models/product";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

interface Alert {
  type: "info" | "success" | "warning" | "danger" | "primary"
  message: string
}

// IMPORTANT: This product select modal is used specifically for the email editor
// there is another more general purpose product select modal ProductSelectModal
@Component({
  selector: 'product-image-select-modal',
  templateUrl: './product_image_select_modal.component.html',
})
export class ProductImageSelectModalComponent {
  @Input()
  product: Product;

  @Input()
  selectedImageUrl: string;

  file: File;
  fileUrl: string;
  hovering = false;
  uploading = false;
  uploadSelected = false;
  productImageUrls: string[] = [];
  alerts: Alert[] = [];

  constructor(private s3UploadApiService: S3UploadApiService,
    private activeModalRef: NgbActiveModal,
    private awsFileService: AWSFileService,
    private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initImageUrls();
  }

  initImageUrls(): void {
    let { imageUrl, parentImageUrl, childImageUrls } = this.product;
    // cannot use spread operator on undefined or null
    childImageUrls = childImageUrls || [];
    // remove falsy and duplicate values
    this.productImageUrls = [
      ...new Set([imageUrl, parentImageUrl, ...childImageUrls]),
    ].filter(Boolean);
  }

  selectImage(imageUrl: string): void {
    this.uploadSelected = false;
    this.selectedImageUrl = imageUrl;
  }

  selectNewProduct(): void {
    this.activeModalRef.close("changeproduct");
  }

  upload(file: File): void {
    this.file = file;
    if (file && file.name.split('.').pop().toLowerCase() === "svg") {
      this.displayAlert({ message: "SVG is not a supported file type.", type: "danger" });
    } else {
      this.uploading = true;
      const preparedFile = this.awsFileService.prepareFile(this.file);
      this.s3UploadApiService.uploadImage(preparedFile)
      .subscribe({
        next: this.handleUploadResponse.bind(this),
        error: this.handleErrorUploadResponse.bind(this)
     });
    }
  }

  save(): void {
    this.activeModalRef.close(this.selectedImageUrl);
  }

  close(): void {
    this.activeModalRef.dismiss("cancelled");
  }

  displayAlert(alert: Alert): void {
    this.alerts = [alert, ...this.alerts];
    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      this.alerts = this.alerts.filter(({ message }) => message !== alert.message);
      this.changeDetectorRef.detectChanges();
    }, 2500);
  }

  private handleUploadResponse(response) {
    const uploadedImageUrl = response.url;
    this.uploading = false;
    this.uploadSelected = true;
    this.selectedImageUrl = uploadedImageUrl;
    this.fileUrl = uploadedImageUrl;
  }

  private handleErrorUploadResponse(error) {
    this.uploading = false;
    console.log(error);
    if (typeof error === 'string') {
      this.displayAlert({ message: error, type: "danger" });
    } else {
      this.displayAlert({ message: 'There was an error uploading your image.', type: "danger" });
    }
  }
}
