import {
  ChangeDetectorRef, Component, Input, ViewEncapsulation,
} from "@angular/core";
import { CategoriesService } from "@app/shared/components/categories/categories.service";
import { EditorProductSelectModalComponent } from "@app/content_editor/components/email_content_editor/components/editor_product_select_modal/editor_product_select_modal.component";
import { ProductsService } from "@app/shared/components/products/products.service";
import { Category } from "@models/category";
import {
  ExcludedCategory, RecommenderConfiguration, RecommenderType,
} from "@app/content_editor/models/recommender_configuration";
import { SourceRecommenderConfiguration } from "@app/content_editor/models/source_recommender_configuration";
import { Product } from "@models/product";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ObjectFormatter } from "@app/shared/tools/object_formatter";

type RecommenderTypeOption = { value: RecommenderType, popoverText: string };

interface Alert {
  type: "info" | "success" | "warning" | "danger" | "primary"
  message: string
}

@Component({
  templateUrl: "./product_filters_modal.component.html",
  styleUrls: ["./product_filters_modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ProductFiltersModalComponent {
  // SourceRecommenderConfiguration comes from a serialized field
  // on the Rails model ProductRecommendationTemplate. it contains
  // all the settings/filters for the products that populate the reco's dynamic content
  @Input()
  sourceRecommenderConfiguration: SourceRecommenderConfiguration;

  alerts: Alert[] = [];
  emailModelType: string;
  categories: Category[];
  excludedCategories: Category[];
  excludedSkus: string[];
  excludedSkuProducts: Product[]; // keeps track of the products for the excluded skus
  excludedSkuProductsLoading = true;
  recommenderConfiguration: RecommenderConfiguration;
  selectedRecommenderType: RecommenderType;
  recommenderTypes: RecommenderTypeOption[] = [{
    value: 'Top Selling',
    popoverText: 'System auto populates the best selling products over the last 30 days.',
  }, {
    value: 'System Matched',
    popoverText: 'System auto populates products purchased in conjunction with the cart items.',
  }, {
    value: 'Most Recommended',
    popoverText: 'System auto populates the 3 most recommended products.',
  }];

  showRecommenderTypeDropdown = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private ngbModal: NgbModal,
    private activeModalRef: NgbActiveModal,
    private categoriesService: CategoriesService,
    private productsService: ProductsService,
  ) {
  }

  ngOnInit(): void {
    this.getCategories(); // initialization of excluded categories handled on category response
    this.initRecommenderConfiguration();
    this.selectedRecommenderType = this.recommenderConfiguration.recommenderType || "Top Selling";
    this.showRecommenderTypeDropdown = true;
    this.initExcludedSkus();
    this.getExcludedSkuProducts();
    this.initRecommenderTypes();
    this.changeDetectorRef.detectChanges();
  }

  private initRecommenderConfiguration(): void {
    const clonedSource = this.cloneDeep(this.sourceRecommenderConfiguration);
    this.recommenderConfiguration = ObjectFormatter.formatAllKeys<RecommenderConfiguration>(clonedSource, "camel_case");
  }

  private cloneDeep(obj) {
    if(typeof obj !== 'object' || obj === null) {
      return obj;
    }

    if(obj instanceof Date) {
      return new Date(obj.getTime());
    }

    if(obj instanceof Array) {
      return obj.reduce((arr, item, i) => {
        arr[i] = this.cloneDeep(item);
        return arr;
      }, []);
    }

    if(obj instanceof Object) {
      return Object.keys(obj).reduce((newObj, key) => {
        newObj[key] = this.cloneDeep(obj[key]);
        return newObj;
      }, {})
    }
  }

  private initExcludedCategories(): void {
    const rawExcludedCategories = this.recommenderConfiguration.excludedCategories;
    this.excludedCategories = rawExcludedCategories?.map((category) => {
      return this.formattedCategory(category);
    }) || [];
  }

  private initExcludedSkus(): void {
    const rawExcludedSkus = this.recommenderConfiguration.excludedSkus;
    if (!rawExcludedSkus) {
      this.excludedSkus = [];
    } else if (typeof rawExcludedSkus === 'string') {
      this.excludedSkus = rawExcludedSkus.split(",").map((sku) => sku.trim());
    } else {
      this.excludedSkus = rawExcludedSkus.map((sku) => sku.trim());
    }
  }

  private getExcludedSkuProducts(): void {
    if (this.excludedSkus.length === 0) {
      this.excludedSkuProducts = [];
      this.excludedSkuProductsLoading = false;
      this.changeDetectorRef.detectChanges();
      return;
    }

    this.productsService.index({ exactSkus: this.excludedSkus.join(",") }).subscribe(({ data: products }) => {
      this.excludedSkuProducts = products;
      this.excludedSkuProductsLoading = false;
      this.changeDetectorRef.detectChanges();
    }, (_error) => {
      this.displayAlert({ message: "There was an error retrieving your excluded products", type: 'danger' });
    });
  }

  displayAlert(alert: Alert): void {
    this.alerts = [alert, ...this.alerts];
    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      this.alerts = this.alerts.filter(({ message }) => message !== alert.message);
      this.changeDetectorRef.detectChanges();
    }, 2000);
  }

  private formattedCategory(rawCategory: ExcludedCategory): Category {
    let category: Category;
    switch (typeof rawCategory) {
      case 'string':
        category = {
          catId: parseInt(rawCategory, 10), catName: "Unknown", id: null, meta: null,
        };
        break;
      case 'number':
        category = {
          catId: rawCategory, catName: 'Unknown', id: null, meta: null,
        };
        break;
      case 'object':
        category = {
          catId: rawCategory.id,
          catName: (rawCategory?.attributes?.name || "Unknown"),
          id: null,
          meta: null,
        };
        break;
      default:
        // do nothing
    }
    if (category.catName === "Unknown") {
      category.catName ||= this.categories.find(({ catId }) => catId === category.catId)?.catName;
    }
    return category;
  }

  confirm(): void {
    this.updateSource();
    this.activeModalRef.close(this.sourceRecommenderConfiguration);
  }

  cancel(): void {
    this.activeModalRef.dismiss('cancelled');
  }

  initRecommenderTypes(): void {
    if (this.isOnSiteAbandonmentEmail()) {
      this.recommenderTypes.push(
        {
          value: 'Recently Viewed',
          popoverText: 'System will auto populate products that were recently viewed by the customer.',
        },
      );
    }
    this.showRecommenderTypeDropdown = true;
  }

  private isOnSiteAbandonmentEmail(): boolean {
    return ['onsite_abandonment_email', 'onsite_abandonment', 'onsite_abandonment_recommended'].includes(this.emailModelType);
  }

  // puts the excluded categories back into the legacy shape for save to serialized YAML
  buildExcludedCategories(): ExcludedCategory[] {
    return this.excludedCategories.map(({ catId, catName }) => {
      return { id: catId, attributes: { name: catName } };
    });
  }

  openProductModal(): void {
    const modal = this.ngbModal.open(EditorProductSelectModalComponent, { size: "lg" });
    modal.componentInstance.maxSelect = 20;
    modal.componentInstance.selectedSkus = this.excludedSkus;
    modal.result.then((products) => {
      this.excludedSkus = products.map(({ sku }) => sku);
    });
  }

  addExcludedSku(sku: string): void {
    if (!this.excludedSkus.includes(sku)) {
      this.excludedSkus = [...this.excludedSkus, sku];
    }
  }

  removeExcludedSku(sku: string): void {
    this.excludedSkus = this.excludedSkus.filter((excludedSku) => excludedSku !== sku);
  }

  getCategories(): void {
    this.categoriesService.index({ "fields[category][]": ["cat_name", "cat_id"] })
      .subscribe(({ data: categories }) => {
        this.categories = categories;
        this.initExcludedCategories();
        this.changeDetectorRef.detectChanges();
      });
  }

  addExcludedCategory(category: Category): void {
    const isAlreadyExcluded: boolean = this.excludedCategories.some(({ catId }) => {
      return catId === category.catId;
    });

    if (isAlreadyExcluded) {
      return;
    }
    this.excludedCategories = [...this.excludedCategories, category];
  }

  removeExcludedCategory(category: Category): void {
    this.excludedCategories = this.excludedCategories.filter(({ catId }) => {
      return catId !== category.catId;
    });
  }

  private updateSource(): void {
    this.setSource("excluded_categories", this.buildExcludedCategories());
    this.setSource("recommender_type", this.selectedRecommenderType);
    this.setSource("excluded_skus", this.excludedSkus);
  }

  private setSource(key: 'excluded_categories' | 'excluded_skus' | 'recommender_type', value: any): void {
    if (this.sourceRecommenderConfiguration[key]) {
      this.sourceRecommenderConfiguration[key] = value;
    } else {
      this.sourceRecommenderConfiguration[ObjectFormatter.toCamelCase(key)] = value;
    }
  }
}
