import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "phoneFormat",
})
export class PhoneFormatPipe implements PipeTransform {
  transform(number) {
    if (number.length === 11) {
      return `${number.substring(0, 1)}-${number.substring(
        1,
        4
      )}-${number.substring(4, 7)}-${number.substring(7, 11)}`;
    }
    return number;
  }
}
