import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { AuthorizationService } from "@app/shared/services/authorization.service";
import { EmailProvider } from "@models/apis/emailProvider";
import { Subprovider } from "@models/apis/subprovider";

@Component({
  selector: "mail-chimp-config-modal",
  styleUrls: ["./mail_chimp_config_modal.component.scss"],
  templateUrl: "./mail_chimp_config_modal.component.html",
})
export class MailChimpConfigModalComponent {
  errored = false;
  connected = false;
  loadingData = false;
  subproviderToggling = false;
  refreshingList = false;
  provider: EmailProvider;
  subproviders: Subprovider[];

  constructor(
      private activeModal: NgbActiveModal,
      private authorizationService: AuthorizationService,
  ) {}

  ngOnInit() {
    this.loadingData = true;
    this.authorizationService.getEmailProvider("mailchimp").subscribe((provider) => {
      this.provider = provider;
      this.subproviders = this.provider.subproviders;
      this.setSubprovidersSelected();
      this.connected = this.provider.hasAccessToken;
      if (!this.connected) { this.errored = true; }
      this.loadingData = false;
    }, (_error) => {
      this.errored = true;
    });
  }

  finish() {
    this.activeModal.close({ connected: this.connected, errored: false });
  }

  changeSubproviderState(subprovider: Subprovider) {
    this.subproviderToggling = true;
    this.authorizationService.changeSubproviderStatus(
        this.provider.id,
        subprovider,
    ).subscribe((response) => {
      subprovider.active = response.active;
      subprovider.selected = response.active;
      this.subproviderToggling = false;
    }, (_error) => {
      this.subproviderToggling = false;
      this.errored = true;
    });
  }

  disconnect() {
    this.authorizationService.destroy("mailchimp").subscribe((response) => {
      this.activeModal.close({ connected: response.data.attributes.authenticated, errored: false });
    }, (_error) => {
      this.activeModal.close({ connected: false, errored: true });
    });
  }

  refresh() {
    this.refreshingList = true;
    this.authorizationService.refreshProviderList(this.provider).subscribe((response) => {
      this.provider = response;
      this.subproviders = this.provider.subproviders;
      this.setSubprovidersSelected();
      this.refreshingList = false;
    }, (_error) => {
      this.errored = true;
      this.refreshingList = false;
    });
  }

  setSubprovidersSelected() {
    this.subproviders.forEach((subprovider) => {
      subprovider.selected = subprovider.active;
    });
  }
}
