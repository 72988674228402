import { ChangeDetectorRef, Component, Input, OnChanges } from "@angular/core";
import { ProductsService } from "@app/shared/components/products/products.service";
import { Product } from "@models/product";
import {
  NgbActiveModal,
  NgbTypeaheadSelectItemEvent,
} from "@ng-bootstrap/ng-bootstrap";
import { Observable, Subject } from "rxjs";

interface Alert {
  type: "info" | "success" | "warning" | "danger" | "primary";
  message: string;
}

// *********************************  IMPORTANT ******************************************
// the explicit change detection colls are needed for this component to function correctly
// within the grapes email editor.
// do not remove them without verifying it still works within
// the product and product-list components
// *********************************  IMPORTANT *******************************************

// this component is meant to be instantiated from inside the AngularBootstrap modal
// ex: this.modalService.open(ProductSelectModalComponent);
// optional inputs if products need to be 'pre-selected' (Pass in only 1 of the three)
// - selectedProductIds, selectedProduct OR selectedProducts
// for multi-select, pass in maxSelect greater than 1
@Component({
  selector: "product-select-modal",
  styleUrls: ["./product_select_modal.component.scss"],
  templateUrl: "./product_select_modal.component.html",
})
export class ProductSelectModalComponent {
  selectedProductLoading = false;
  productsByMetricLoading = true;
  search = "";
  searchSubject: Subject<string> = new Subject<string>();
  alerts: Alert[] = [];

  @Input()
  selectedProductId: string;

  @Input()
  selectedProduct: Product;

  @Input()
  selectedProducts: Product[] = [];

  @Input()
  maxSelect = 1;

  active = 1; // number of the active tab, 1 is search
  productsByMetric: {
    revenue: Product[];
    cart_abandons: Product[];
    views: Product[];
  } = {
    revenue: [],
    cart_abandons: [],
    views: [],
  };

  searching = false;

  searchProducts = (text$: Observable<string>) => {
    return this.productsService.search(text$);
  }

  readonly thirtyDays = 30 * 60 * 60 * 24 * 1000;
  metricRequestParams = {
    startDate: new Date(Date.now() - this.thirtyDays),
    endDate: new Date(),
    page: 1,
    perPage: 5,
    stats: true, // required for backend to return metrics
  };

  constructor(
    private activeModalRef: NgbActiveModal,
    private changeDetectorRef: ChangeDetectorRef,
    private productsService: ProductsService
  ) {}

  ngOnInit(): void {
    this.setSelectedProducts();
    this.getProductsByMetric();
  }

  close(): void {
    this.activeModalRef.dismiss("cancelled");
  }

  accept(): void {
    if (this.selectedProducts.length === 0) {
      this.displayAlert({ message: "No Product Selected", type: "warning" });
      return;
    }
    if (this.maxSelect === 1) {
      this.activeModalRef.close(this.selectedProducts[0]);
    } else {
      this.activeModalRef.close(this.selectedProducts);
    }
  }

  selectSearchedProduct(event: NgbTypeaheadSelectItemEvent): void {
    this.search = "";
    this.selectProduct(event.item as Product);
  }

  selectProduct(product: Product): void {
    if (this.maxSelect === 1) {
      // auto toggle selection for single product select situations
      this.selectedProducts = [product];
    } else if (this.selectedProducts.length >= this.maxSelect) {
      this.displayAlert({
        type: "info",
        message: `${this.maxSelect} of ${this.maxSelect} components selected`,
      });
    } else {
      this.selectedProducts = [...this.selectedProducts, product];
    }
    this.changeDetectorRef.detectChanges();
  }

  // clears the typeahead's input on a selection
  formatInput(_inputObject: any): string {
    return "";
  }

  isSelected(product: Product): boolean {
    return !!this.selectedProducts.find(({ id }) => id === product.id);
  }

  clearSelection(product: Product): void {
    this.selectedProducts = this.selectedProducts.filter(
      ({ id }) => id !== product.id
    );
    this.changeDetectorRef.detectChanges();
  }

  displayAlert(alert: Alert): void {
    this.alerts = [alert, ...this.alerts];
    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      this.alerts = this.alerts.filter(
        ({ message }) => message !== alert.message
      );
      this.changeDetectorRef.detectChanges();
    }, 2000);
  }

  // products can be derived from three inputs, selectedProductId, selectedProduct or selectedProducts
  // all are standardized to selectedProducts
  private setSelectedProducts(): void {
    if (this.selectedProduct) {
      this.selectedProducts = [this.selectedProduct];
    } else if (this.selectedProductId) {
      this.selectedProductLoading = true;
      this.productsService.getProduct(this.selectedProductId).subscribe(
        (product) => {
          this.selectedProducts = [product];
          this.selectedProductLoading = false;
        },
        (_error) => {
          this.displayAlert({
            message: "There was an error retrieving your product",
            type: "danger",
          });
          this.selectedProductLoading = true;
        }
      );
    }
    if (!this.selectedProducts) {
      this.selectedProducts = [];
    }
  }

  private getProductsByMetric(): void {
    this.searching = true;
    ["revenue", "cart_abandons", "views"].forEach((metric) => {
      this.productsService
        .index({ sort: `${metric} desc`, ...this.metricRequestParams })
        .subscribe(
          ({ data: products }) => {
            this.productsByMetric[metric] = products;
            this.searching = false;
          },
          (_error) => {
            this.searching = false;
            this.displayAlert({
              message: `there was an error getting products by ${metric}`,
              type: "danger",
            });
          },
          () => {
            if (metric === "views") {
              this.productsByMetricLoading = false;
              this.searching = false;
            }
          }
        );
    });
  }
}
