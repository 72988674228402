import {
  Component, EventEmitter, Input, Output,
} from "@angular/core";

@Component({
  selector: "checklist-item",
  styleUrls: ["./checklist_item.component.scss"],
  templateUrl: "./checklist_item.component.html",
})
export class ChecklistItemComponent {
  @Input()
  item: string;

  @Input()
  faIcon: string;

  @Input()
  text: string;

  @Input()
  subtext: string;

  @Input()
  ctaLink: string;

  @Input()
  ctaText: string;

  @Input()
  clearable: boolean = true;

  @Output()
  clearItem = new EventEmitter();

  @Output()
  cta = new EventEmitter();

  @Input()
  showItem: boolean;

  @Input()
  hideBorder: boolean = false;

  additionalIconClasses: string;

  constructor() {}

  ngOnInit(): void {
    switch (this.item) {
      case "emailBasics":
      case "abandonedCart":
      case "firstEmail":
        this.additionalIconClasses = "checked-icon py-2";
        break;
      case "integrations":
        this.additionalIconClasses = "dismissed-icon py-2";
        break;
      default:
        this.additionalIconClasses = "p-2";
        break;
    }
  }

  onAction(): void {
    this.cta?.emit();
  }

  clear(): void {
    this.clearItem?.emit();
  }
}
