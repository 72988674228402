import { Subprovider } from "@models/apis/subprovider";
import { Base } from "@models/base";

export class EmailProvider extends Base {
  name: string;
  enabled: boolean;
  working: boolean;
  accountName: string;
  userEmail: string;
  storeId: number;
  hasAccessToken: boolean;
  subproviders: Subprovider[] = [];
  selected = false;
  errors: string[];
  engagementThreshold: number;
  defaultFromEmail: string;
  verifiedEmail: boolean;
  defaultCustomerName: string;
  oneClickUnsubscribe: boolean;
  importCustomersAsSubscribers: boolean;
  enableDoubleOptIn: boolean;
}
