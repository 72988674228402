import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from "@angular/core";

@Directive({
  host: {
    "[class.cursor-pointer]": "true",
    "[class.sortable-header--asc]": "sortDirection === 'asc'",
    "[class.sortable-header--desc]": "sortDirection === 'desc'",
  },
  selector: "th[sortName]",
})
export class SortableHeaderDirective {
  @Input()
  sortName: string;

  @Output()
  sort = new EventEmitter<string>();

  @Input()
  sortDirection: "asc" | "desc";

  @Input()
  validSortDirection: "asc" | "desc";

  @HostListener("click", ["$event"])
  onClick(e: Event) {
    if (!this.sortDirection) {
      this.sortDirection = this.validSortDirection || "desc";
      this.sort.next(`${this.sortName} ${this.sortDirection}`);
    } else if (
      this.sortDirection === "asc" &&
      this.validSortDirection !== "asc"
    ) {
      this.sortDirection = "desc";
      this.sort.next(`${this.sortName} ${this.sortDirection}`);
    } else if (
      this.sortDirection === "desc" &&
      this.validSortDirection !== "desc"
    ) {
      this.sortDirection = "asc";
      this.sort.next(`${this.sortName} ${this.sortDirection}`);
    }
  }

  unsetSortDirection() {
    this.sortDirection = null;
  }
}
