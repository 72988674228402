import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Response } from "@models/response";
import { TwitterPost } from "@models/social/twitter_post";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class TwitterService {
  constructor(
    private http: HttpClient,
    private deserializeService: DeserializeService,
  ) {}

  getPosts(params: any): Observable<Response<TwitterPost[]>> {
    return this.http.get(`${environment.apiUrl}/v2/social_hub/twitter_posts`, { params })
      .pipe(mergeMap(async (postResponse: any) => {
        const posts = this.deserializeService.deserialize(postResponse) as TwitterPost[];

        return new Response<TwitterPost[]>(posts, postResponse.meta);
      }));
  }

  getPlaces(params): Observable<{ id: string, name: string }[]> {
    return this.http.get<{ id: string, name: string }[]>(
      `${environment.apiUrl}/v2/social_hub/twitter_places`,
      { params },
    );
  }

  getPlace(id: string): Observable<{ id: string, name: string }> {
    return this.http.get<{ id: string, name: string }>(`${environment.apiUrl}/v2/social_hub/twitter_places/${id}`);
  }

  // backend call returns status code only, no data
  addFavorite(twitterPostId: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/v2/social_hub/twitter_favorites`, { postId: twitterPostId });
  }

  // backend call returns status code only, no data
  removeFavorite(twitterPostId: string): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}/v2/social_hub/twitter_favorites/${twitterPostId}`);
  }

  deletePost(postId: string) {
    return this.http.delete(`${environment.apiUrl}/v2/social_hub/twitter_posts/${postId}`);
  }
}
