<div class="sb-header">
  <div class="sb-header__buttons">
    <button class="sb-create-button btn btn-success hover-blue mr-3 cursor-pointer"
            id="sb-header__create__button"
            (click)="modalService.open(createModalContent);">
      <fa-icon class="white-color" *ngIf="!!store"
               [icon]="['fas', 'edit']">
      </fa-icon>
      Create
    </button>
    <div class="sb-header__dropdown" style="float:right;" *ngIf="user"
         id="sb-header__user__dropdown"
         (click)="toggleDropdown(!showDropdown, 1);showNotificationsDropdown = false"
         (mouseenter)="toggleDropdown(true);showNotificationsDropdown = false"
         (mouseleave)="toggleDropdown(false)">
      <div class="d-flex align-items-center cursor-pointer">
        <div *ngIf="storeLogo && !useDefaultStoreIcon" class="sb-header__store-logo mr-2"
             [style.background-image]='storeLogo'>
        </div>
        <div *ngIf="useDefaultStoreIcon" class="sb-header__store-logo default-store-icon mr-2 green-light-background">
          <fa-icon [icon]="['fas', 'user-robot']" class="blue-color" size="2x"></fa-icon>
        </div>
      </div>
      <div *ngIf="showDropdown" class="user-menu-dropdown white-background border-top green-border dropdown_menu--animated">
        <div class="p-2 d-md-block text-center border-bottom-2 blue-border">
          <div [class.text-danger]="isImpersonating" class="text-truncate store-name">{{ storeName }}</div>
          <a class="change-store-link" *ngIf="user.stores?.length > 1" (click)="openChangeStore(content)">
            Change Store
          </a>
        </div>
        <hr class="my-1">
        <a routerLink="/store/settings/general" class="dropdown-item user-dropdown-item cursor-pointer">
          <fa-icon [icon]="['fas', 'cog']"></fa-icon>
          <span class="text-center">Settings</span>
        </a>
        <a routerLink="/billing" class="dropdown-item user-dropdown-item cursor-pointer">
          <fa-icon [icon]="['fas', 'credit-card']"></fa-icon>
          <span class="text-center">Subscription & Add-ons</span>
        </a>
        <hr class="my-1" *ngIf="user.trueUserAdmin || user.admin">
        <a routerLink="/admin/stores" class="dropdown-item user-dropdown-item cursor-pointer" *ngIf="user.trueUserAdmin || user.admin">
          <fa-icon [icon]="['fas', 'tachometer-alt']" class="gold-color"></fa-icon>
          <span class="text-center">Admin Dashboard</span>
        </a>
        <a [routerLink]="['/admin/stores/', user.currentStore.id]" class="dropdown-item user-dropdown-item cursor-pointer" *ngIf="user.trueUserId">
          <fa-icon [icon]="['fas', 'store-alt']" class="gold-color"></fa-icon>
          <span class="text-center">Store Admin Page</span>
        </a>
        <a (click)="stopImpersonating()" *ngIf="isImpersonating" class="dropdown-item user-dropdown-item  cursor-pointer">
          <fa-icon [icon]="['fas', 'user-slash']" class="gold-color"></fa-icon>
          <span class="text-center">Stop Impersonating</span>
        </a>
        <hr class="my-1">
        <a (click)="logout()" class="cursor-pointer dropdown-item user-dropdown-item">
          <fa-icon [icon]="['fas', 'sign-out-alt']"></fa-icon>
          <span class="text-center">Log Out</span>
        </a>
      </div>
    </div>
    <div class="sb-header__dropdown mx-3 my-2" style="float:right;"
         id="sb-header__notifications__dropdown"
         (click)="toggleNotificationsDropdown(!showNotificationsDropdown, 1);showDropdown = false"
         (mouseenter)="toggleNotificationsDropdown(true);showDropdown = false"
         (mouseleave)="toggleNotificationsDropdown(false)">
      <fa-icon size="2x" class="blue-color" [icon]="['fas', 'bell']"></fa-icon>
      <span *ngIf="newNotificationsCount > 0" class="notification-count">{{ newNotificationsCount}}</span>
      <div *ngIf="showNotificationsDropdown" class="user-menu-dropdown notification-dropdown white-background border-top green-border dropdown_menu--animated">
        <div *ngIf="notifications?.length === 0" class="p-3 dropdown-item text-center">
          No notifications.
        </div>
        <ng-container *ngFor="let notification of notifications">
          <div class="dropdown-item notification-item"
               (click)="clickNotification(notification)"
               [class.green-light-background]="!notification.dismissed">
            <fa-icon [icon]="['fas', notification.icon ? notification.icon : 'exclamation-circle']" class="blue-color mr-2"></fa-icon>
            <strong>{{ notification.title }}</strong><small class="float-right">{{ notification.createdAt | date: 'MM/dd/yyyy - hh:mm aa' }}</small>
            <div [innerHTML]="notification.content"></div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header bg-secondary">
    <div class="row w-100">
      <div class="col-6">
        <h6 class="modal-title text-light">Change store</h6>
      </div>
      <div class="col-6">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span class="text-light" aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <ul class="list-group">
      <ng-container *ngFor="let userStore of user.stores">
        <li *ngIf="userStore.id !== store.id"
            class="list-group-item d-flex align-items-center justify-content-between">
          <div>
            <div>{{ userStore.name }}</div>
            <small>{{ userStore.url }}</small>
          </div>
          <div>
            <button (click)="onChangeStore(userStore.id)" class="btn btn-primary">
              Switch
            </button>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>

<ng-template #createModalContent let-modal>
  <div class="card">
    <div class="card-header text-center py-4 bg-secondary">
      <h2 class="m-auto white-color">
        I want to create...
      </h2>
    </div>
    <div class="card-body col-md-12 text-center px-4">
      <div class="row">
        <div class="col-12 py-4 px-4">
          <div class="row">
            <div class="col-6 py-2">
              <a class="btn btn-primary w-100" routerLink="/email_campaigns/new/template" (click)="modal.dismiss()">Email Campaign</a>
            </div>
            <div class="col-6 py-2">
              <a class="btn btn-primary w-100" (click)="openCreatePostModal();modal.dismiss();">Social Post</a>
            </div>
            <div class="col-6 py-2">
              <a class="btn btn-primary w-100" (click)="createForm();modal.dismiss();">Signup Form</a>
            </div>
            <div class="col-6 py-2">
              <a class="btn btn-primary w-100" (click)="createFlow();modal.dismiss();">Automation</a>
            </div>
            <div class="col-6 py-2" *ngIf="store.canUseSMSMarketing">
              <a class="btn btn-primary w-100" [routerLink]="'/sms_campaigns/new'" (click)="modal.dismiss()">SMS Campaign</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #popupNotificationContent let-modal>
  <div class="modal-header">
    <div class="text-center">
      <fa-icon [icon]="['fas', popupNotification.icon]" *ngIf="popupNotification.icon" class="green-color" size="3x"></fa-icon>
      <h3 class="mt-2 mb-0">{{ popupNotification.title }}</h3>
    </div>
  </div>
  <div class="modal-body">
    <div class="my-2 text-center">
      <p style="font-size:1rem;" [innerHTML]="popupNotification.content"></p>
    </div>
    <div class="text-center my-3" *ngIf="!!popupNotification.imageUrl">
      <img [src]="popupNotification.imageUrl" style="max-width: 90%">
    </div>
  </div>

  <div class="modal-footer text-center">
    <a type="button" *ngIf="popupNotification.url"
       (click)="modal.close();clickNotification(popupNotification);"
       class="btn btn-secondary mx-2">
      {{ popupNotification.buttonText || "Learn more" }}
    </a>
    <button type="button"
            (click)="dismissPopupNotification(popupNotification, modal)"
            class="btn btn-success mx-2">
      Dismiss
    </button>
  </div>
</ng-template>
