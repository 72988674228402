import { CommonModule, CurrencyPipe, DecimalPipe, TitleCasePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RouterModule } from "@angular/router";
import { ApiKeyGeneratorComponent } from "@app/shared/components/api_key_generator/api_key_generator.component";
import { BreadcrumbsComponent } from "@app/shared/components/breadcrumbs/breadcrumbs.component";
import { EmailVerificationAlertComponent } from "@app/shared/components/email_verification_alert/email_verification_alert.component";
import { CreditCardFailedComponent } from "@app/shared/components/credit_card_failed/credit_card_failed.component";
import { DropdownSegmented } from "@app/shared/components/dropdown_segmented/dropdown_segmented.component";
import { DropdownWithList } from "@app/shared/components/dropdown_with_list/dropdown_with_list.component";
import { DropdownWithMultiList } from "@app/shared/components/dropdown_with_multi_list/dropdown_with_multi_list.component";
import { DynamicSegmentEditModalComponent } from "@app/shared/components/dynamic_segment_edit_modal/dynamic_segment_edit_modal.component";
import { EmailListsAddApiPage } from "@app/shared/components/email_lists/add_api/add_api.page";
import { ExpandableList } from "@app/shared/components/expandable_list/expandable_list";
import { GeoCountriesDropdown } from "@app/shared/components/geo_countries_dropdown/geo_countries_dropdown";
import { GeoSubregionsDropdown } from "@app/shared/components/geo_subregions_dropdown/geo_subregions_dropdown";
import { ImageUploadComponent } from "@app/shared/components/image_upload/image_upload.component";
import { ModalInputFieldContent } from "@app/shared/components/modal_input_field/modal_input_field";
import { ModalPhoneNumberContent } from "@app/shared/components/modal_phone_number/modal_phone_number";
import { NotFound } from "@app/shared/components/not_found/not_found";
import { PardotScheduleDemoModalComponent } from "@app/shared/components/pardot_schedule_demo_modal/pardot_schedule_demo_modal.component";
import { PreviewModal } from "@app/shared/components/preview_modal/preview_modal";
import { ProductSelectModalComponent } from "@app/shared/components/product_select_modal/product_select_modal.component";
import { SbColorPickerComponent } from "@app/shared/components/sb_color_picker/sb_color_picker.component";
import { ModalDialogueContent } from "@app/shared/components/sb_modal_dialogue/sb_modal_dialogue";
import { StepsDots } from "@app/shared/components/steps_dots/steps_dots";
import { UploadFile } from "@app/shared/components/upload_file/upload_file";
import { SenderEmailDomainValidationDirective } from "@app/shared/directives/sender_email_domain_validation.directive";
import { ForbiddenValidatorDirective } from "@app/shared/directives/restricted_pattern_validation.directive";
import { DefaultImageDirective } from "@app/shared/directives/default_image.directive";
import { PendingChangesGuard } from "@app/shared/guards/can_deactivate_unsaved_changes_confirmation.guard";
import { AbsValuePipe } from "@app/shared/pipes/abs_value.pipe";
import { PhoneFormatPipe } from "@app/shared/pipes/phone-format.pipe";
import { NoSanitizePipe } from "@app/shared/pipes/nosanitizer.pipe";
import { PrettyTimePipe } from "@app/shared/pipes/pretty_time.pipe";
import { SafeSrcPipe } from "@app/shared/pipes/safe_src.pipe";
import { TimeAgoPipe } from "@app/shared/pipes/time_ago.pipe";
import { SecondsToDaysPipe } from "@app/shared/pipes/seconds_to_days.pipe";
import { VisibleStoresPipe } from "@app/shared/pipes/visible_stores.pipe";
import { NgbModule, NgbAlertModule } from "@ng-bootstrap/ng-bootstrap";
import { AccordionModule } from 'ngx-bootstrap/accordion';

import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { NgSelectModule } from "@ng-select/ng-select";

import { NgChartsModule } from "ng2-charts";
import "./defaults/charts/charts";

import { NgxMaskModule } from "ngx-mask";
import { CustomFormsModule } from "ngx-custom-validators";

import { TemplateSelectionComponent } from "@app/shared/components/template_selection/template_selection.component";
import { CanDeactivateGuard } from "@app/shared/guards/can-deactivate.guard";
import { DateRangeComponent } from "./components/date_range/date_range.component";
import { EmailCampaignsChartComponent } from "./components/email_campaigns_chart/email_campaigns_chart.component";
import { EmailPreviewComponent } from "./components/email_preview/email_preview.component";
import { EmailTemplateCard } from "./components/email_template_card/email_template_card.component";
import { SbFooterComponent } from "./components/layouts/components/sb-footer/sb-footer.component";
import { MainMenuComponent } from "./components/layouts/main_menu.component";
import { AdminMainMenuComponent } from "./components/layouts/admin_main_menu.component";
import { SbWizardComponent } from "./components/layouts/components/sb-wizard/sb-wizard";
import { StrippedHeaderComponent } from "./components/layouts/stripped_header.component";
import { LoadingOverlayComponent } from "./components/loading_overlay/loading_overlay.component";
import { IframePreviewDesktopComponent } from "./components/iframe_preview_desktop/iframe_preview_desktop.component";
import { IframePreviewMobileComponent } from "./components/iframe_preview_mobile/iframe_preview_mobile.component";
import { MobilePopupPreviewComponent } from "./components/mobile_popup_preview/mobile_popup_preview.component";
import { OrdersAndRevenueChartComponent } from "./components/orders_and_revenue_chart/orders_and_revenue_chart.component";
import { SMSPreviewComponent } from "./components/sms_preview/sms_preview.component";
import { SubscribersChartComponent } from "./components/subscribers_chart/subscribers_chart.component";
import { TimePickerComponent } from "./components/time_picker/time_picker.component";
import { TopProductsChartComponent } from "./components/top_products_chart/top_products_chart.component";
import { MenuButtonsDirective } from "./directives/menu_buttons.directive";
import { SortableHeaderDirective } from "./directives/sortable_header.directive";
import { SortableTableDirective } from "./directives/sortable_table.directive";
import { UserOwnerGuard } from "./guards/user-owner.guard";
import { UserGuard } from "./guards/user.guard";
import { S3UploadApiService } from "./services/s3_upload_api.service";
import { StoreService } from "./services/store.service";
import { UserService } from "./services/user.service";
import { ScriptService } from "@app/shared/services/script.service";

import { PostalCodeValidationDirective } from "./directives/postal_code_validation.directive";
import { HumanizePipe } from "@app/shared/pipes/humanize.pipe";
import { SMSKeywordService } from "@app/shared/services/sms_keywords.service";
import {
  MailChimpConfigModalComponent
} from "@app/shared/components/mail_chimp_config_modal/mail_chimp_config_modal.component";
import { GoogleConfigModalComponent } from "@app/shared/components/google_config_modal/google_config_modal.component";
import { AdrollConfigModalComponent } from "@app/shared/components/adroll_config_modal/adroll_config_modal.component";
import {
  CaptchaConfigModalComponent
} from "@app/shared/components/captcha_config_modal/captcha_config_modal.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ZapierConfigModalComponent } from "@app/shared/components/zapier_config_modal/zapier_config_modal.component";
import {
  InstagramConfigModalComponent
} from "@app/shared/components/instagram_config_modal/instagram_config_modal.component";
import {
  FacebookConfigModalComponent
} from "@app/shared/components/facebook_config_modal/facebook_config_modal.component";
import { SbHeaderComponent } from "@app/shared/components/layouts/components/sb-header/sb-header.component";
import { SbNavComponent } from "@app/shared/components/layouts/components/sb-nav/sb-nav.component";
import {
  OnsiteAbandonmentSendPreviewModalComponent
} from "@app/shared/components/onsite_abandonment_send_preview_modal/onsite_abandonment_send_preview_modal.component";
import { RadioButtonComponent } from "@app/shared/components/sb_radio_button/sb_radio_button.component";
import { FooterOnlyLayoutComponent } from "@app/shared/components/layouts/footer_only/footer_only.component";
import { UpgradeButtonComponent } from "@app/shared/components/sb_upgrade_button/sb_upgrade_button.component";
import { ToggleComponent } from "@app/shared/components/sb_toggle/sb_toggle.component";
import { StoreNotificationsService } from "@app/shared/services/store_notifications.service";

import { EmailCampaignAccoladesComponent } from "@app/shared/components/email-campaign-accolades/email-campaign-accolades.component";
import { TemplateCardComponent } from "@app/shared/components/template-card/template-card.component";
import {
  OnsiteAbandonmentSettingsComponent
} from "@app/shared/components/onsite_abandonment_settings/onsite_abandonment_settings.component";

import { StepperComponent } from "./components/stepper/stepper.component";
import { UniversalErrorService } from "@app/shared/services/universal_error_service";
import {
  AdrollPerformanceGraphComponent
} from "@app/ads/components/adroll_performance_graph/adroll_performance_graph.component";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { ContentBlockService } from "@app/shared/services/content_block.service";
import { FlowService } from "@app/shared/services/flow.service";
import { EmailTemplateService } from "@app/shared/services/email_template.service";
import { UploadService } from "@app/segments/services/upload.service";
import { OnsiteAbandonmentEmailService } from "@app/shared/services/onsite_abandonment_email.service";
import { SbErrorComponent } from "@app/shared/components/sb_error/sb_error.component";
import {StorageService} from "@app/shared/services/storage.service";

import { AiDesignerModalComponent } from "@app/shared/components/ai_designer_modal/ai_designer_modal.component";
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from "ng-recaptcha";
import { environment } from "@environments/environment";

@NgModule({
  declarations: [
    SubscribersChartComponent,
    OrdersAndRevenueChartComponent,
    EmailCampaignsChartComponent,
    DateRangeComponent,
    TimePickerComponent,
    TopProductsChartComponent,
    NotFound,
    ModalDialogueContent,
    SbColorPickerComponent,
    UpgradeButtonComponent,
    RadioButtonComponent,
    ToggleComponent,
    StepsDots,
    DropdownWithList,
    DropdownWithMultiList,
    DropdownSegmented,
    SbWizardComponent,
    SbHeaderComponent,
    SbNavComponent,
    SbFooterComponent,
    BreadcrumbsComponent,
    EmailVerificationAlertComponent,
    EmailTemplateCard,
    UploadFile,
    EmailPreviewComponent,
    ModalInputFieldContent,
    ModalPhoneNumberContent,
    PreviewModal,
    LoadingOverlayComponent,
    GeoCountriesDropdown,
    GeoSubregionsDropdown,
    EmailListsAddApiPage,
    MainMenuComponent,
    AdminMainMenuComponent,
    StrippedHeaderComponent,
    FooterOnlyLayoutComponent,
    MenuButtonsDirective,
    MainMenuComponent,
    IframePreviewDesktopComponent,
    IframePreviewMobileComponent,
    MobilePopupPreviewComponent,
    MenuButtonsDirective,
    PostalCodeValidationDirective,
    SenderEmailDomainValidationDirective,
    ForbiddenValidatorDirective,
    DefaultImageDirective,
    SMSPreviewComponent,
    SortableTableDirective,
    SortableHeaderDirective,
    ApiKeyGeneratorComponent,
    HumanizePipe,
    ImageUploadComponent,
    ProductSelectModalComponent,
    AbsValuePipe,
    TemplateSelectionComponent,
    TimeAgoPipe,
    VisibleStoresPipe,
    ExpandableList,
    PrettyTimePipe,
    SafeSrcPipe,
    SecondsToDaysPipe,
    PhoneFormatPipe,
    NoSanitizePipe,
    CreditCardFailedComponent,
    DynamicSegmentEditModalComponent,
    PardotScheduleDemoModalComponent,
    MailChimpConfigModalComponent,
    GoogleConfigModalComponent,
    AdrollConfigModalComponent,
    CaptchaConfigModalComponent,
    ZapierConfigModalComponent,
    InstagramConfigModalComponent,
    FacebookConfigModalComponent,
    OnsiteAbandonmentSendPreviewModalComponent,
    EmailCampaignAccoladesComponent,
    TemplateCardComponent,
    OnsiteAbandonmentSettingsComponent,
    StepperComponent,
    AdrollPerformanceGraphComponent,
    SbErrorComponent,
    AiDesignerModalComponent,
  ],
  exports: [
    AccordionModule,
    SubscribersChartComponent,
    OrdersAndRevenueChartComponent,
    EmailCampaignsChartComponent,
    TopProductsChartComponent,
    CommonModule,
    NgbModule,
    CustomFormsModule,
    ReactiveFormsModule,
    ModalDialogueContent,
    RadioButtonComponent,
    SbColorPickerComponent,
    ModalInputFieldContent,
    ModalPhoneNumberContent,
    StepsDots,
    DropdownWithList,
    DropdownWithMultiList,
    DropdownSegmented,
    SbWizardComponent,
    SbFooterComponent,
    EmailTemplateCard,
    UploadFile,
    PreviewModal,
    LoadingOverlayComponent,
    EmailPreviewComponent,
    SMSPreviewComponent,
    NgChartsModule,
    PickerModule,
    MainMenuComponent,
    AdminMainMenuComponent,
    StrippedHeaderComponent,
    FooterOnlyLayoutComponent,
    MenuButtonsDirective,
    GeoCountriesDropdown,
    GeoSubregionsDropdown,
    EmailListsAddApiPage,
    IframePreviewDesktopComponent,
    IframePreviewMobileComponent,
    MobilePopupPreviewComponent,
    PostalCodeValidationDirective,
    SenderEmailDomainValidationDirective,
    ForbiddenValidatorDirective,
    DefaultImageDirective,
    MenuButtonsDirective,
    SortableTableDirective,
    SortableHeaderDirective,
    ApiKeyGeneratorComponent,
    DateRangeComponent,
    TimePickerComponent,
    HumanizePipe,
    TemplateSelectionComponent,
    ImageUploadComponent,
    ProductSelectModalComponent,
    AbsValuePipe,
    TimeAgoPipe,
    VisibleStoresPipe,
    ExpandableList,
    PrettyTimePipe,
    SafeSrcPipe,
    SecondsToDaysPipe,
    PhoneFormatPipe,
    NoSanitizePipe,
    CreditCardFailedComponent,
    BreadcrumbsComponent,
    EmailVerificationAlertComponent,
    DynamicSegmentEditModalComponent,
    PardotScheduleDemoModalComponent,
    UpgradeButtonComponent,
    EmailCampaignAccoladesComponent,
    TemplateCardComponent,
    OnsiteAbandonmentSettingsComponent,
    StepperComponent,
    AdrollPerformanceGraphComponent,
    SbErrorComponent,
    AiDesignerModalComponent,
  ],
  imports: [
    AccordionModule,
    FontAwesomeModule,
    CustomFormsModule,
    FormsModule,
    ReactiveFormsModule,
    NgChartsModule,
    PickerModule,
    CommonModule,
    NgbModule,
    RouterModule,
    NgxMaskModule,
    NgSelectModule,
    NgbAlertModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  providers: [
    CurrencyPipe,
    HumanizePipe,
    TitleCasePipe,
    DecimalPipe,
    PhoneFormatPipe,
    NoSanitizePipe,
    S3UploadApiService,
    UploadService,
    ScriptService,
    StoreService,
    UserService,
    UniversalErrorService,
    UserGuard,
    UserOwnerGuard,
    StoreNotificationsService,
    SMSKeywordService,
    CanDeactivateGuard,
    PendingChangesGuard,
    DeserializeService,
    ContentBlockService,
    FlowService,
    EmailTemplateService,
    OnsiteAbandonmentEmailService,
    StorageService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.reCAPTCHASiteKey } as RecaptchaSettings,
    },
  ],
})
export class SharedModule { }
