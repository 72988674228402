import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OnsiteAbandonmentEmail } from "@models/onsite_abandonment_email";
import { OnsiteAbandonmentEmailConfiguration } from "@models/onsite_abandonment_email_configuration";
import { Response } from "@models/response";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { BehaviorSubject, Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { environment } from "@environments/environment";

@Injectable()
export class OnsiteAbandonmentEmailService {
  private base = `${environment.apiUrl}/v2/onsite_abandonment/emails`;
  private emailSource$ = new BehaviorSubject<OnsiteAbandonmentEmail>(null);

  constructor(
    private httpClient: HttpClient,
    private deserializeService: DeserializeService,
  ) {}

  getEmail(): BehaviorSubject<OnsiteAbandonmentEmail> {
    return this.emailSource$;
  }

  setEmail(email: OnsiteAbandonmentEmail): void {
    this.emailSource$.next(email);
  }

  index(dateRange: string): Observable<Response<OnsiteAbandonmentEmail[]>> {
    const params = {
      startDate: dateRange,
      stats: "true",
    };
    return this.httpClient.get(this.base, { params }).pipe(
      mergeMap(async (response: any) => {
        const emails = this.deserializeService.deserialize(response);
        return new Response(emails, response.meta);
      })
    );
  }

  show(id) {
    return this.httpClient
      .get<OnsiteAbandonmentEmail>(`${environment.apiUrl}/v2/onsite_abandonment/emails/${id}/`, {})
      .pipe(
        mergeMap(
          async (response) => {
            const email = this.deserializeService.deserialize(response)
            this.setEmail(email);
            return email;
          }
        )
      );
  }

  update(oaEmail: OnsiteAbandonmentEmail) {
    return this.httpClient
      .put<OnsiteAbandonmentEmail>(`${environment.apiUrl}/v2/onsite_abandonment/emails/${oaEmail.id}/`, oaEmail)
      .pipe(
        mergeMap(
          async (response) => {
            const email = this.deserializeService.deserialize(response)
            this.setEmail(email);
            return email;
          }
        )
      );
  }

  create(params: any): Observable<OnsiteAbandonmentEmail> {
    return this.httpClient
      .post<OnsiteAbandonmentEmail>(`${environment.apiUrl}/v2/onsite_abandonment/emails/`, params)
      .pipe(
        mergeMap(
          async (response) => {
            const email = this.deserializeService.deserialize(response)
            this.setEmail(email);
            return email;
          }
        )
      );
  }

  getConfiguration(): Observable<OnsiteAbandonmentEmailConfiguration> {
    return this.httpClient
      .get<OnsiteAbandonmentEmailConfiguration>(
        `${environment.apiUrl}/v2/onsite_abandonment/emails/get_configuration`
      )
      .pipe(
        mergeMap(
          async (response) =>
            this.deserializeService.deserialize(response)
        )
      );
  }

  updateConfiguration(
    config: OnsiteAbandonmentEmailConfiguration,
  ): Observable<OnsiteAbandonmentEmailConfiguration> {
    return this.httpClient.put<OnsiteAbandonmentEmailConfiguration>(`${environment.apiUrl}/v2/onsite_abandonment/emails/update_configuration`, config)
      .pipe(mergeMap(async (response) => this.deserializeService.deserialize(response)));
  }

  sendPreview(id: string, recipients: string) {
    return this.httpClient.post(
      `${environment.apiUrl}/v2/onsite_abandonment/emails/${id}/send_preview`,
      { recipients }
    );
  }

  showPreview(id: string) {
    return this.httpClient.get(
      `${environment.apiUrl}/v2/onsite_abandonment/emails/${id}/show_preview`,
      {}
    );
  }
}
