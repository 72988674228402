import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { TriggeredEmailTemplate } from "@models/triggered_email_template";
import { environment } from "@environments/environment";

@Injectable({ providedIn: "root" })
export class TriggeredEmailTemplateService {
  private endpoint = `${environment.apiUrl}/v2/triggered_email_templates`;
  private readonly headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private deserializeService: DeserializeService,
  ) {}

  // returns triggered_email_template (master template) for current store
  getTemplate(): Observable<TriggeredEmailTemplate> {
    return this.http
      .get<TriggeredEmailTemplate>(this.endpoint, {
        params: { brandingUpdateRequired: "true" },
      })
      .pipe(mergeMap((response) => this.deserializeTemplate(response)));
  }

  // returns triggered emails created by default for current store
  getCreatedByDefault(): Observable<TriggeredEmailTemplate[]> {
    return this.http
      .get<TriggeredEmailTemplate[]>(
        `${this.endpoint}/get_created_by_default`,
        { params: { brandingUpdateRequired: "true" } }
      )
      .pipe(mergeMap((response) => this.deserializeTemplates(response)));
  }

  updateMasterTemplate(id, params): Observable<TriggeredEmailTemplate> {
    return this.http.patch<TriggeredEmailTemplate>(
      `${environment.apiUrl}/v2/features/triggered_email_templates/${id}`,
      { triggered_email_template: params },
    );
  }

  private async deserializeTemplate(
    templateData
  ): Promise<TriggeredEmailTemplate> {
    return (this.deserializeService.deserialize(templateData)) as TriggeredEmailTemplate;
  }

  private async deserializeTemplates(
    templatesData
  ): Promise<TriggeredEmailTemplate[]> {
    return (this.deserializeService.deserialize(templatesData)) as TriggeredEmailTemplate[];
  }
}
