import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { AdrollConfigModalComponent } from "@app/shared/components/adroll_config_modal/adroll_config_modal.component";
import {
  CaptchaConfigModalComponent,
} from "@app/shared/components/captcha_config_modal/captcha_config_modal.component";
import {
  FacebookConfigModalComponent,
} from "@app/shared/components/facebook_config_modal/facebook_config_modal.component";
import { GoogleConfigModalComponent } from "@app/shared/components/google_config_modal/google_config_modal.component";
import { ZapierConfigModalComponent } from "@app/shared/components/zapier_config_modal/zapier_config_modal.component";
import { InstagramConfigModalComponent } from "../components/instagram_config_modal/instagram_config_modal.component";
import { MailChimpConfigModalComponent } from "../components/mail_chimp_config_modal/mail_chimp_config_modal.component";

import { ApisService } from "@app/segments/services/apis.service";
import { AdrollConfigurationService } from "@app/shared/services/adroll_configuration.service";
import { GoogleAnalyticsConfigurationService } from "@app/shared/services/google_analytics_configuration.service";
import { StoreService } from "@app/shared/services/store.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { mergeMap, take } from "rxjs/operators";

import { EmailProvider } from "@models/apis/emailProvider";
import { Subprovider } from "@models/apis/subprovider";
import { Authorization } from "@models/authorization";
import { environment } from "@environments/environment";
import { AuthTokenService } from "@app/auth/services/auth-token.service";
import { DeserializeService } from "@app/shared/services/deserialize.service";

@Injectable({
  providedIn: "root",
})
export class AuthorizationService {
  private endpoints: any;

  constructor(
      private http: HttpClient,
      private modalService: NgbModal,
      private googleAnalyticsConfigurationService: GoogleAnalyticsConfigurationService,
      private adrollConfigurationService: AdrollConfigurationService,
      private storeService: StoreService,
      private apisService: ApisService,
      private authTokenService: AuthTokenService,
      private deserializeService: DeserializeService,
  ) {
    const authToken = authTokenService.getToken();
    this.endpoints = {
      "constant-contact": {
        auth: "/newsletters_api/provider/authorization/new?service_name=Constant_Contact&auth_token=" + authToken,
        disconnect: "/newsletters_api/provider/authorization?service_name=Constant_Contact&auth_token=" + authToken,
      },
      facebook: {
        api: "/v2/integrations/facebook?auth_token=" + authToken,
        auth: "/v2/integrations/facebook_handle?provider=facebook&auth_token=" + authToken,
      },
      "google-analytics": {
        api: "/v2/integrations/google_oauth2?auth_token=" + authToken,
        auth: "/v2/integrations/google_oauth2/start?auth_token=" + authToken,
      },
      instagram: {
        api: "/v2/integrations/instagram?auth_token=" + authToken,
        auth: "/v2/integrations/facebook_handle?provider=instagram&auth_token=" + authToken,
      },
      mailchimp: {
        auth: "/newsletters_api/provider/authorization/new?service_name=MailChimp&auth_token=" + authToken,
        disconnect: "/newsletters_api/provider/authorization?service_name=MailChimp&auth_token=" + authToken,
      },
      twitter: {
        api: "/v2/integrations/twitter?auth_token=" + authToken,
        auth: "/v2/integrations/twitter/start?auth_token=" + authToken,
      },
      smile: {
        api: "/v2/integrations/smile?auth_token=" + authToken,
        auth: "/v2/integrations/smile/start?auth_token=" + authToken,
      },
    };
  }

  connect(integration: string, uri?: string): Observable<any> {
    const configurableIntegrations = ["adroll", "recaptcha", "zapier"];
    if (configurableIntegrations.includes(integration)) {
      return this.configure(integration);
    }
    if (!uri) {
      uri = this.endpoints[integration].auth;
    }
    const subWindow = this.openSubWindow(`${environment.apiUrl}${uri}`, integration);
    return new Observable((observer) => {
      this.subWindowCloseOrReturn(subWindow).pipe(take(1)).subscribe(() => {
        this.pollIntegrationStatus(integration, observer, 0);
      });
    });
  }

  // Centers popup over the current window
  openSubWindow(url: string, integration: string, h = 750, w = 750): WindowProxy {
    const y = window.top.outerHeight / 2 + window.top.screenY - (h / 2);
    const x = window.top.outerWidth / 2 + window.top.screenX - (w / 2);
    return window.open(url, integration,
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,
       width=${w}, height=${h}, top=${y}, left=${x}`);
  }

  // observer returns true when the subWindow either closes OR redirects back to the current page
  // this keeps from ending up with the "Inception"
  // effect where pages spawn within pages within pages
  subWindowCloseOrReturn(subWindow: WindowProxy): Observable<boolean> {
    let pollCounter = 0;
    return new Observable((subscribe) => {
      const windowPoller = setInterval(() => {
        if (!subWindow.closed) {
          try {
            console.log(subWindow.location.href);
            if (subWindow.location.href.includes("store_setup")) {
              clearInterval(windowPoller);
              subWindow.close();
              subscribe.next(true);
            }
          } catch (_error) { // keeps cross-origin checks from throwing errors
          }
        } else if (subWindow.closed || pollCounter >= 900) {
          // has been polling for greater than three minutes
          clearInterval(windowPoller);
          subWindow.close();
          subscribe.next(true);
        }
        pollCounter++;
      }, 200);
    });
  }

  getEmailProvider(integration: string, params?): Observable<EmailProvider> {
    if (integration === "mailchimp" || integration === "constant-contact") {
      return new Observable((observer) => {
        this.apisService.index().subscribe((providers) => {
          const provider = providers.find((p) => p.name.toLowerCase() === integration.trim().replace("-", " "));
          provider.enabled = !!provider && provider.hasAccessToken;
          return observer.next(new EmailProvider(provider));
        });
      });
    }

    console.error("invalid authorization name");
    return new Observable<EmailProvider>();
  }

  getAuthorization(integration: string, params?): Observable<Authorization> {
    if (integration === "mailchimp" || integration === "constant-contact") {
      return new Observable((observer) => {
        this.apisService.index().subscribe((providers) => {
          const provider = providers.find((p) => p.name.toLowerCase() === integration.trim().replace("-", " "));
          if (provider) {
            provider.enabled = !!provider && provider.hasAccessToken;
          }
          return observer.next(new Authorization(provider));
        });
      });
    }
    return this.http.get<Authorization>(`${environment.apiUrl}${this.endpoints[integration].api}`, { params }).pipe(
        mergeMap(async (response) => this.deserializeService.deserialize(response)));
  }

  destroy(integration: string): Observable<any> {
    const disconnectUrl = this.endpoints[integration].disconnect || this.endpoints[integration].api;
    return this.http.delete<any>(`${environment.apiUrl}${disconnectUrl}`,);
  }

  configure(integration: string): Observable<any> {
    switch (integration) {
      case "adroll":
        return this.openAdrollModal();
      case "facebook":
        return this.openFacebookModal();
      case "google-analytics":
        return this.openGoogleModal();
      case "recaptcha":
        return this.openCaptchaModal();
      case "zapier":
        return this.openZapierModal();
      case "instagram":
        return this.openInstagramModal();
      case "mailchimp":
        return this.openMailChimpModal();
      default:
        return null;
    }
  }

  checkConfiguration(integration: string): Observable<any> {
    switch (integration) {
      case "adroll":
        return this.checkAdrollConfiguration();
      case "google-analytics":
        return this.checkGoogleConfiguration();
      case "recaptcha":
        return this.checkCaptchaConfiguration();
      default:
        return null;
    }
  }

  // For Mailchimp only
  changeSubproviderStatus(provider_id: string, subprovider: Subprovider): Observable<Subprovider> {
    const path = `${ environment.apiUrl }/v2/providers/${provider_id}/subproviders/${subprovider.id}`;

    return this.http.put<Subprovider>(
        path,
        subprovider,
    ).pipe(mergeMap(async (response) => this.deserializeService.deserialize(response)));
  }

  // For Mailchimp only
  refreshProviderList(provider: EmailProvider) {
    const path = `${ environment.apiUrl }/v2/providers/${provider.id}/refresh_lists`;
    return this.http.post<EmailProvider>(
        path,
        {},
    ).pipe(mergeMap(async (response) => this.deserializeService.deserialize(response)));
  }

  private pollIntegrationStatus(integration, observer, count: number): void {
    // TODO: Replace with websockets once we upgrade to Rails 5
    this.getAuthorization(integration).subscribe((authorization) => {
      if (authorization.enabled) {
        observer.next({ connected: true, errored: false, data: authorization });
      } else if (count < 100) { // Only poll for 5 minutes. (100 * 3s = 5m)
        setTimeout(() => {
          this.pollIntegrationStatus(integration, observer, count + 1);
        }, 3000);
      }
    }, (error) => {
      observer.next({ connected: false, errored: error.status !== 404, errors: [error] });
    });
  }

  private pollConfiguration(integration, observer, count: number): void {
    // TODO: Replace with websockets once we upgrade to Rails 5
    this.checkConfiguration(integration).subscribe((response) => {
      if (response.connected) {
        observer.next(response);
      } else if (count < 100) { // Only poll for 5 minutes. (100 * 3s = 5m)
        setTimeout(() => {
          this.pollConfiguration(integration, observer, count + 1);
        }, 3000);
      }
    }, (error) => {
      observer.next({ connected: false, errored: error.status !== 404, errors: [error] });
    });
  }

  private openAdrollModal() {
    return new Observable((observer) => {
      const adrollConfigModal = this.modalService.open(AdrollConfigModalComponent, { windowClass: "sb-modal" });
      adrollConfigModal.result.then((response) => {
        this.pollConfiguration("adroll", observer, 0);
        observer.next(response);
      }).catch(() => {
        this.pollConfiguration("adroll", observer, 0);
      });
    });
  }

  private openGoogleModal() {
    return new Observable((observer) => {
      const googleConfigModal = this.modalService.open(GoogleConfigModalComponent, { windowClass: "sb-modal" });
      googleConfigModal.result.then((response) => {
        observer.next(response);
      }).catch(() => {
        observer.next();
      });
    });
  }

  private openFacebookModal() {
    return new Observable((observer) => {
      const facebookConfigModal = this.modalService.open(FacebookConfigModalComponent, { windowClass: "sb-modal" });
      facebookConfigModal.result.then((response) => {
        observer.next(response);
      }).catch(() => {
        observer.next();
      });
    });
  }

  private openCaptchaModal() {
    return new Observable((observer) => {
      const captchaConfigModal = this.modalService.open(CaptchaConfigModalComponent, { windowClass: "sb-modal" });
      captchaConfigModal.result.then((response) => {
        observer.next(response);
      }).catch(() => {
        observer.next();
      });
    });
  }

  private openZapierModal() {
    return new Observable((observer) => {
      const zapierConfigModal = this.modalService.open(ZapierConfigModalComponent,
          { windowClass: "sb-modal", size: "lg" });
      zapierConfigModal.result.then((response) => {
        observer.next(response);
      }).catch(() => {
        observer.next();
      });
    });
  }

  private openInstagramModal() {
    return new Observable((observer) => {
      const instagramConfigModal = this.modalService.open(InstagramConfigModalComponent, { windowClass: "sb-modal" });
      instagramConfigModal.result.then((response) => {
        observer.next(response);
      }).catch(() => {
        observer.next();
      });
    });
  }

  private openMailChimpModal() {
    return new Observable((observer) => {
      const instagramConfigModal = this.modalService.open(MailChimpConfigModalComponent, { windowClass: "sb-modal" });
      instagramConfigModal.result.then((response) => {
        observer.next(response);
      }).catch(() => {
        observer.next();
      });
    });
  }

  // If google is connected, but not configured, we want it to show the Error state.
  private checkGoogleConfiguration(): Observable<any> {
    return new Observable((observer) => {
      this.googleAnalyticsConfigurationService.show().subscribe((response) => {
        const googleConfig = response.data.google_configuration;
        if (!!googleConfig && googleConfig.account_name
            && googleConfig.web_property_name
            && googleConfig.profile_name) {
          observer.next({ errored: false, connected: true });
        } else if (googleConfig) {
          observer.next({ errored: true, connected: true });
        } else {
          observer.next({ errored: false, connected: false });
        }
      }, () => {
        observer.next({ errored: true, connected: false });
      });
    });
  }

  // If AdRoll configuration exists, then it is configured.
  private checkAdrollConfiguration(): Observable<any> {
    return new Observable((observer) => {
      this.adrollConfigurationService.show().subscribe((response) => {
        const adrollConfiguration = response.data;
        const store = this.storeService.getSource().value;

        if (!!adrollConfiguration && !store.hasTrialPackage) {
          observer.next({
            errored: false,
            connecting: (adrollConfiguration.enabled
                && adrollConfiguration.progress.value < 90
                && adrollConfiguration.progress.value > 0),
            connected: adrollConfiguration.enabled,
            progress: adrollConfiguration.progress,
          });
        } else {
          observer.next(
              { errored: false, connected: false, progress: adrollConfiguration.progress },
          );
        }
      });
    });
  }

  private checkCaptchaConfiguration(): Observable<any> {
    return new Observable((observer) => {
      this.storeService.getStore().pipe(take(1)).subscribe({
        next: (store) => {
          if (!!store.googleSecretKey && !!store.googleSiteKey) {
            observer.next({ errored: false, connected: true });
          } else {
            observer.next({ errored: false, connected: false });
          }
        },
        error: (error) => {
          observer.next({ errored: true, connected: false });
        },
      });
    });
  }
}
