<div class="position-relative">
  <label>Age Restrictions</label>
  <div class="d-flex justify-content-between align-items-center border p-1 cursor-pointer"
       (click)="toggleDropdown()">
    <span></span>
    <div class="py-1">{{ selected?.name || default?.name }}</div>
    <fa-icon [icon]="['fas', dropdownExpanded ? 'caret-up' : 'caret-down']" class="green-color"></fa-icon>
  </div>
  <div *ngIf="dropdownExpanded"
       class="position-absolute bg-white border z-index-2000 w-100 cursor-pointer">
    <div class="dropdown-item"
         *ngFor="let option of options"
         (click)="select(option)">
      {{option.name}}
    </div>
  </div>
</div>
