export class ChannelsFilter {
  sort: string;
  page: number;
  perPage: number;

  startDate: Date;
  endDate: Date;

  constructor(params?: Partial<ChannelsFilter>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}
