import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { User } from "@models/user";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { BehaviorSubject, Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { environment } from "@environments/environment";
import { DynamicSegment } from "@models/dynamic_segment";
import { Response } from "@models/response";
import * as FullStory from "@fullstory/browser";
import { StoreService } from "@app/shared/services/store.service";
import { AuthService } from "@app/auth/services/auth.service";
import { Store } from "@models/store";

export interface MultiUserCreate {
  users: UserUpdate[];
  multiple: boolean;
}

// needs all fields to be optional, does not always update all fields
// TODO determine if either of the below interfaces are still needed
export interface UserUpdate {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone1?: string;
}

export interface UserCreate {
  firstName: string;
  lastName: string;
  email: string;
  nightly?: boolean;
  weekly?: boolean;
}

export interface UserRegister {
  noStore: boolean;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

@Injectable({
  providedIn: "root",
})
export class UserService {
  private userSource$ = new BehaviorSubject<User>(null);

  constructor(
    private http: HttpClient,
    private storeService: StoreService,
    private authService: AuthService,
    private deserializeService: DeserializeService,
  ) {}

  // gets current user or with id, the user the id belongs to
  show(id: string | null = null, params: any = {}): Observable<User> {
    return this.http.get<User>(this.userUrl(id), { params }).pipe(
      mergeMap(async (response: any) => {
        const user = this.deserializeService.deserialize(response.data);
        const stores = this.deserializeService.deserialize(response.included);
        this.setUser(user);
        if (stores) {
          user.stores.forEach((store) => {
            Object.assign(store, stores.find((x) => x.id === store.id));
          });
        }
        return user;
      }),
    );
  }

  // default return is all users for store (owners and non-owners)
  // @params {
  //    non_owners: "true" returns only non-owners
  // }
  index(options: { non_owners?: "true"; include?: string } = {}): Observable<Response<User[]>> {
    const path = `${environment.apiUrl}/v2/users`;
    return this.http.get<User[]>(path, { params: options }).pipe(mergeMap(async (response: any) => {
      const users = this.deserializeService.deserialize(response);

      return new Response(users, response.meta);
    }));
  }

  create(body: UserCreate | UserRegister): Observable<User> {
    return this.http.post<any>(`${environment.apiUrl}/v2/users`, body).pipe(
      mergeMap(async (response) => this.deserializeService.deserialize(response)),
    );
  }

  // updates the current user by default
  // with id given, updates user the id belongs to
  update(body: User, id: string | null = null): Observable<User> {
    return this.http.put<DynamicSegment>(this.userUrl(id), body).pipe(
      mergeMap(async (response) => this.deserializeService.deserialize(response)),
    );
  }

  destroy(id: string): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}/v2/users/${id}`);
  }

  // sends an array of user json objects to backend for mass update/create
  // those with id's are updated, those without are created
  createOrUpdateMulti(body: MultiUserCreate): Observable<Response<User[]>> {
    return this.http.post<any>(`${environment.apiUrl}/v2/users`, body, {
      params: { include: "springbot_email_subscriptions" },
    }).pipe(mergeMap(async (response: any) => {
      const users = this.deserializeService.deserialize(response);

      return new Response(users, response.meta);
    }));
  }

  // 99% of the time user is current, but for specific scenarios,
  // (the addition of sub-users to a store in store setup), you can add an id
  userUrl(id: string | null): string {
    return `${environment.apiUrl}/v2/users/${id || "current"}`;
  }

  getSource() {
    return this.userSource$;
  }

  setUser(user: User) {
    this.identifyUser(user);
    this.userSource$.next(user);
  }

  identifyUser(user: User): void {
    this.storeService.getStore().subscribe((store) => {
      if (!!user && environment.production && !user.admin) {
        this.fullStoryIdentify(store, user);
        this.intercomIdentify(store, user);
      }
    });
  }

  private fullStoryIdentify(store: Store, user: User) {
    const customVars = {
      "displayName": store?.name,
      "email": user.email,
      "impersonated_bool": this.authService.isImpersonating(),
      "billingplan_str": store?.package?.packageName,
      "plugininstalled_bool": !!store?.pluginVersion,
      "storeconnected_bool": !!store
    };
    FullStory.identify(store.id.toString(), customVars);
  }

  private intercomIdentify(store: Store, user: User) {
    if (!!(<any>window).Intercom) {
      (<any>window).Intercom("boot", {
        app_id: environment.intercomAppId,
        user_hash: user.intercomHmac,
        user_id: user.id,
      });
    }
  }
}
