import { Component, ViewEncapsulation } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { S3UploadApiService } from "@app/shared/services/s3_upload_api.service";
import { MjmlGeneratorService } from "@app/shared/services/mjml_generator_service";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@models/store";
import { AIModuleModalService } from "@app/content_editor/components/email_content_editor/services/ai_module_modal.service";

@Component({
  selector: 'ai-module-modal',
  templateUrl: './ai_module_modal.component.html',
  styleUrls: ['./ai_module_modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AIModuleModalComponent {  
  sectionPrompt = "";
  loadingFeaturedImage = false;
  loadingBackgroundImage = false;
  featuredImageUrl = "";
  backgroundImageUrl = "";
  campaignId = "";
  submitting = false;
  contentType = "section"
  showImages = true;
  errorMsg = false;
  isAskChatGPTInputSectionCollapsed: false;
  isChatGPTResutlsSectionCollapsed: false;
  verificationPoll: any;
  aiTookFoeverPoll: any;
  generatedMJMLWorkerId = null;
  mjmlContentsResult = [];
  htmlContents = [];
  selectedOption = 0;
  carouselOnly = false;
  loadingInit = true;
  aiTookForever: boolean = false;

  store: Store;
  requestLimit: number = 20;
  requestsThisMonth: number = 0;
  remainingRequests: number = 0;
  remoteWorking: boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    private s3: S3UploadApiService,
    private mjmlGeneratorService: MjmlGeneratorService,
    private route: ActivatedRoute,
    private aIModuleModalService: AIModuleModalService,
    ) {}

  ngOnInit(): void {
    this.loadingInit = true;
    this.showImages = this.contentType === "mj-section" || this.contentType === "mj-column";
    clearInterval(this.verificationPoll);
    this.getRequestLimit()
    this.sectionPrompt = "";
  }

  searchContent(askChatGPTAccordion): void {
    this.mjmlContentsResult = [];
    this.htmlContents = [];
    this.submitting = false;
    this.aiTookForever = false;
    clearInterval(this.verificationPoll);
    if (this.sectionPrompt.trim() !== "") {
      askChatGPTAccordion.toggle('chatGPTResultsSection')
      this.submitting = true;
      this.errorMsg = false;
      this.mjmlGeneratorService.create(this.params()).subscribe((data) => {
        this.generatedMJMLWorkerId = data.id;
        this.checkGeneratedMJML();
        this.aiTookFoeverPoll = setTimeout(() => {
          if (this.submitting) {
            this.aiTookForever = true;
            this.submitting = false;
            clearInterval(this.verificationPoll);
            clearInterval(this.aiTookFoeverPoll);
            console.log(`generated MJML Worker Id: ${this.generatedMJMLWorkerId }`);
          }
        }, 80000);
      }, (response) => {
        console.log(response);
        this.errorMsg = true;
        this.submitting = false;
      });
    }
  }

  getRequestLimit() {
    this.mjmlGeneratorService.limit().subscribe((data) => {
      this.requestLimit = data.requestLimit;
      this.requestsThisMonth = data.requestsThisMonth;
      // temporarily
      // this.remoteWorking = data.remoteWorking;
      this.remoteWorking = true;
      // end
      this.loadingInit = false;
      const remaining = this.requestLimit - this.requestsThisMonth;
      if (remaining <= 0) {
        this.remainingRequests = 0;
      } else {
        this.remainingRequests = remaining;
      }
    }, (response) => {
      console.log(response);
      this.errorMsg = true;
      this.submitting = false;
      this.loadingInit = false;
    });
  }

  selectedContentCarousel(e) {
    this.selectedOption = e;
  }

  ChooseOrWaitForContent() {
    if (this.submitting) {
      clearInterval(this.verificationPoll);
      this.activeModal.close({ id: this.generatedMJMLWorkerId });
    } else {
      this.activeModal.close({ mjmlContent: this.mjmlContentsResult[this.selectedOption].mjml });
    }
  }

  cancel() {
    clearInterval(this.verificationPoll);
    clearInterval(this.aiTookFoeverPoll);
    this.activeModal.dismiss();
  }

  ngOnDestroy() {
    clearInterval(this.verificationPoll);
    clearInterval(this.aiTookFoeverPoll);
  }

  tryAgain(accordion) {
    this.mjmlContentsResult = [];
    this.htmlContents = [];
    this.submitting = false;
    this.aiTookForever = false;
    setTimeout(() => {
      accordion.toggle('searchContentSection');
    });
  }

  private checkGeneratedMJML() {
    this.mjmlContentsResult = [];
    this.verificationPoll = setInterval(() => {
      this.aIModuleModalService.checkGeneratedMJML(this.generatedMJMLWorkerId).subscribe((mjmlGenerator) => {
        if (mjmlGenerator.status == 'finished') {
          this.submitting = false;
          let tagName = this.contentType;
          tagName = tagName === "mj-ai-module" ? "mj-section" : tagName;
          this.mjmlContentsResult = this.aIModuleModalService.extractMJML(mjmlGenerator.generatedMjml, tagName);
          this.htmlContents = this.mjmlContentsResult.map((h) => h.html);
          clearInterval(this.verificationPoll);
          clearInterval(this.aiTookFoeverPoll);
        }
      });
    }, 7000);
  }

  private findId() {
    const getLastChild = (route) => {
      let child = route;
      if (child && child?.params?.id) {
        this.campaignId = child?.params?.id;
      } else if (child) {
        getLastChild(child.firstChild);
      }
    }

    getLastChild(this.route.snapshot.root);
  }

  private params(): any {
    this.findId();
    return {
      contentType: this.contentType.replace("mj-", ""),
      campaignId: this.campaignId,
      prompt: this.sectionPrompt,
      featuredImageUrl: this.featuredImageUrl,
      backgroundImageUrl: this.backgroundImageUrl,
    };
  }

  async handleImageSelect(file: File, fileType: string): Promise<any> {
    const logoTooLarge = (file.size / 1024 / 1024) > 5;
    const logoFileTypeIncorrect = !this.isImage(file);
    if (logoTooLarge) {
      alert("Image must be less than 1024x1024 pixels");
    } else if (logoFileTypeIncorrect) {
      alert("File extension must be .jpg or .png");
    } else {
      this.loadingFeaturedImage = fileType == 'featuredImageUrl';
      this.loadingBackgroundImage = fileType == 'backgroundImageUrl';
      this.s3.uploadImage(file)
        .subscribe({
          next: (response) => {
            this.handleUploadResponse(response, fileType);
          },
          error: (error) => {
            console.error(error);
            this.loadingFeaturedImage = false;
            this.loadingBackgroundImage = false;
            alert("An error occurred uploading the image.")
          }
        });
    }
  }

  private isImage(file: File) {
    return !!(file.type.includes("jpeg") || file.type.includes("png"));
  }

  private handleUploadResponse(data: any, fileType: string) {
    this.loadingFeaturedImage = false;
    this.loadingBackgroundImage = false;
    if (fileType == 'featuredImageUrl') {
      this.featuredImageUrl = data.url;
    } else {
      this.backgroundImageUrl = data.url;
    }
  }
}
