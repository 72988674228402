import {
  Component, EventEmitter, Input, Output,
} from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Store } from "@app/shared/models/store";
import { StoreService } from "@app/shared/services/store.service";
import { FacebookPage } from "@models/social/facebook_page";
import { SocialBlastAttachment } from "@models/social/social_blast_attachment";

@Component({
  selector: "facebook-post-preview",
  styleUrls: ["./facebook_post_preview.component.scss"],
  templateUrl: "./facebook_post_preview.component.html",
})
export class FacebookPostPreviewComponent {
  @Input()
  facebookPage: FacebookPage;

  @Input()
  content: string;

  @Input()
  attachedFiles: SocialBlastAttachment[];

  @Input()
  croppingWarning: boolean;

  @Output()
  croppingWarningChange = new EventEmitter();

  store: Store;

  constructor(private sanitizer: DomSanitizer, private storeService: StoreService) {}

  ngOnInit() {
    this.store = this.storeService.getSource().getValue();
  }

  displayContent(): string | SafeHtml {
    const landingUrl = this.attachedFiles[0]?.productLandingUrl;
    if (landingUrl) {
      const previewUrl = `${this.store.redirHostname}a/preview`;
      const content = this.content.replace(landingUrl, `<span style="color: #216fdb;">${previewUrl}</span>`);
      return this.sanitizer.bypassSecurityTrustHtml(content);
    }
    return this.content;
  }
}
