import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "pardot-schedule-demo-modal",
  styleUrls: ["./pardot_schedule_demo_modal.component.scss"],
  templateUrl: "./pardot_schedule_demo_modal.component.html",
})
export class PardotScheduleDemoModalComponent {
  @Input()
  department: string;

  constructor(private activeModalRef: NgbActiveModal) {}

  close(): void {
    this.activeModalRef.close();
  }
}
