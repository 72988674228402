import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Category } from "@models/category";
import { Product } from "@models/product";
import { Response } from "@models/response";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { BehaviorSubject, Observable, of } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  mergeMap,
} from "rxjs/operators";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class CategoriesService {
  private categoriesIndexSource$ = new BehaviorSubject<Category[]>(null);

  constructor(
    private httpClient: HttpClient,
    private deserializeService: DeserializeService,
  ) {}

  getIndexSource(): BehaviorSubject<Category[]> {
    return this.categoriesIndexSource$;
  }

  setIndexSource(categories: Category[]): void {
    this.categoriesIndexSource$.next(categories);
  }

  actions(categoryId: string, params?: any) {
    return this.httpClient.get<Response<any>>(
      `${environment.apiUrl}/v2/categories/${categoryId}/top_actions`,
      { params }
    );
  }

  chart(categoryId: string, params: any = {}) {
    return this.httpClient.get<any>(`${environment.apiUrl}/v2/categories/${categoryId}/chart`, {
      params,
    });
  }

  index(params?: any): Observable<Response<Category[]>> {
    return this.httpClient.get<Category[]>(`${environment.apiUrl}/v2/categories`, { params }).pipe(
      mergeMap(async (categoriesResponse: any) => {
        const categories = (this.deserializeService.deserialize(categoriesResponse)) as Category[];
        return new Response(categories, categoriesResponse.meta);
      })
    );
  }

  products(categoryId: string, params?: any) {
    return this.httpClient
      .get<Response<Product[]>>(`${environment.apiUrl}/v2/categories/${categoryId}/top_products`, {
        params,
      })
      .pipe(
        mergeMap(async (productsResponse) => {
          const products = (this.deserializeService.deserialize(productsResponse)) as Product[];
          return new Response(products, productsResponse.meta);
        })
      );
  }

  search(text$: Observable<string>): Observable<Category[]> {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      mergeMap((term) =>
        term.length > 2
          ? this.index({ catName: term })
          : of(new Response([], {}))
      ),
      map((result) => result.data)
    );
  }

  show(categoryId: string, params?: any) {
    return this.httpClient
      .get<Response<Category>>(`${environment.apiUrl}/v2/categories/${categoryId}`, { params })
      .pipe(
        mergeMap(async (categoryResponse: any) => {
          const categories = (this.deserializeService.deserialize(categoryResponse)) as Category;
          return new Response(categories, categoryResponse.meta);
        })
      );
  }
}
