import { Injectable } from "@angular/core";
import { EditorProductSelectModalComponent } from "@app/content_editor/components/email_content_editor/components/editor_product_select_modal/editor_product_select_modal.component";
import { ProductImageSelectModalComponent } from "@app/content_editor/components/email_content_editor/components/product_image_select_modal/product_image_select_modal.component";
import { ProductFiltersModalComponent } from "@app/content_editor/components/email_content_editor/components/product_filters_modal/product_filters_modal.component";
import { RecommenderConfiguration } from "@app/content_editor/models/recommender_configuration";
import { Product } from "@models/product";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Injectable({ providedIn: "root" })
export class ProductModalService {
  constructor(private modalService: NgbModal) {
  }

  openProductSearchModal(selectedProduct: Product = null): Promise<Product> {
    const modalRef = this.modalService.open(EditorProductSelectModalComponent, { size: "lg" });
    modalRef.componentInstance.selectedProduct = selectedProduct;
    modalRef.componentInstance.maxSelect = 1;
    return modalRef.result as Promise<Product>;
  }

  openMultiProductSearchModal(selectedProducts: Product[] = []): Promise<Product[]> {
    const modalRef = this.modalService.open(EditorProductSelectModalComponent, { size: "lg" });
    modalRef.componentInstance.selectedProducts = selectedProducts;
    modalRef.componentInstance.maxSelect = 9; // somewhat arbitrary, not set in stone
    return modalRef.result as Promise<Product[]>;
  }

  // this allows for the selection of a product image OR a custom image for a product
  // the other two simple product selection modals
  openProductImageSelectModal(product: Product, imageUrl: string): Promise<string> {
    const modalRef = this.modalService.open(ProductImageSelectModalComponent);
    modalRef.componentInstance.product = product;
    modalRef.componentInstance.currentUrl = imageUrl;
    return modalRef.result;
  }

  // this allows for the selection of categories of products to recommend
  openProductFiltersModal(
    recommenderConfiguration: RecommenderConfiguration,
  ): Promise<RecommenderConfiguration> {
    const modalRef = this.modalService.open(ProductFiltersModalComponent);
    modalRef.componentInstance.sourceRecommenderConfiguration = recommenderConfiguration;
    return modalRef.result;
  }
}
