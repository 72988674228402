export class ChartFilter {
  trigger: string;
  action: string;
  endDate?: string;
  startDate: string;
  metric: "revenue" | "orders";
  constructor(params?: Partial<ChartFilter>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}
