<sb-header></sb-header>
<div class="light-gray-background">
  <div class="not-found-logo-wrapper text-center white-background">
    <a routerLink="/">
      <img src="https://springbot-assets.s3.amazonaws.com/images/springbot-icon.png"
           class="sb-nav__sb-icon">
    </a>
  </div>

  <div class="sb-router-outlet pt-5">
    <div class="error-box white-background">
      <h1>404</h1>

      <p class="error-description">
        Oops! Looks like the page you are looking for was moved or never existed.
        Make sure you typed the URL correctly or followed a valid link.
      </p>

      <div class="controls">
        <a href="/" class="btn btn-primary">
          Go Back
        </a>

        <span class="contact-link">
         or contact
         <a href="mailto:help@springbot.com" target="_blank">help@springbot.com</a>
       </span>
      </div>
    </div>
  </div>
</div>
<sb-footer></sb-footer>
