import {
  Component, EventEmitter, Input, Output, SimpleChanges,
} from "@angular/core";
import { AuthorizationService } from "@app/shared/services/authorization.service";
import { FacebookService } from "@app/social/services/facebook.service";
import { InstagramService } from "@app/social/services/instagram.service";
import { SocialAccountsService } from "@app/social/services/social_accounts_service";
import { UserAuthsService } from "@app/social/services/user_auths.service";
import { SocialAccount } from "@models/social/social_account";
import { of, zip } from "rxjs";
import { catchError, take } from "rxjs/operators";

@Component({
  selector: "social-account-selector",
  styleUrls: ["./social_account_selector.component.scss"],
  templateUrl: "./social_account_selector.component.html",
})
export class SocialAccountSelectorComponent {
  loading = true;
  allSelected = false;
  selectedCount = 0;
  @Input()
  checkIgPostPermission: boolean;

  @Input()
  maxSelect: number;

  @Input()
  socialAccounts: SocialAccount[] = [];

  @Output() primaryFacebookAccount: EventEmitter<SocialAccount> = new EventEmitter<SocialAccount>();
  @Output() primaryTwitterAccount: EventEmitter<SocialAccount> = new EventEmitter<SocialAccount>();
  @Output() primaryInstagramAccount: EventEmitter<SocialAccount> = (
    new EventEmitter<SocialAccount>()
  );

  @Output() socialAccountsChange: EventEmitter<SocialAccount[]> = (
    new EventEmitter<SocialAccount[]>()
  );

  constructor(
    private userAuthsService: UserAuthsService,
    private authorizationService: AuthorizationService,
    private instagramService: InstagramService,
    private facebookService: FacebookService,
    private socialAccountsService: SocialAccountsService,
  ) {
  }

  ngOnInit(): void {
    this.checkIgPostPermission
      ? this.getSocialAccountDataWithPermissions()
      : this.getSocialAccountData();
  }

  // responds to parent changes of selected socialAccounts
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.socialAccounts) {
      this.setSelectedCount();
    }
  }

  onToggleSelectAll(): void {
    this.socialAccounts.forEach((socialAccount) => socialAccount.selected = this.allSelected);
    this.setSelectedCount();
  }

  updateSelectedAttrs(): void {
    this.allSelected = this.socialAccounts.every(({ selected }) => selected);
    this.setSelectedCount();
  }

  // change emitting only occurs on close and initial load for performance reasons
  onClose(): void {
    this.socialAccountsChange.emit(this.socialAccounts);
  }

  private getSocialAccountData(): void {
    this.socialAccountsService.getSocialAccounts().subscribe((socialAccounts: SocialAccount[]) => {
      this.processSocialAccounts(socialAccounts);
      this.emitSocialAccounts();
      this.loading = false;
    });
  }

  private getSocialAccountDataWithPermissions(): void {
    const $getAccounts = this.socialAccountsService.getSocialAccounts();
    const $getPermissions = (
      this.facebookService.getPermissions().pipe(catchError((_err) => of([])))
    );
    zip($getAccounts, $getPermissions).pipe(take(1)).subscribe(([socialAccounts, permissions]) => {
      const blockInstagram = !permissions.includes("instagram_content_publish");
      this.processSocialAccounts(socialAccounts, blockInstagram);
      this.emitSocialAccounts();
      this.loading = false;
    });
  }

  // social data is cloned so that behavioral subject is not mutated
  private cloneAccounts(socialAccounts: SocialAccount[]): SocialAccount[] {
    return socialAccounts.reduce(
      (result: SocialAccount[], account: SocialAccount) => result.concat({ ...account }),
      [],
    );
  }

  private processSocialAccounts(socialAccounts: SocialAccount[], blockInstagram = false): void {
    this.socialAccounts = this.cloneAccounts(socialAccounts);
    if (blockInstagram) {
      this.socialAccounts = this.socialAccounts.filter(({ platform }) => platform !== "instagram");
    }
    this.setPrimaryAccountsAsSelected();
    this.setSelectedCount();
  }

  private emitSocialAccounts(): void {
    this.socialAccountsChange.emit(this.socialAccounts);
    this.primaryFacebookAccount.emit(this.getPrimaryAccount("facebook"));
    this.primaryTwitterAccount.emit(this.getPrimaryAccount("twitter"));
    this.primaryInstagramAccount.emit(this.getPrimaryAccount("instagram"));
  }

  private setPrimaryAccountsAsSelected(): void {
    this.socialAccounts.forEach((socialAccount) => socialAccount.selected = socialAccount.primary);
  }

  private setSelectedCount(): void {
    this.selectedCount = this.socialAccounts.filter(({ selected }) => selected).length;
  }

  private getPrimaryAccount(platformName: "facebook" | "twitter" | "instagram"): SocialAccount {
    return this.socialAccounts.find(
      ({ platform, primary }) => primary && platform === platformName,
    );
  }
}
