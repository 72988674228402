/**
 * How to use ModalDialogueContentComponent
 * Add the following to your component:
 * import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
 * import {
 *   ModalDialogueContent,
 * } from "@app/shared/components/sb_modal_dialogue/sb_modal_dialogue";
 * import { Modal } from "@models/modal_info";
 *
 * myModal = new Modal ();
 *
 * ngOnInit() {
 *   this.myModal = new Modal();
 * }
 *
 * add { private modalService: NgbModal } to constructor
 *
 * Example open method for modal and changing the default values of the modal instance.
 * Accepts up to 3 buttons
 *
 * open() {
 *   const modalRef = this.modalService.open(ModalDialogueContent, {size: "sm"});
 *   this.myModal.buttons = [
 *     {
 *       "name": "Jerry",
 *     },
 *     {
 *       "name": "Javier",
 *     },
 *   ];
 *   // if a checkbox is needed, this.myModal.checkboxText can be used.
 *   // if a list is needed, this.myModal.listText = [ { text: "" }, { text: "" }]
 *   this.myModal.icon = "error.png";
 *   modalRef.componentInstance.attributes = this.myModal;
 *   modalRef.result.then((result) => {
 *     this.closeResult = `Closed with: ${result}`;
 *     console.log(result);
 *   }, (reason) => {
 *     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
 *   });
 * }
 *
 * private getDismissReason(reason: any): string {
 *   if (reason === ModalDismissReasons.ESC) {
 *     return "by pressing ESC";
 *   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
 *     return "by clicking on a backdrop";
 *   } else {
 *     return  `with: ${reason}`;
 *   }
 * }
 *
 * For a button or whatever you want to open the modal. Place on html.
 * <button (click)="open()">Button</button>
 */

import { Component, Input } from "@angular/core";
import { Modal } from "@models/modal_info";
import { SbModalDialogResult } from "@models/sb_modal_dialog_result";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "modal-dialogue-content",
  styleUrls: ["./sb_modal_dialogue.scss"],
  templateUrl: "./sb_modal_dialogue.html",
})
export class ModalDialogueContent {
  checkboxNeeded: boolean;
  checkboxChecked: boolean;
  dropdownNeeded: boolean;
  dropdownSelection: any;
  dropdownExpanded = false;
  useFontawesomeIcon = false;
  result: SbModalDialogResult;
  @Input()
  attributes: Modal;

  icon: string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.icon = this.setIcon();
    this.checkboxNeeded = this.attributes?.checkboxText.length > 0;
    this.checkboxChecked = false;
    this.dropdownNeeded = this.attributes?.dropdownItems.length > 1;
    setTimeout(() => {
      document.querySelectorAll("button").forEach(e => e?.blur());
    })
  }

  setIcon() {
    this.useFontawesomeIcon = this.attributes?.useFontawesomeIcon || false;
    let defaultIcon = "";
    let icon = "";
    if (this.useFontawesomeIcon) {
      defaultIcon = "fas fa-exclamation fa-3x";
      icon = defaultIcon;
      if (this.attributes.icon) {
        icon = this.attributes.icon;
      }
    } else {
      defaultIcon =
        "https://s3.amazonaws.com/springbot-assets/images/exclamation.svg";
      icon = defaultIcon;
      if (this.attributes?.icon) {
        icon = icon.replace("exclamation.svg", this.attributes.icon);
      }
    }

    return icon;
  }

  toggleDropdown() {
    this.dropdownExpanded = !this.dropdownExpanded;
  }

  closeModal(action): void {
    const checkboxValue = this.checkboxChecked ? true : null;
    const dropdownValue = this.dropdownNeeded ? this.dropdownSelection : null;

    this.result = {
      action,
      data: {
        checkboxValue,
        dropdownValue,
      },
    };
    this.activeModal.close(this.result);
  }
}
