import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Response } from "@models/response";
import { FacebookGeoLocation } from "@models/social/facebook_geo_location";
import { FacebookPost } from "@models/social/facebook_post";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class FacebookService {
  constructor(
    private http: HttpClient,
    private deserializeService: DeserializeService,
  ) {}

  getPosts(params: any): Observable<Response<FacebookPost[]>> {
    return this.http.get(`${environment.apiUrl}/v2/social_hub/facebook_posts`, { params })
      .pipe(mergeMap(async (postResponse: any) => {
        const posts = this.deserializeService.deserialize(postResponse) as FacebookPost[];

        return new Response<FacebookPost[]>(posts, postResponse.meta);
      }));
  }

  searchLocations(type: "city" | "region" | "country" | "zip", query: string): Observable<FacebookGeoLocation[]> {
    return this.http.get<FacebookGeoLocation[]>(`${environment.apiUrl}/v2/social_hub/facebook_locations`,
      { params: { type, query } }).pipe(
      mergeMap(async (response) => this.deserializeService.deserialize(response)),
    );
  }

  likePost(postId: string, pageId: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/v2/social_hub/facebook_likes`, { postId, pageId });
  }

  unlikePost(postId: string, pageId: string): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}/v2/social_hub/facebook_likes/post/${postId}/page/${pageId}`);
  }

  // TODO: fix all anys in this file
  // targetId: id of post or comment to comment on/reply to
  // pageId: facebook page id that is commenting
  createComment(targetId: string, pageId: string, message: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/v2/social_hub/facebook_comments`, {
      postId: targetId,
      pageId,
      message,
    });
  }

  // returns an array of strings containing the granted facebook permissions
  // for the stores most recent authorization
  getPermissions(): Observable<string[]> {
    return this.http.get<{ data: string[] }>(`${environment.apiUrl}/v2/social_hub/facebook_permissions`)
      .pipe(map(({ data }) => data));
  }

  deletePost(postId: string) {
    return this.http.delete(`${environment.apiUrl}/v2/social_hub/facebook_posts/${postId}`);
  }
}
