import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { AuthTokenService } from "@app/auth/services/auth-token.service";
import { Observable, Subscriber } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { TokenCheckResponse } from "@app/auth/services/token_check_response";
import { RegistrationParams } from "@app/shared/registration_params";
import { UniversalErrorService } from "@app/shared/services/universal_error_service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public REFRESH_THRESHOLD: number = 15; // If the token will expire in less than 15 minutes, refresh it.

  constructor(
    private authTokenService: AuthTokenService,
    private httpClient: HttpClient,
    private universalErrorService: UniversalErrorService,
  ) {}

  login(email: string, password: string): Observable<Response> {
    const uri = `${environment.apiUrl}/v2/authenticate`;
    const body = { email: email, password: password };
    this.authTokenService.clear();

    return new Observable((subscriber) => {
      return this.httpClient.post(uri, body).subscribe((response: any) => {
        const authToken = response["authToken"];
        const refreshToken = response["refreshToken"];
        this.authTokenService.setTokens(authToken, refreshToken);
        response.ok = true;
        subscriber.next(response);
      }, (response) => {
        response.ok = false;
        subscriber.next(response);
      });
    });
  }

  register(params: any): Observable<any> {
    const uri = `${environment.apiUrl}/v2/register`;

    return new Observable((subscriber) => {
      return this.httpClient.post(uri, params).subscribe((response: any) => {
        this.authTokenService.clearImpersonatorId();
        const authToken = response["authToken"];
        const refreshToken = response["refreshToken"];
        this.authTokenService.setTokens(authToken, refreshToken);
        response.ok = true;
        subscriber.next(response);
      }, (response) => {
        response.ok = false;
        subscriber.next(response);
      });
    });
  }

  sendPasswordResetToken(params: any): Observable<any> {
    const uri = `${environment.apiUrl}/v2/send_password_reset_token`;

    return new Observable((subscriber) => {
      return this.httpClient.post(uri, params).subscribe((response: any) => {
        response.ok = true;
        subscriber.next(response);
      }, (response) => {
        response.ok = false;
        subscriber.next(response);
      });
    });
  }

  resetPassword(registrationToken: string, password: string, passwordConfirmation: string): Observable<any> {
    const uri = `${environment.apiUrl}/v2/reset_password`;
    const body = { registrationToken, password, passwordConfirmation };

    return new Observable((subscriber) => {
      return this.httpClient.post(uri, body).subscribe((response: any) => {
        const authToken = response["authToken"];
        const refreshToken = response["refreshToken"];
        this.authTokenService.setTokens(authToken, refreshToken);
        response.ok = true;
        subscriber.next(response);
      }, (response) => {
        response.ok = false;
        subscriber.next(response);
      });
    });
  }

  resendVerificationEmail(): Observable<any> {
    const uri = `${environment.apiUrl}/v2/resend_verification_email`;

    return new Observable((subscriber) => {
      return this.httpClient.get(uri).subscribe((response: any) => {
        response.ok = true;
        subscriber.next(response);
      }, (response) => {
        response.ok = false;
        subscriber.next(response);
      });
    });
  }

  verifyRegistration(registrationToken: string): Observable<any> {
    const uri = `${environment.apiUrl}/v2/verify_registration`;
    const body = { registrationToken };

    return new Observable((subscriber) => {
      return this.httpClient.post(uri, body).subscribe((response: any) => {
        response.ok = true;
        subscriber.next(response);
      }, (response) => {
        response.ok = false;
        subscriber.next(response);
      });
    });
  }

  getBranding(): Observable<RegistrationParams> {
    const uri = `${environment.apiUrl}/v2/brand_scraping`;

    return new Observable((subscriber) => {
      return this.httpClient.post(uri, {}).subscribe((response: any) => {
        subscriber.next(response.data.attributes);
      }, (response) => {
        subscriber.error(response);
      });
    });
  }

  impersonate(userId: string, refresh = true): void {
    this.authTokenService.setImpersonationId(userId);
    if (refresh)  {
      window.location.href = "/dashboard"; // We should figure out how to reload store/user data without refreshing the page.
    }
  }

  stopImpersonating(): void {
    this.authTokenService.clearImpersonatorId();
    window.location.href = "/admin/stores"; // We should figure out how to reload store/user data without refreshing the page.
  }

  isImpersonating(): boolean {
    return !!this.authTokenService.getImpersonatorId();
  }

  logout(): Observable<Response> {
    const uri = `${environment.apiUrl}/v2/invalidate_token`;
    this.authTokenService.clear();

    return new Observable((subscriber) => {
      this.httpClient.get(uri).subscribe((response: any) => {
        response.ok = true;
        subscriber.next(response);
      }, (response) => {
        response.ok = false;
        subscriber.next(response);
      });
    });
  }

  // Subscriber returns if the token is valid. False tells the subscriber the user needs to be redirected to login.
  // Clearing the tokens will prevent redirect loops.
  tokenCheck(): Observable<TokenCheckResponse> {
    return new Observable((subscriber) => {
      if (!this.authTokenService.getToken()) {
        this.authTokenService.clear();
        return subscriber.next(new TokenCheckResponse({ok: false, error: "not logged in"}));
      }

      this.heartbeat().subscribe((heartbeatResponse) => {
        const tokenExpired = this.authTokenService.isTokenExpired();
        const tokenExpiringSoon = this.authTokenService.remainingTokenTimeInMinutes() < this.REFRESH_THRESHOLD;

        if (!heartbeatResponse.ok || tokenExpired || tokenExpiringSoon) {
          // The token will expire soon, or has already expired, so try to refresh it.
          console.log("refreshing token");
          return this.runTokenRefresh(subscriber);
        } else {
          // everything is fine. Move along.
          return subscriber.next(new TokenCheckResponse({ ok: true }));
        }
      }, (error) => {
        console.log("Error: ", error);
        return this.runTokenRefresh(subscriber);
      });
    });
  }

  heartbeat(): Observable<Response> {
    const uri = `${environment.apiUrl}/v2/heartbeat`;

    return new Observable((subscriber) => {
      return this.httpClient.get(uri).subscribe((response: any) => {
        response.ok = true;
        subscriber.next(response);
      }, (response) => {
        console.error("heartbeat response error: ", response)
        response.ok = false;
        subscriber.next(response);
      });
    });
  }

  private runTokenRefresh(subscriber: Subscriber<TokenCheckResponse>) {
    this.refreshToken().subscribe((response) => {
      if (response.ok) {
        // The token was refreshed, and the new token has been stored.
        console.log("token has been refreshed");
        this.authTokenService.setTokens(response['authToken'], response['refreshToken']);
        return subscriber.next(new TokenCheckResponse({ok: true}));
      } else {
        // Token refresh failed, need to log back in.
        console.log("token refresh failed:", response);
        this.authTokenService.clear();
        const responseErrors = this.universalErrorService.extractErrorMessages(response);
        let errorMessage = "Something went wrong.";
        if (responseErrors.length > 0) {
          errorMessage = responseErrors[0];
        }
        return subscriber.next(new TokenCheckResponse({ok: false, error: errorMessage}));
      }
    });
  }

  refreshToken(): Observable<Response> {
    const uri = `${environment.apiUrl}/v2/refresh_token`;
    const body = {
      refreshToken: this.authTokenService.getRefreshToken()
    }

    return new Observable((subscriber) => {
      return this.httpClient.post(uri, body).subscribe((response: any) => {
        response.ok = true;
        subscriber.next(response);
      }, (response) => {
        response.ok = false;
        subscriber.next(response);
      });
    });
  }
}
