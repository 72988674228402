<router-outlet></router-outlet>

<ng-template #forceLogoutModal let-modal>
  <div class="modal-body text-center">
    <h3>Your session has expired</h3>
    <p>For your security we've automatically signed you out.<p>
  </div>
  <div class="modal-footer bg-secondary d-flex justify-content-around align-items-center">
    <button (click)="relog();modal.dismiss()" class="btn btn-secondary">Log in</button>
  </div>
</ng-template>
