<ng-container>
  <div class="row mt-5">
    <div class="col-12">
      <p class="text-white"><fa-icon [icon]="['fab', 'instagram']"></fa-icon> Instagram</p>
    </div>
  </div>

  <div class="row no-gutters">
    <div class="col-12 bg-light border rounded">
      <div class="d-flex align-items-center justify-content-between m-2">
        <div class="d-flex align-items-center">
          <img class="img-fluid border rounded-circle size-40px mr-2" [src]="instagramUser.image"
               alt="fb page image"/>
          <p class="ml-0 mb-0 font-weight-bolder">{{instagramUser.name}}</p>
        </div>
        <fa-icon [icon]="['fas', 'ellipsis-v']" class="medium-gray-color"></fa-icon>
      </div>
      <div class="height-350" *ngIf="attachedFile?.imageContentType else imagePlaceholderText">
        <img class="w-100" alt="" [src]="attachedFile.imageUrl" *ngIf="attachedFile.imageContentType.includes('image')">
        <video controls class="embed-responsive" *ngIf="attachedFile.imageContentType.includes('video')">
          <source class="embed-item"
                  [src]="attachedFile.imageUrl"
                  [type]="attachedFile.imageContentType">
        </video>
      </div>
      <ng-template #imagePlaceholderText>
        <p class="font-italic text-center">Image(s) or video displays here</p>
      </ng-template>
      <div class="mx-3 m-2">
        <div class="medium-gray-color d-flex border-top mt-2 pt-2 justify-content-between">
          <div class="d-flex">
            <fa-icon [icon]="['fas', 'heart']" class="mr-4"></fa-icon>
            <fa-icon [icon]="['fas', 'comment']" class="mr-4"></fa-icon>
            <fa-icon [icon]="['fas', 'paper-plane']"></fa-icon>
          </div>
          <fa-icon [icon]="['fas', 'bookmark']"></fa-icon>
        </div>
        <p class="my-2"><span
            class="font-weight-bolder">{{instagramUser.name}}</span> {{ displayContent() }}
        </p>
        <p class="small medium-gray-color mb-1">Just Now</p>
      </div>
    </div>
  </div>
  <div class="row no-gutters" *ngIf="productLinkWarning">
    <div class="col-12 bg-light-blue d-flex justify-content-between rounded align-items-center p-3 mt-2">
                <span><fa-icon [icon]="['fas', 'exclamation-circle']"></fa-icon> Product links will not post to Instagram
                </span>
      <fa-icon [icon]="['fas', 'times']" class="cursor-pointer" (click)="productLinkWarning = false"></fa-icon>
    </div>
  </div>
  <div class="row no-gutters" *ngIf="isPostingSingleImage && singleImageWarning">
    <div class="col-12 bg-light-blue d-flex justify-content-between rounded align-items-center p-3 mt-2">
                <span><fa-icon [icon]="['fas', 'exclamation-circle']"></fa-icon> Only first image will post to Instagram
                </span>
      <fa-icon [icon]="['fas', 'times']" class="cursor-pointer" (click)="singleImageWarning = false"></fa-icon>
    </div>
  </div>
  <div class="row no-gutters" *ngIf="attachedFile && croppingWarning">
    <div class="col-12 bg-light-blue d-flex justify-content-between rounded align-items-center p-3 mt-2">
                <span><fa-icon [icon]="['fas', 'exclamation-circle']"></fa-icon> Cropping of images maybe slightly different
                </span>
      <fa-icon [icon]="['fas', 'times']" class="cursor-pointer" (click)="croppingWarningChange.emit(false);"></fa-icon>
    </div>
  </div>
</ng-container>
