import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EmailProvider } from "@models/apis/emailProvider";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApisService {
  constructor(
    private httpClient: HttpClient,
    private deserializeService: DeserializeService,
  ) {}

  index(): Observable<EmailProvider[]> {
    return this.httpClient.get<EmailProvider>(`${environment.apiUrl}/v2/providers`).pipe(mergeMap(async (response) => this.deserializeService.deserialize(response)));
  }
}
