export class Modal {
  icon: string;
  title =
    "Oh no! Something went wrong, but the springbots have been notified and are looking into it...";
  label: string = null;
  subtext = "";
  listText: any = [
    {
      text: "",
    },
  ];

  buttons: any = [
    {
      name: "Cancel",
    },
  ];

  checkboxText = "";
  dropdownItems: any = [{}];
  useFontawesomeIcon = false;
  phoneNumber = "";
  phoneVerifyStatus = "";
  phoneVerifyAbility = true;
  confirmModal?: string;

  constructor(params?: Partial<Modal>) {
    if (params) {
      Object.assign(this, params);
    }
  }
}
