import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { StoreService } from "@app/shared/services/store.service";
import { Store } from "@models/store";
import { AppDashboard } from "@models/dashboard/app_dashboard";
import { Recommendation } from "@models/dashboard/recommendation";
import { NgbCalendar, NgbDate } from "@ng-bootstrap/ng-bootstrap";

import { AppDashboardService } from "@app/dashboard/services/app_dashboard.service";
import { RecommendationsService } from "@app/dashboard/services/recommendations.service";
import { environment } from "@environments/environment";

import { UserService } from "@app/shared/services/user.service";
import { SocialAccountsService } from "@app/social/services/social_accounts_service";
import { User } from "@models/user";
import { BroadcastService } from "@app/shared/services/broadcast.service";
import { BroadcastMessage } from "@models/broadcast_message";
import {StorageService} from "@app/shared/services/storage.service";

@Component({
  selector: "app-dashboard",
  styleUrls: ["./dashboard.component.scss"],
  templateUrl: "./dashboard.component.html",
})
export class DashboardComponent {
  store: Store;
  user: User;
  dash: AppDashboard;
  loading = true;
  recommendations: Recommendation[];
  startDate: Date;
  endDate: Date;
  platformConnected: boolean;
  showBetaToggle: boolean;
  oldFrontendUrl = environment.apiUrl;
  showEmailVerificationAlert: boolean = false;
  hideEmailVerificationAlert: boolean = false;
  showStoreSyncingAlert: boolean = false;
  verificationPoll: any;
  userEmailAddress: string = "";
  datesFilter: any;

  flash: string[][];
  greeting: string;
  numAlerts: number;
  messages: {
    type: string;
    message: string;
  }[];

  stepperTitle: string = "Account setup";
  showPercentage: boolean = true;
  steps = [];
  showStepper: boolean = false;
  usersCount: number = 0;
  socialMediaConnected = false;
  checklistOptOut: boolean = false;

  constructor(
    private storeService: StoreService,
    private dashService: AppDashboardService,
    private recoService: RecommendationsService,
    private calendar: NgbCalendar,
    private titleService: Title,
    private userService: UserService,
    private socialAccountsService: SocialAccountsService,
    private router: Router,
    private broadcastService: BroadcastService,
    private storageService: StorageService,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle("Springbot Dashboard");
    this.endDate = this.convertNgbDateToDate(this.calendar.getToday());
    this.startDate = this.convertNgbDateToDate(this.calendar.getPrev(this.calendar.getToday(), "d", 30));
    this.getStore();
    this.getUser();
    this.getDash();
    this.getGreeting();
    // this.storageService.setItem("hideOnboardingChecklist", "false");
    this.broadcastService.listen("emailVerification").subscribe((message: BroadcastMessage) => {
      this.showEmailVerificationAlert = message.payload.verified;
    });
    this.broadcastService.listen("storeUpdated").subscribe((message: BroadcastMessage) => {
      this.store = message.payload.store;
    });
    this.broadcastService.listen("userUpdated").subscribe((message: BroadcastMessage) => {
      this.user = message.payload.user;
    });
    this.broadcastService.listen("hideOnboardingChecklist").subscribe(() => {
      this.checklistOptOut = true;
    });
  }

  ngOnDestroy() {
    if (this.verificationPoll) {
      clearInterval(this.verificationPoll);
    }
  }

  closeAlert(): void {
    this.numAlerts -= 1;
  }

  getGreeting(): void {
    const hour = new Date().getHours();
    if (hour >= 5 && hour < 12) {
      this.greeting = "Good morning";
    } else if (hour >= 12 && hour < 17) {
      this.greeting = "Good afternoon";
    } else if (hour >= 17 && hour < 24) {
      this.greeting = "Good evening";
    } else {
      this.greeting = "Hello";
    }
  }

  launchCampaignBowlingAlley(): void {
    const url = this.store.branding.state === "done" ? "/email_campaigns/new/template" : "/brand_scraping"
    this.router.navigateByUrl(url);
  }

  getStore(): void {
    this.storeService.getStore().subscribe((response) => {
      this.store = response;
      this.platformConnected = response.platformConnected;
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
      this.showStoreSyncingAlert = this.store.harvesting && this.store.fullSyncStartedAt > oneWeekAgo;
      this.loading = false;
      this.getUsers();
      this.getChecklist();
      if (this.store?.frontendBetaEnabled && !this.store?.frontendBetaForced) {
        this.showBetaToggle = true;
      }
    });
  }

  getDash(): void {
    const params = {
      startDate: this.startDate,
      endDate: this.endDate,
    };
    this.dashService.show(params).subscribe((response) => {
      this.dash = response;
      this.flash = this.dash.flash;
      this.messages = this.flash.map((flashPair) => ({
        type: flashPair[0] === 'notice' ? 'success' : 'danger',
        message: flashPair[1],
      }));
      this.numAlerts = this.flash.length;
      this.recommendations = this.dash.recommendations;
      this.loading = !(this.store && this.dash);
    });
  }

  getMoreRecos(): void {
    const params = {
      reset: true,
    };
    this.recoService.index(params).subscribe((response) => {
      this.recommendations = response;
    });
  }

  getUser(): void {
    this.userService.show("current", {}).subscribe((user) => {
      this.userService.setUser(user);
      this.user = user;
      this.userEmailAddress = this.user.email;
      this.showEmailVerificationAlert = this.user && !this.user.confirmed;
      if (this.showEmailVerificationAlert && !this.verificationPoll) {
        this.verificationPoll = setInterval(() => {
          this.getUser();
        }, 30000);
      } else if (this.verificationPoll && !this.showEmailVerificationAlert) {
        clearInterval(this.verificationPoll);
      }
    });
  }

  convertNgbDateToDate(date: NgbDate): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  private getUsers(): void {
    this.userService.index({ non_owners: "true"}).subscribe(
      (response) => {
        this.usersCount = response.data.length;
        this.getSocialMediaStatus();
      }
    );
  }

  getSocialMediaStatus(): void {
    this.socialAccountsService.getSocialAccounts().subscribe((socialAccounts) => {
      const socialPlatformsAvailable = socialAccounts.filter(
          ({ primary }) => primary,
      ).map(({ platform }) => platform);
      const facebookAuthed = socialPlatformsAvailable.includes("facebook");
      const twitterAuthed = socialPlatformsAvailable.includes("twitter");
      const instagramAuthed = socialPlatformsAvailable.includes("instagram");
      this.socialMediaConnected = facebookAuthed || twitterAuthed || instagramAuthed;
      this.setSteps();
    });
  }

  onClickStepper(step) {
    this.router.navigateByUrl(step.link);
  }

  getChecklist(): void {
    this.checklistOptOut = this.storageService.getItem(`hideOnboardingChecklist${this.store.id}`) == "true"
  }

  unhideStepper(): void {
    this.checklistOptOut = false;
    this.storageService.setItem(`hideOnboardingChecklist${this.store.id}`, "false");
    this.broadcastService.publish({ type: "hideOnboardingChecklist", payload: { hideOnboardingChecklist: false }});
  }

  hideStepper(): void {
    this.checklistOptOut = true;
    this.storageService.setItem(`hideOnboardingChecklist${this.store.id}`, "true");
    this.broadcastService.publish({ type: "hideOnboardingChecklist", payload: { hideOnboardingChecklist: true }});
  }

  datesChanged(event) {
    if (event && event?.startDate) {
      this.datesFilter = {
        endDate: event.endDate,
        startDate: event.startDate
      };
    }
  }

  private setSteps() {
    this.steps = [
      {
        text: "Connect your cart",
        icon: ['fas', 'link'],
        completed: this.store.platformConnected,
        link: "/store/integrations",
        weight: 30,
        redirection: false
      },
      {
        text: "Upload your contacts",
        icon: ['fas', 'upload'],
        completed: this.store.allSubscribersCount > 0,
        link: "/segments/email_lists/index",
        weight: 20,
        redirection: false
      },
      {
        text: "Set up store branding",
        icon: ['fas', 'cog'],
        completed: this.store.branding.state === "done",
        link: "/store/branding",
        redirection: false,
        weight: 25,
      },
      {
        text: "Add your team members",
        icon: ['fas', 'users'],
        completed: this.usersCount > 0,
        link: "/store/settings/users",
        redirection: false,
        weight: 10,
      },
      {
        text: "Connect social accounts",
        icon: ['fas', 'share'],
        completed: this.socialMediaConnected,
        link: "/store/integrations",
        redirection: false,
        weight: 15,
      },
    ];
    this.showStepper = this.steps.filter(e => e.completed === false).length > 0;
  }
}
