import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { AuthorizationService } from "@app/shared/services/authorization.service";
import { StoreService } from "@app/shared/services/store.service";

@Component({
  selector: "instagram-config-modal",
  styleUrls: ["./instagram_config_modal.component.scss"],
  templateUrl: "./instagram_config_modal.component.html",
})
export class InstagramConfigModalComponent {
  fbConnected: boolean;
  errored = false;
  loadingData = false;
  loadingIgPage = false;
  connected = false;
  pages: any[];
  profile: any;
  selectedPage: any;

  constructor(private activeModal: NgbActiveModal,
              private authorizationService: AuthorizationService,
              private storeService: StoreService) {
  }

  ngOnInit() {
    this.getInstagramAuth();
  }

  finish() {
    this.activeModal.close({ connected: this.connected, errored: false });
  }

  cancel() {
    this.activeModal.dismiss();
  }

  updateInstagramPage(pageId) {
    const params = {
      instagram_page: pageId,
    };

    this.loadingIgPage = true;
    this.selectedPage = this.pages.find((page) => page.id === pageId);

    this.storeService.updateStore(params).subscribe((_response) => {
      this.loadingIgPage = false;
    }, (_errors) => {
      this.loadingIgPage = false;
      this.errored = true;
    });
  }

  resetInstagramPage() {
    const params = {
      instagram_page: null,
    };

    this.loadingIgPage = true;
    this.selectedPage = null;

    this.storeService.updateStore(params).subscribe((_response) => {
      this.loadingIgPage = false;
    }, (_errors) => {
      this.loadingIgPage = false;
      this.errored = true;
    });
  }

  clearAuth() {
    this.authorizationService.destroy("instagram").subscribe((response) => {
      this.activeModal.close({ connected: false, errored: false });
    }, (_errors) => {
      this.errored = true;
    });
  }

  private getInstagramAuth(): void {
    this.loadingData = true;
    this.authorizationService.getAuthorization("instagram").subscribe((auth) => {
      this.fbConnected = auth.authable;
      this.connected = auth.enabled;
      this.pages = auth.pages;
      this.profile = auth.profile;

      if (this.pages) {
        this.loadingData = false;
        this.selectedPage = this.pages.find((page) => page.id === auth.page.id);
        this.errored = false;
        this.loadingData = false;
      } else {
        this.loadingData = false;
      }
    }, (_errors) => {
      this.errored = true;
      this.loadingData = false;
    });
  }
}
