<div class="card modal-container">
  <div class="card-header">
    <div class="text-center">
      <div class="mt-2 mb-2">
        <fa-icon [icon]="['fas', 'user-robot']" size="3x" class="blue-medium-color"></fa-icon>
      </div>
      <div class="h6 mb-2">
        AI Designer
        <br><span class="badge badge-primary">Beta</span>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="alert alert-danger" *ngIf="!remoteWorking">
      <strong>Oops!</strong> It looks like our AI Designer is not working right now. Please try again later.
    </div>
    <div class="alert alert-danger" *ngIf="remainingRequests && remainingRequests < 1">
      <strong>Oops!</strong> Looks like you've run out of AI generations for this month.
      <br><a (click)="activeModal.close(false);" routerLink="/billing">Upgrade</a> for more.
    </div>

    <div class="row mb-2" *ngIf="contentFormat === 'text'">
      <div class="col-md-12">
        <h5 class="mb-0">Product</h5>
        <div>Choose a product to promote</div>
        <div class="row" *ngIf="selectedProduct">
          <div class="col-4">
            <img [src]="selectedProduct?.imageUrl" class="w-100">
          </div>
          <div class="col-8">
            <h5>{{ selectedProduct?.name }}</h5>
            <h6>{{ selectedProduct?.sku }}</h6>
            <div>{{ selectedProduct?.shortDescription }}</div>
          </div>
        </div>

        <button (click)="openProductSelectModal()"
                class="mt-2 btn btn-outline-secondary w-100 rounded">
          <fa-icon [icon]="['fas', 'link']" class="pr-2"></fa-icon>
          <span *ngIf="selectedProduct">Select a different Product</span>
          <span *ngIf="!selectedProduct">Select a Product</span>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h5 class="mb-0">Prompt</h5>
        <div class="slightly-smaller-text text-gray-color my-1 p-1">
          Let the AI Deisgner know what you're going for.

          <i class="fa fa-question-circle blue-color"
             *ngIf="contentFormat === 'mjml'"
             #designvisionPopover="ngbPopover"
             [ngbPopover]="designVisionPopoverContent"
             [autoClose]="'outside'" size="2x"
             [placement]="'right'"
             triggers="mouseenter:mouseleave"
             popoverTitle="Template Design Vision"
             container="body"
             [triggers]>
          </i>
        </div>
        <ng-template #designVisionPopoverContent>
          Use your own words to best describe your vision for the template. Here are some things to consider:
          <div><strong>Layout:</strong> Do you prefer a single column or multiple columns? Should there be a hero image at the top?
          </div>
          <div><strong>Elements:</strong> Icons, buttons, dividers, or any specific visual elements you want included. Where should they be placed?
          </div>
          <div><strong>Mood & Tone:</strong> Should it be formal, playful, minimalistic, retro, or festive?</div>
          <div><strong>Inspiration:</strong> If you've seen email templates or designs you love, describe them. It'll help us get closer to your vision.
          </div>
          Remember, the more you share, the better the AI can design according to your expectations
        </ng-template>
      </div>
    </div>

    <textarea class="form-control" id="designVisionTextarea" rows="5" [(ngModel)]="designVision"></textarea>
    <p class="text-danger mt-2" *ngIf="productOrPromptError">A product or a prompt is required.</p>

    <div class="row" *ngIf="contentFormat === 'mjml'">
      <div class="col-md-12 pt-2">
        <label class="font-weight-bold mr-1">Sample Prompts: </label>
        <ng-container *ngFor="let sample of samplePrompts">
          <button class="mr-1" (click)="designVision = sample.prompt">{{ sample.label }}</button>
        </ng-container>
      </div>
    </div>

    <hr>

    <div class="row mb-3" *ngIf="contentFormat === 'mjml'">
      <div class="col-md-6">
        <h5>Theme</h5>
        <div ngbDropdown class="d-inline-block w-100">
          <button type="button" class="btn btn-outline-primary w-100" id="startDateDropdown"
                  ngbDropdownToggle>{{ selectedTheme }}</button>
          <div ngbDropdownMenu aria-labelledby="startDateDropdown" class="w-100">
            <ng-container *ngFor="let theme of themeOptions">
              <button (click)="selectedTheme = theme" ngbDropdownItem>{{ theme }}</button>
            </ng-container>
          </div>
        </div>
        <input class="form-control" type="text" placeholder="Placeholder text" [(ngModel)]="customTheme"
               *ngIf="selectedTheme === 'Other'">
        <div class="slightly-smaller-text text-gray-color mt-1 p-1">
          The primary goal or theme of your template helps set the direction and tone.
        </div>
      </div>
    </div>

    <div class="mb-3 row" *ngIf="contentFormat === 'mjml'">
      <div class="col-6 p-1">
        <div class="card p-1 h-100">
          <div class="text-center">
            <label class="form-label">Featured Image</label>
            <small class="ml-1">(Optional)</small>
          </div>
          <div class="file btn btn-primary upload-button">
            <span *ngIf="!loadingFeaturedImageUrl">Choose file</span>
            <fa-icon [icon]="['fas', 'cog']" *ngIf="loadingFeaturedImageUrl" [spin]="true" size="1x"
                     class="white-color"></fa-icon>
            <input type="file" accept="image/*"
                   id="featuredImageFileSelector"
                   (change)="handleImageSelect($event.target.files[$event.target.files.length - 1], 'featuredImageUrl')">
          </div>
          <img [src]="featuredImageUrl" class="w-100 mt-1" *ngIf="featuredImageUrl">
        </div>
      </div>
      <div class="col-6 p-1">
        <div class="card p-1 h-100">
          <div class="text-center">
            <label class="form-label">Background Image</label>
            <small class="ml-1">(Optional)</small>
          </div>
          <div class="file btn btn-primary upload-button">
            <span *ngIf="!loadingBackgroundImageUrl">Choose file</span>
            <fa-icon [icon]="['fas', 'cog']" *ngIf="loadingBackgroundImageUrl" [spin]="true" size="1x"
                     class="white-color"></fa-icon>
            <input type="file" accept="image/*"
                   id="backgroundImageFileSelector"
                   (change)="handleImageSelect($event.target.files[$event.target.files.length - 1], 'backgroundImageUrl')">
          </div>
          <img [src]="backgroundImageUrl" class="w-100 mt-1" *ngIf="backgroundImageUrl">
        </div>
      </div>
    </div>

    <div class="text-right" *ngIf="remainingRequests && remainingRequests <= 20">
      <small class="text-right">
        <strong>
          Monthly AI Limit: {{ remainingRequests }} Generations Remaining.
        </strong><br>
        Current subscription: {{requestLimit}} per month).
        <a (click)="activeModal.close(false);" routerLink="/billing">Upgrade</a> for more.
      </small>
    </div>
  </div>

  <div class="modal-footer bg-secondary">
    <div class="d-flex justify-content-end">
      <div class="mr-2">
        <button type="button"
                class="btn btn-secondary mr-2"
                (click)="activeModal.close(false);">
          Cancel
        </button>
        <button type="submit"
                [disabled]="(designVision.length === 0 && contentFormat === 'mjml') ||
                            remainingRequests === 0 ||
                            !remoteWorking"
                class="btn btn-primary"
                (click)="onSubmit()">
          Generate
        </button>
      </div>
    </div>
  </div>
</div>
