import { HttpClient, HttpEvent, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class UploadService {
  constructor(private http: HttpClient) {}

  // file from event.target.files[0]
  upload(
    url: string,
    file: File,
    method = "PATCH",
    params: any = {}
  ): Observable<HttpEvent<any>> {
    const data = new FormData();
    data.append("upload", file);

    Object.keys(params).forEach((key) => {
      data.append(key, params[key]);
    });

    const options = {
      params,
      reportProgress: true,
    };

    const req = new HttpRequest(method, url, data, options);
    return this.http.request(req);
  }
}
