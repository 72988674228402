import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ProductAttachment } from "@models/dashboard/product_attachment";
import { Product } from "@models/product";
import { Response } from "@models/response";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { BehaviorSubject, Observable, of } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  mergeMap,
} from "rxjs/operators";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProductsService {
  private productsIndexSource$ = new BehaviorSubject<Product[]>(null);

  constructor(
    private httpClient: HttpClient,
    private deserializeService: DeserializeService,
  ) {}

  getIndexSource(): BehaviorSubject<Product[]> {
    return this.productsIndexSource$;
  }

  setIndexSource(products: Product[]): void {
    this.productsIndexSource$.next(products);
  }

  // TODO: Replace with show
  getProduct(id: string): Observable<Product> {
    return this.httpClient.get<Product>(`${environment.apiUrl}/v2/products/${id}`).pipe(
      mergeMap(
        async (productsResponse: any) =>
          (this.deserializeService.deserialize(productsResponse)) as Product
      )
    );
  }

  index(params?: any): Observable<Response<Product[]>> {
    return this.httpClient
      .get<Response<Product[]>>(`${environment.apiUrl}/v2/products`, { params })
      .pipe(
        mergeMap(async (productsResponse: any) => {
          const products = (this.deserializeService.deserialize(productsResponse)) as Product[];
          return new Response<Product[]>(products, productsResponse.meta);
        })
      );
  }

  search(text$: Observable<string>): Observable<Product[]> {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      mergeMap((term) =>
        term.length > 2 ? this.index({ sku: term }) : of(new Response([], {}))
      ),
      map((result) => result.data)
    );
  }

  show(id: string, params = {}) {
    return this.httpClient
      .get<Response<Product>>(`${environment.apiUrl}/v2/products/${id}`, { params })
      .pipe(
        mergeMap(async (productResponse: Response<Product>) => {
          const product = (this.deserializeService.deserialize(productResponse)) as Product;
          return new Response<Product>(product, productResponse.meta);
        })
      );
  }

  // TODO: This does not have a serializer on the backend.
  topRelatedProducts(productId: string, params?: any) {
    return this.httpClient.get<Response<Product[]>>(
      `${environment.apiUrl}/v2/products/${productId}/top_products`,
      { params }
    );
  }

  topChildProducts(productId: string, params?: any) {
    return this.httpClient.get<Response<Product[]>>(
      `${environment.apiUrl}/v2/products/${productId}/top_child_products`,
      { params }
    );
  }

  purchases(productId: string, params?: any) {
    return this.httpClient.get<Response<any[]>>(
      `${environment.apiUrl}/v2/products/${productId}/referrers`,
      { params }
    );
  }

  actions(productId: string, params?: any) {
    return this.httpClient.get<Response<any>>(
      `${environment.apiUrl}/v2/products/${productId}/top_actions`,
      { params }
    );
  }

  top_abandoned_products(params?: any) {
    return this.httpClient.get<Response<any[]>>(
      `${environment.apiUrl}/v2/products/get_top_abandoned_products`,
      { params }
    );
  }

  topAttachments(
    productId: string,
    params?: any
  ): Observable<ProductAttachment[]> {
    return this.httpClient
      .get<ProductAttachment[]>(`${environment.apiUrl}/v2/products/${productId}/top_attachments`, {
        params,
      })
      .pipe(
        mergeMap(
          async (response) =>
            this.deserializeService.deserialize(response)
        )
      );
  }
}
