import { Base } from "./base";

export class GeneratedText extends Base {
  createdAt: Date;
  generatedAt: Date; // Set when the AI finishes generating the MJML.
  status: string; // 'received', 'processing', 'errored', or 'finished'. Set by the backend.
  contentType: string; // 'template' or 'section'.
  prompt: string; // User input.
  maxLength: number; // Maximum number of characters in the generated text.
  productId: string;
  generatedText: string[];
}
