import { Injectable } from "@angular/core";
import { AIModuleModalComponent } from "@app/content_editor/components/email_content_editor/components/ai_module_modal/ai_module_modal.component";
import {MjmlGeneratorService} from "@app/shared/services/mjml_generator_service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import mjml2html from "mjml-browser";
import { AiDesignerModalComponent } from "@app/shared/components/ai_designer_modal/ai_designer_modal.component";

@Injectable({ providedIn: 'root' })
export class AIModuleModalService {
  constructor(private modalService: NgbModal, private mjmlGeneratorService: MjmlGeneratorService,) {
  }

  openModal(contentType, MJMLOptions = [], carouselOnly = false): Promise<any> {
    const modal = this.modalService.open(AIModuleModalComponent, {
      size: 'lg',
      backdrop: 'static',
    });
    modal.componentInstance.contentType = contentType;
    modal.componentInstance.mjmlContentsResult = MJMLOptions;
    modal.componentInstance.carouselOnly = carouselOnly;
    modal.componentInstance.htmlContents =  MJMLOptions.map((h) => h.html);
    return modal.result;
  }

  openSocialModal(): Promise<any> {
    const modal = this.modalService.open(AiDesignerModalComponent, {
      size: 'md',
      backdrop: 'static',
    });
    modal.componentInstance.contentType = 'social_post';
    return modal.result;
  }

  hasOpenModals() {
    return this.modalService.hasOpenModals();
  }

  checkGeneratedMJML(id): Observable<any> {
    return this.mjmlGeneratorService.show(id);
  }

  extractMJML(mjml: string, tag: string) {
    const results = []
    this.extractMJMLTags(mjml, tag).forEach((mjmlContent) => {
      const html = this.mjml2html(mjmlContent, tag);
      if (html) {
        const tagNameFromNewTemplate = mjmlContent.substring(0, 12);
        if (mjmlContent && tagNameFromNewTemplate.includes(tag)) {
          results.push({
            mjml: mjmlContent,
            html: html
          });
        }
      }
    });
    if (results.length > 3) {
      results.shift();
    }
    return results;
  }

  private mjml2html(mjml: string, tag: string) {
    let mjmlFormatted = "";
    if (tag.includes("section")) {
      mjmlFormatted = `<mjml><mj-body>${mjml}</mj-body></mjml>`;
    } if (tag.includes("text")) {
      mjmlFormatted = `<mjml><mj-body><mj-section><mj-column>${mjml}</mj-column></mj-section></mj-body></mjml>`;
    } if (tag.includes("column")) {
      mjmlFormatted = `<mjml><mj-body><mj-section>${mjml}</mj-section></mj-body></mjml>`;
    }

    try {
      return mjml2html(mjmlFormatted, { noMigrateWarn: true })?.html;
    } catch (e) {
      console.log("Invalid mjml template");
      return null;
    }
  }

  private extractMJMLTags(mjml: string, tag: string) {
    const sandbox = document.createElement('div');
    sandbox.innerHTML = mjml;
    const tags = [];
    sandbox.querySelectorAll(tag).forEach((mjml) => {
      tags.push(mjml.outerHTML);
    });

    return tags;
  }

  getRobotLoadingTemplate(id, contentType, columnAttributes = ""): string {
    const addSectionTag = contentType.includes("section");
    const addColumn = addSectionTag || contentType.includes("column");
    return `${(addSectionTag ? "<mj-section>" : "")}
      ${(addColumn ? `<mj-column${columnAttributes}>` : ``)}
        <mj-text>
          <div generating-content-module-id="${id}" content-type="${contentType}" style="text-align: center;padding: 10px;">
            <img src="https://springbot-assets.s3.amazonaws.com/images/robot-loading.gif" style="width: 100px;"/>
            <p style="color: gray; padding: 0px; margin: 0; margin-top: 6px;">
              We are buiding something for you...
            </p>
          </div>
        </mj-text>
      ${(addColumn ? `</mj-column>` : ``)}
      ${(addSectionTag ? "</mj-section>" : "")}`;
  }

  getChooseTemplateHTML(id, contentType): string {
    const addSectionTag = contentType.includes("section");
    const addColumnTag = addSectionTag || contentType.includes("column");
    const addTextTag = addColumnTag || contentType.includes("text");
    const template = `<div generating-content-module-id="${id}" content-type="${contentType}" templates-loaded="1"
      style="text-align: center;padding: 16px;">
      <p style="color: #4A4A4A; padding: 0px; margin: 0;">
        Choose a template
      </p>
    </div>`;
    return `${(addSectionTag ? "<mj-section>" : "")}
            ${(addColumnTag ? "<mj-column>" : "")}
            ${(addTextTag ? "<mj-text>" : "")}
              ${template}
        ${(addTextTag ? "</mj-text>" : "")}
        ${(addColumnTag ? "</mj-column>" : "")}
        ${(addSectionTag ? "</mj-section>" : "")}`;
  }
}
