import { Component, ElementRef } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "zapier-config-modal",
  styleUrls: ["./zapier_config_modal.component.scss"],
  templateUrl: "./zapier_config_modal.component.html",
})
export class ZapierConfigModalComponent {
  loadingData = false;
  done = true;
  errors: any;
  private el: any;
  private zapierScript: any;

  constructor(private activeModal: NgbActiveModal, el: ElementRef) {
    this.el = el.nativeElement;
  }

  ngAfterViewInit() {
    this.zapierScript = document.createElement("script");
    this.zapierScript.type = "text/javascript";
    this.zapierScript.src =
      "https://zapier.com/apps/embed/widget.js?services=springbot&limit=5&html_id=zapier-embed";
    this.el.appendChild(this.zapierScript);
  }

  finish() {
    this.activeModal.close({ connected: false, errored: false });
  }

  cancel() {
    if (this.done) {
      this.activeModal.dismiss();
    } else {
      this.activeModal.close({ connected: true, errored: true });
    }
  }
}
