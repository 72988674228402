import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ReportingUtilityService {
  getChannelIcon(model: any): string[] {
    switch (model.channelName || model.name) {
      case 'Email Campaigns':
        return ['fas', 'envelope'];
      case 'Facebook':
        return ['fab', 'facebook-square'];
      case 'Instagram':
        return ['fab', 'instagram'];
      case 'Retargeting with Adroll':
      case 'Retargeting':
        return ['fas', 'bullseye'];
      case 'Trackable Links':
        return ['fas', 'link'];
      case 'Triggered Emails':
        return ['fas', 'crosshairs'];
      case 'Twitter':
        return ['fab', 'twitter'];
      case 'SMS':
        return ['fas', 'phone-alt'];
      default:
        return ['fas', 'rocket'];
    }
  }
}
