<div class="sb-modal-content zapier-config-form">
  <div class="modal-icon-wrapper">
    <img class="modal-icon" src="https://springbot.s3.amazonaws.com/integrations/zapier-logomark.svg">
  </div>
  <h4 class="modal-header-text">
    Zapier Integration
  </h4>
  <div class="zapier-intro-text">
    <p>
      Zapier allows you to instantly connect Springbot with 2,000+ apps to automate your work. Automated connections called "zaps" can automate your day-to-day tasks and build workflows between apps that otherwise wouldn't be possible.
    </p>
    <p>
      Each zap has one app as the trigger (where your information comes from) which causes the action in other apps (where your data gets sent automatically). Sign up for a free <a href="https://zapier.com/sign-up/">Zapier account</a>, from there you can jump right in.
    </p>
  </div>

  <div class="zapier-modal-error-text" *ngFor="let error of errors">
    {{ error.message }}
  </div>

  <div *ngIf="loadingData" class="text-center modal-loading">
    <fa-icon [icon]="['fas', 'cog']" size="2x" [spin]="true" class="green-color"></fa-icon>
  </div>

  <div id="zapier-embed"></div>

</div>

<div class="modal-actions">
  <button class="btn btn-secondary modal-action" (click)="cancel()">Cancel</button>
  <button class="btn btn-primary modal-action" [disabled]="!done" (click)="finish()">Finish</button>
</div>
