<div class="sb-modal-content">
  <div class="modal-icon-wrapper">
    <img class="modal-icon" src="https://springbot.s3.amazonaws.com/integrations/mailchimp.png">
  </div>
  <h4 class="modal-header-text">
    MailChimp Configuration
  </h4>

  <div *ngIf="loadingData" class="text-center">
    <div class="spacing"></div>
    <fa-icon [icon]="['fas', 'cog']" size="2x" [spin]="true" class="green-color"></fa-icon>
  </div>

  <div class="alert alert-danger text-center" *ngIf="errored">
    <fa-icon [icon]="['fas', 'exclamation-circle']" size="2x"></fa-icon>
    <p>
      We're sorry but something went wrong.
    </p>
    <p>
      The springbots are aware of the issue and are working to resolve it.
    </p>
  </div>

  <div *ngIf="!loadingData && !errored && connected">
    <div *ngIf="provider.accountName">
      <strong>Account:</strong>
      {{ provider.accountName }}
    </div>
    <div *ngIf="provider.userEmail">
      <strong>Email:</strong>
      {{ provider.userEmail }}
    </div>

    <div class="provider-list-container">
      <p class="text-center"><strong>Lists to Sync</strong></p>
      <div class="custom-control custom-switch" *ngFor="let subprovider of subproviders">
        <input type="checkbox" class="custom-control-input" id="{{subprovider.id + '_toggle'}}"
        [(ngModel)]="subprovider.selected"
        (change)="changeSubproviderState(subprovider)" [disabled]="subproviderToggling">
        <label class="cursor-pointer" for="{{subprovider.id + '_toggle'}}">{{ subprovider.name }}</label>
      </div>

      <p class="lists-not-found"
          *ngIf="provider && provider.hasAccessToken && subproviders.length <= 0">
        Lists not found from {{ provider.name }}.
      </p>
    </div>
  </div>
</div>

<div class="modal-actions">
  <button (click)="disconnect()" [disabled]="loadingData || errored" class="btn btn-danger modal-action">
    Disconnect
  </button>
  <button class="btn btn-secondary modal-action" (click)="refresh()" [disabled]="refreshingList || errored">Refresh Lists</button>
  <button class="btn btn-primary modal-action" (click)="finish()" [disabled]="loadingData || errored">Finish</button>
</div>
