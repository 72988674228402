import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthTokenService } from "@app/auth/services/auth-token.service";
import { environment } from "@environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authTokenService: AuthTokenService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
      Authorization: `Bearer ${this.authTokenService.getToken()}`,
      "Authorization-Type": "jwt",
      "X-Store-ID": this.authTokenService.getCurrentStoreId() || "",
    }

    const impersonationId = this.authTokenService.getImpersonatorId();

    if (impersonationId) {
      headers["Impersonate"] = impersonationId
    }

    if (req.url.indexOf(environment.apiUrl) > -1) {
      // This is an internal API request.
      req = req.clone({
        setHeaders: headers,
      });
    } else {
      // This is an external request
      console.info("External request to: ", req.url);
    }

    return next.handle(req);
  }
}
