<div class="row">
  <div class="col-lg-10 col-md-6">
    <h3>
      <fa-icon class="glacier-medium-color font-weight-light" [icon]="['fas', 'clipboard-list']"></fa-icon>
      To do list
    </h3>
  </div>
</div>
<div class="row p-2 justify-content-center">
  <!-- How to pass a param in the HTML:
  (cta)="checklistItemClick(item, ['param'])" -->
  <checklist-item *ngFor="let item of checklistItems"
                  class="col-md-4 p-2 animate-fade" item="{{item.id}}"
                  text="{{item.text}}" [clearable]="false"
                  subtext="{{item.subtext}}"
                  (cta)="checklistItemClick(item)"
                  ctaLink="{{item.ctaLink}}" ctaText="{{item.ctaText}}">
  </checklist-item>
</div>
