import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { ProductsService } from "@app/shared/components/products/products.service";
import { Product } from "@models/product";
import { NgbActiveModal, NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import { of, Subject } from "rxjs";
import { map } from "rxjs/operators";

interface Alert {
  type: "info" | "success" | "warning" | "danger" | "primary"
  message: string
}

type tabName = 'search' | 'revenue' | 'abandons' | 'views';

// *********************************  IMPORTANT *************************************************************************
// the explicit change detection calls are needed for this component to function correctly within the grapes email editor
// do not remove them without verifying it still works correctly
// *********************************  IMPORTANT *************************************************************************

// this component is meant to be instantiated from inside the AngularBootstrap modal
// ex: this.modalService.open(ProductSelectModalComponent);
// optional inputs if products need to be 'pre-selected' (Pass in only 1 of the three)
// - selectedProductIds, selectedProduct OR selectedProducts
// for multi-select, set maxSelect greater than 1

@Component({
  styleUrls: ["./editor_product_select_modal.component.scss"],
  templateUrl: "./editor_product_select_modal.component.html",
})
export class EditorProductSelectModalComponent {
  selectedProductLoading = false; // depending on source, selectedProducts may be passed in directly or require a GET request
  productsByMetricLoading = true;
  search = "";
  searchSubject: Subject<string> = new Subject<string>();
  alerts: Alert[] = [];

  @Input()
  selectedProduct: Product;

  @Input()
  selectedProducts: Product[] = [];

  @Input()
  selectedProductSkus: string[];

  @Input()
  maxSelect = 1; // defaults to single product selection

  activeTab: tabName = 'search';
  readonly tabs: tabName[] = ['search', 'revenue', 'abandons', 'views'];
  productsByMetric: { revenue: Product[], cart_abandons: Product[], views: Product[] } = {
    revenue: [],
    cart_abandons: [],
    views: [],
  };

  readonly thirtyDays = 30 * 60 * 60 * 24 * 1000;
  metricRequestParams = {
    startDate: new Date(Date.now() - this.thirtyDays),
    endDate: new Date(),
    page: 1,
    perPage: 5,
    stats: true, // required for backend to return metrics
  };

  filteredProducts: Product[] = [];
  productFilter = {
    page: 1,
    perPage: 10,
  };

  constructor(private activeModalRef: NgbActiveModal,
    private changeDetectorRef: ChangeDetectorRef,
    private productsService: ProductsService) {
  }

  ngOnInit(): void {
    this.setSelectedProducts();
    this.getProductsByMetric();
  }

  close(): void {
    this.activeModalRef.dismiss("cancelled");
  }

  accept(): void {
    if (this.selectedProducts.length === 0) {
      this.displayAlert({ message: "No Product Selected", type: "warning" });
      return;
    }
    if (this.maxSelect === 1) {
      this.activeModalRef.close(this.selectedProducts[0]);
    } else {
      this.activeModalRef.close(this.selectedProducts);
    }
  }

  searchProducts(event: Event): void {
    const input = event.target as HTMLInputElement;
    const text$ = of(input.value);
    this.productsService.search(text$).pipe(map((products) => {
      return this.removeSelectedProductsFrom(products);
    })).subscribe((results) => {
      this.filteredProducts = results;
    });
  }

  selectSearchedProduct(event: NgbTypeaheadSelectItemEvent): void {
    this.search = "";
    this.selectProduct(event.item as Product);
  }

  selectProduct(product: Product): void {
    if (this.maxSelect === 1) { // auto toggle selection for single product select situations
      this.selectedProducts = [product];
    } else if (this.selectedProducts.length >= this.maxSelect) {
      this.displayAlert({ type: 'info', message: `${this.maxSelect} of ${this.maxSelect} components selected` });
    } else {
      this.selectedProducts = [...this.selectedProducts, product];
      this.sortProductsByMetric(); // moves selected products up the list, only used for multi-select
    }
    this.changeDetectorRef.detectChanges();
  }

  // clears the typeahead's input on a selection
  formatInput(_inputObject: any): string {
    return "";
  }

  isSelected(product: Product): boolean {
    return !!this.selectedProducts.find(({ id }) => id === product.id);
  }

  clearSelection(product: Product): void {
    this.selectedProducts = this.selectedProducts.filter(({ id }) => id !== product.id);
    this.sortProductsByMetric();
    this.changeDetectorRef.detectChanges();
  }

  displayAlert(alert: Alert): void {
    this.alerts = [alert, ...this.alerts];
    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      this.alerts = this.alerts.filter(({ message }) => message !== alert.message);
      this.changeDetectorRef.detectChanges();
    }, 2000);
  }

  // selectedProducts can be derived from 3 inputs
  // - selectedProduct
  // - selectedProducts
  // - selectedSkus
  // all are standardized to selectedProducts
  private setSelectedProducts(): void {
    if (this.selectedProducts?.length > 0) {
      return;
    }

    if (this.selectedProduct) {
      this.selectedProducts = [this.selectedProduct];
    } else if (this.selectedProductSkus?.length > 0) {
      this.getSelectedProductsBySkus();
    }
  }

  private getSelectedProductsBySkus(): void {
    this.selectedProductLoading = true;
    const skuQueryParams = { exactSkus: this.selectedProductSkus.join(",") };
    this.productsService.index(skuQueryParams).subscribe(({ data: products }) => {
      this.selectedProducts = products;
    }, (_error) => {
      this.displayAlert({
        message: `There was an error retrieving your ${this.selectedProductSkus.length > 1 ? 'products' : 'product'}`,
        type: 'danger',
      });
    }, () => {
      this.selectedProductLoading = false;
    });
  }

  private getProductsByMetric(): void {
    let loadedMetrics = 0;
    ["revenue", "cart_abandons", "views"].forEach((metric) => {
      this.productsService.index({ sort: `${metric} desc`, ...this.metricRequestParams })
        .subscribe(({ data: products }) => {
          this.productsByMetric[metric] = products;
        }, (_error) => {
          this.displayAlert({ message: `there was an error getting products by ${metric}`, type: "danger" });
        }, () => {
          loadedMetrics++;
          this.productsByMetricLoading = loadedMetrics < 3;
        });
    });
  }

  // moves selected items to the front of all arrays in order of selection
  private sortProductsByMetric(): void {
    ["revenue", "cart_abandons", "views"].forEach((metric) => {
      const metricProducts = this.productsByMetric[metric];

      const selectedMetricProducts = this.selectedProducts.filter(({ id }) => {
        return metricProducts.some((selectedProduct) => selectedProduct.id === id);
      });
      const unselectedMetricProducts = this.removeSelectedProductsFrom(metricProducts);
      this.productsByMetric[metric] = [...selectedMetricProducts, ...unselectedMetricProducts];
    });
  }

  private removeSelectedProductsFrom(products: Product[]): Product[] {
    return products.filter(({ id }) => {
      return !this.selectedProducts.some((selectedProduct) => selectedProduct.id === id);
    });
  }

  selectedPosition(product: Product): string {
    const productIndex = this.selectedProducts.findIndex(({ id }) => id === product.id);
    return productIndex >= 0 ? (1 + productIndex).toString(10) : "";
  }

  productsForTab(tab: tabName): Product[] {
    switch (tab) {
      case 'search':
        return this.selectedProducts;
      case "abandons":
        return this.productsByMetric['cart_abandons'];
      default:
        return this.productsByMetric[tab];
    }
  }
}
