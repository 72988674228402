import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Channel } from "@models/channel";
import { Response } from "@models/response";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { mergeMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@models/action";
import { ChannelStats } from "@models/reports/channel_stats";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class ChannelsService {
  constructor(
    private httpClient: HttpClient,
    private deserializeService: DeserializeService,
  ) {}

  private base = `${environment.apiUrl}/v2/channels`;

  index(params?: any): Observable<Response<Channel[]>> {
    return this.httpClient.get<Response<Channel[]>>(this.base, {params}).pipe(
      mergeMap(async (response: any) => {
        const channels = this.deserializeService.deserialize(response);
        return new Response(channels, response.meta);
      }),
    );
  }

  dashboardStats(params?: any): Observable<Response<Channel[]>> {
    const path = `${this.base}/index_stats`;
    return this.httpClient.get<Channel[]>(path, { params }).pipe(mergeMap(async (response: any) => {
      return new Response(response.data, response.meta);
    }));
  }

  show(channelId?: string, params?: any): Observable<Response<Channel>> {
    return this.httpClient
      .get<Response<Channel>>(`${this.base}/${channelId}`, { params })
      .pipe(
        mergeMap(async (channelResponse: Response<Channel>) => {
          const channel = (this.deserializeService.deserialize(channelResponse)) as Channel;
          return new Response<Channel>(channel, channelResponse.meta);
        })
      );
  }

  stats(channelId: string, params?: any): Observable<ChannelStats> {
    return this.httpClient
      .get(`${this.base}/${channelId}/stats`, { params })
      .pipe(mergeMap(async (response: any) => response.stats as ChannelStats));
  }

  topActions(channelId: string, params?: any): Observable<Response<Action[]>> {
    return this.httpClient
      .get<Response<Action[]>>(`${this.base}/${channelId}/top_actions`, {
        params,
      })
      .pipe(
        mergeMap(async (actionResponse) => {
          const actions = (this.deserializeService.deserialize(actionResponse)) as Action[];
          return new Response(actions, actionResponse.meta);
        })
      );
  }
}
