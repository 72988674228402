import {
  ContentChildren,
  Directive,
  EventEmitter,
  Output,
  QueryList,
} from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { SortableHeaderDirective } from "./sortable_header.directive";

@Directive({
  selector: "table[sortable]",
})
export class SortableTableDirective {
  @ContentChildren(SortableHeaderDirective, { descendants: true })
  contentChildren: QueryList<SortableHeaderDirective>;

  @Output()
  sort = new EventEmitter<string>();

  private subscriptions: Subscription[];

  ngAfterViewInit() {
    this.subscriptions = this.contentChildren.map((tableHeader) =>
      tableHeader.sort.subscribe((sortDirection) => {
        // Unset the sort direction of all other headers
        this.contentChildren.forEach((child) => {
          if (child.sortName !== tableHeader.sortName) {
            child.unsetSortDirection();
          }
        });

        // Emit the sort direction to the parents
        this.sort.emit(sortDirection);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
