import { Base } from "./base";

export class ContentBlock extends Base<ContentBlock> {
  id: string;
  name: string;
  basic: boolean;
  storeId: string;
  mjmlContent: string;
  content: string;
  thumbnailUrl: string;
}
