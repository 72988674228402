export class ObjectFormatter {
  // recursively formats all keys to underscore from camel case or kebab case
  static formatAllKeys<Result = { [key: string]: any }>(
    oldBodyObj: { [key: string]: any },
    format: "snake_case" | "camel_case" = "snake_case"
  ): Result {
    return Object.entries(oldBodyObj).reduce((newBodyObj, [key, value]) => {
      // recursively formats all values that are objects
      if (this.isKeyedObject(value)) {
        value = this.formatAllKeys(value);
      }
      // recursively formats arrays for possibility of containing objects
      if (Array.isArray(value)) {
        value = value.map((item) =>
          this.isKeyedObject(item) ? this.formatAllKeys(item) : item
        );
      }
      const newKey =
        format === "snake_case"
          ? ObjectFormatter.toSnakeCase(key)
          : ObjectFormatter.toCamelCase(key);
      newBodyObj[newKey] = value;
      return newBodyObj;
    }, {}) as Result;
  }

  // converts to back-end friendly format
  // added fixes for peculiar scenarios
  static toSnakeCase(key: string): string {
    return ObjectFormatter.numberTransition(
      ObjectFormatter.deCamelize(ObjectFormatter.deKebab(key))
    );
  }

  // returns true for a hash-like object with key/value pairs
  // skips Arrays (which are handled separately)
  // and Dates (which would be mangled by the formatting)
  // Note: Angular seems to automatically correctly format dates as strings in get requests
  static isKeyedObject(value: any): boolean {
    return !!(
      !!value &&
      typeof value === "object" &&
      Array.isArray(value) === false &&
      !(value instanceof Date)
    );
  }

  // takes in kebab-case, outputs underscore
  static deKebab(value: string): string {
    return value.split("-").join("_");
  }

  // takes in camel-case, outputs underscore
  static deCamelize(value: string): string {
    return value.replace(/([A-Z])/g, "_$1").toLowerCase();
  }

  // takes in camel-case, outputs lowercase string with spaces
  // ex "superFreak" => "super freak"
  static lowerSpacedString(value: string): string {
    return value.replace(/([A-Z])/g, " $1").toLowerCase();
  }

  // adds underscore on transition from letter to number
  // ex: phone1 => phone_1
  static numberTransition(value: string): string {
    return value.replace(/([A-Za-z])([0-9])/, "$1_$2");
  }

  // takes in (snake case) or spaced string, outputs camelcase
  // ex: "super_freak" or "super freak" => "superFreak"
  static toCamelCase(value: string): string {
    return value
      .split(/[\s|_]/)
      .map((chunk, i) =>
        i > 0 ? chunk[0].toUpperCase() + chunk.slice(1) : chunk
      )
      .join("");
  }
}
