import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "time-picker",
  styleUrls: ["./time_picker.component.scss"],
  templateUrl: "./time_picker.component.html",
})
export class TimePickerComponent {
  @Input()
  seedTime: string;

  @Input()
  selectedTime: NgbTimeStruct;

  @Input()
  inputHidden: boolean = false;

  @Input()
  icon: any = ['fas', 'clock'];

  @Input()
  iconSize: string = "1x";

  @Output()
  selectedTimeChange = new EventEmitter<NgbTimeStruct>();

  readonly hourButtons = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  readonly minuteButtons = [
    "00",
    "05",
    "10",
    "15",
    "20",
    "25",
    "30",
    "35",
    "40",
    "45",
    "50",
    "55",
  ];

  loading = true;
  period = "AM";
  hour: string;
  minute: string;

  ngOnInit(): void {
    this.setDefaultTime();
  }

  displayTime(): string {
    return `${this.hour}: ${this.minute} ${this.period}`;
  }

  onChange(): void {
    if (!this.selectedTime) {
      this.selectedTime = {} as NgbTimeStruct;
    }
    if (this.period === "AM") {
      if (this.hour === "12") {
        this.selectedTime.hour = 0;
      } else {
        this.selectedTime.hour = +this.hour;
      }
      this.selectedTime.minute = +this.minute;
    } else if (this.period === "PM") {
      if (this.hour === "12") {
        this.selectedTime.hour = +this.hour;
      } else {
        this.selectedTime.hour = +this.hour + 12;
      }
    }

    this.selectedTimeChange.emit(this.selectedTime);
    this.selectedTime.minute = +this.minute;
  }

  setHour(hour: string): void {
    this.hour = hour;
    this.onChange();
  }

  setMinute(minute: string): void {
    this.minute = minute;
    this.onChange();
  }

  setPeriod(period: string): void {
    this.period = period;
    this.onChange();
  }

  private setDefaultTime(): void {
    this.selectedTime.hour = 10;
    this.selectedTime.minute = 0;
    this.selectedTime.second = 0;

    if (/^[01]\d:\d{2}\s(AM|PM)$/.test(this.seedTime)) {
      this.period = this.seedTime.slice(6, 8);
      this.hour = this.seedTime.slice(0, 2);
      this.minute = this.seedTime.slice(3, 5);
      this.selectedTime.hour = +this.seedTime.slice(0, 2);
      this.selectedTime.minute = +this.seedTime.slice(3, 5);
    } else {
      this.period = "AM";
      this.hour = "10";
      this.minute = "00";
    }
    this.loading = false;
  }
}
