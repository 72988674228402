import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
// Tokens are stored in memory and local storage. We only attempt to get the token from
// local storage if it is not defined in memory.
export class AuthTokenService {
  private tokenKey: string = "sb-jwt-token";
  private refreshKey: string = "sb-refresh-token";
  private expirationKey: string = "sb-expiration-timestamp";
  private impersonationKey: string = "sb-impersonation";

  cachedTokens: any = {};

  constructor() {
    this.checkStorageAvailability();
  }

  private storage: Storage;

  setTokens(token: string, refresh: string) {
    this.setItem(this.tokenKey, token);
    this.setItem(this.refreshKey, refresh);
    let expiration = new Date()
    expiration.setTime(new Date().getTime() + (60 * 60 * 1000));
    this.setItem(this.expirationKey, expiration.toString());
  }

  getToken(): string {
    return this.getItem(this.tokenKey);
  }

  getRefreshToken(): string {
    return this.getItem(this.refreshKey);
  }

  remainingTokenTimeInMinutes(): number {
    const expirationStr = this.getItem(this.expirationKey);

    if (!expirationStr) {
      return 0
    }

    try {
      const expirationDate = new Date(expirationStr);
      const diff = Math.abs(expirationDate.getTime() - new Date().getTime());
      return Math.floor((diff/1000)/60);
    } catch(e) {
      console.error(e);
      return 0;
    }
  }

  isTokenExpired(): boolean {
    return this.remainingTokenTimeInMinutes() < 0;
  }

  clear() {
    this.removeItem(this.tokenKey);
    this.removeItem(this.impersonationKey);
    this.removeItem(this.refreshKey);
    this.removeItem(this.expirationKey);
  }

  setCurrentStoreId(id) {
    this.setItem("current-store-id", id);
  }

  getCurrentStoreId() {
    return this.getItem("current-store-id");
  }

  setImpersonationId(userId: string): void {
    if (!userId) return;

    this.setItem(this.impersonationKey, userId.toString());
  }

  getImpersonatorId(): string {
    return this.getItem(this.impersonationKey);
  }

  clearImpersonatorId(): void {
    this.removeItem(this.impersonationKey);
  }

  private setItem(key: string, value: string) {
    this.cachedTokens[key] = value;
    this.storage.setItem(key, value);
  }

  private getItem(key: string) {
    if (!this.cachedTokens[key]) {
      this.cachedTokens[key] = this.storage.getItem(key)
    }
    return this.cachedTokens[key];
  }

  private removeItem(key: string) {
    this.storage.removeItem(key);
    this.cachedTokens[key] = null;
  }

  private checkStorageAvailability() {
    try {
      localStorage.setItem("__storage_test__", "test");
      localStorage.removeItem("__storage_test__");
      this.storage = localStorage;
    } catch (e) {
      // localStorage not available, fall back to sessionStorage
      this.storage = sessionStorage;
    }
  }
}
