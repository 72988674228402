import { Base } from "./base";

export class GeneratedMjml extends Base {
  createdAt: Date;
  generatedAt: Date; // Set when the AI finishes generating the MJML.
  status: string; // 'received', 'processing', 'errored', or 'finished'. Set by the backend.
  contentType: string; // 'template' or 'section'.
  templateId: string; // The template that was generated from the content
  prompt: string; // User input.
  previousMjml: string; // (optional) The MJML that the user wants to modify.
  theme: string; // newsletter, seasonal sale, product launch, etc.
  listId: string; // The list the email is being sent to.
  campaignId: string; // The campaign the email is being sent to.
  featuredImageUrl: string; // Image likely to be utilized in the main content of the MJML.
  backgroundImageUrl: string; // Image that can be used as a background for the template/section.
  includedProductIds: string[]; // Products to include in the MJML.
  generatedMjml: string[];
}
