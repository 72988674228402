import { Filter } from "./filter";
import { Node } from "./node";

export class Observer {
  id: number;
  event?: string;
  filters?: Filter;
  flowId: number;
  subjectClass?: string;
  node: Node;
}
