import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Recommendation } from "@models/dashboard/recommendation";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class RecommendationsService {
  constructor(
    private http: HttpClient,
    private deserializeService: DeserializeService,
  ) {}

  index(params?: any): Observable<Recommendation[]> {
    return this.http.get<Recommendation[]>(`${environment.apiUrl}/v2/recommendations`, { params })
      .pipe(mergeMap(async (response) => this.deserializeService.deserialize(response)));
  }

  update(id: number, params: any): Observable<Recommendation> {
    return this.http.put(`${environment.apiUrl}/v2/recommendations/${id}`, params)
      .pipe(mergeMap(async (response) => this.deserializeService.deserialize(response)));
  }
}
