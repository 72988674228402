<div class="row">
  <div class="col-12 d-flex justify-content-between mt-3">
    <p>Facebook Audience Restrictions</p>
    <p (click)="open(fbRestrictionsModal)" class="cursor-pointer text-secondary">
      Edit
    </p>
  </div>
  <div class="col-12">
    <p>{{restrictionsDisplay}}</p>
  </div>
</div>

<ng-template #fbRestrictionsModal>
  <div class="modal-body">
    <div class="container-fluid mb-5">
      <div class="row justify-content-center">
        <div class="col-10 d-flex flex-column align-items-center text-center">
          <fa-icon [icon]="['fas', 'users']" size="4x"></fa-icon>
          <h2>Facebook Audience Restrictions</h2>
          <p>Restrict who should see this post on Facebook.
            Only the individuals in your set audience will be able to view this post.
          </p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-6 d-flex flex-column">
          <facebook-age-dropdown (notify)="restrictions.ageMin = $event"></facebook-age-dropdown>
          <facebook-location-selector
              [prompt]="'Search by country to target'"
              [label]="'Country'"
              [locationType]="'country'"
              (notify)="restrictions.countries.push($event);">
          </facebook-location-selector>
          <div *ngFor="let country of restrictions.countries"
               class="mx-1 my-2 d-flex justify-content-between align-items-center">
            <span>{{country.name}}</span><fa-icon (click)="removeLocation('countries', country)"
                                            [icon]="['fas', 'times-circle']" class="text-danger cursor-pointer"></fa-icon>
          </div>
          <facebook-location-selector
              [prompt]="'Search by province, state, or region to target'"
              [label]="'Region'"
              [locationType]="'region'"
              (notify)="restrictions.regions.push($event);">
          </facebook-location-selector>
          <div *ngFor="let region of restrictions.regions"
               class="mx-1 my-2 d-flex justify-content-between align-items-center">
            <span>{{region.name}}</span><fa-icon (click)="removeLocation('regions', region)"
                                           [icon]="['fas', 'times-circle']" class="text-danger cursor-pointer"></fa-icon>
          </div>
          <facebook-location-selector
              [prompt]="'Search by city to target'"
              [label]="'City'"
              [locationType]="'city'"
              (notify)="restrictions.cities.push($event);">
          </facebook-location-selector>
          <div *ngFor="let city of restrictions.cities"
               class="mx-1 my-2 d-flex justify-content-between align-items-center">
            <span>{{city.name}}</span><fa-icon (click)="removeLocation('cities', city)"
                                         [icon]="['fas', 'times-circle']" class="text-danger cursor-pointer"></fa-icon>
          </div>
          <facebook-location-selector
              [prompt]="'Search by ZIP or post codes to target'"
              [label]="'Zip'"
              [locationType]="'zip'"
              (notify)="restrictions.zips.push($event);">
          </facebook-location-selector>
          <div *ngFor="let zip of restrictions.zips"
               class="mx-1 my-2 d-flex justify-content-between align-items-center">
            <span>{{zip.name}}</span><fa-icon (click)="removeLocation('zips', zip)"
                                        [icon]="['fas', 'times-circle']" class="text-danger cursor-pointer"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer bg-secondary">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6 d-flex justify-content-center">
          <button (click)="close()" class="btn btn-secondary px-5">
            Cancel
          </button>
        </div>
        <div class="col-6 d-flex justify-content-center">
          <button (click)="accept()" class="btn btn-primary px-5">
            Accept
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
