import { Component } from "@angular/core";

import { AdrollConfigurationService } from "@app/shared/services/adroll_configuration.service";
import { StoreService } from "@app/shared/services/store.service";
import { Store } from "@models/store";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "adroll-config-modal",
  styleUrls: ["./adroll_config_modal.component.scss"],
  templateUrl: "./adroll_config_modal.component.html",
})
export class AdrollConfigModalComponent {
  loadingData = true;
  errorState = false;
  private storeSettings: Store;
  adrollConfiguration: any;
  requiresTrialUpgrade = false;
  requiresPackageUpgrade = false;

  constructor(
    private activeModal: NgbActiveModal,
    private adrollConfigurationService: AdrollConfigurationService,
    private storeService: StoreService
  ) {}

  ngOnInit() {
    this.storeSettings = this.storeService.getSource().value;
    this.getAdrollConfiguration();
  }

  getAdrollConfiguration() {
    this.adrollConfigurationService
      .show({
        should_check_has_package: true,
      })
      .subscribe((response) => {
        this.adrollConfiguration = response.data;
        this.setUpsellStates();
        this.loadingData = false;
      });
  }

  setUpsellStates() {
    const isTrialPackage = this.storeSettings.hasTrialPackage;

    this.requiresTrialUpgrade = isTrialPackage;
    this.requiresPackageUpgrade =
      !isTrialPackage && !this.adrollConfiguration.has_adroll_package;

    const needsUpsell =
      this.requiresTrialUpgrade || this.requiresPackageUpgrade;
    if (!this.adrollConfiguration.enabled && needsUpsell) {
      this.errorState = true;
    }
  }

  save() {
    let observer;
    if (!this.adrollConfiguration.enabled) {
      observer = this.adrollConfigurationService.create(this.adrollParams());
    } else {
      observer = this.adrollConfigurationService.update(this.adrollParams());
    }

    observer.subscribe(
      (response) => {
        this.activeModal.close({
          connecting: true,
          errored: false,
          data: response,
        });
      },
      (response) => {
        this.activeModal.close({
          connected: false,
          errored: true,
          data: response,
        });
      }
    );
  }

  cancel() {
    this.activeModal.dismiss();
  }

  private adrollParams() {
    return {
      data: {
        custom_checkout_url: this.adrollConfiguration.custom_checkout_url,
        instagram_account_id: this.adrollConfiguration.instagram_account_id,
        facebook_page_url: this.adrollConfiguration.facebook_page_url,
      },
    };
  }
}
