import { Base } from "@models/base";
import { SubTemplate } from "./sub_template";

export class EmailTemplate extends Base {
  id: string;
  storeId: number;
  name: string;
  thumbnailUrl =
    "https://s3.amazonaws.com/springbot-assets/images/email_campaigns/template_placeholder.png";
  mjmlContent: string;
  content: string;
  favorite: boolean;
  basic: boolean;
  type = "email_campaign";
  category: string;
  originCategory: string;
  subTemplates: {
    cartTemplate: SubTemplate;
    purchasedCartTemplate: SubTemplate;
    productRecommendationsTemplate: SubTemplate;
  };

  // These functions are here until we port the cart, purchased_cart, and
  // product_recommendation modules to Angular
  // TODO: remove these when we port to Angular
  get(key) {
    return this[this.snakeToCamel(key)];
  }

  set(key, value) {
    this[this.snakeToCamel(key)] = value;
  }

  // Because the editor is upgraded from angular.js and uses snake_case everywhere.
  snakeToCamel(str: string): string {
    return str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());
  }
}
