import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { StoreService } from "@app/shared/services/store.service";
import { UserService } from "@app/shared/services/user.service";
import { AuthTokenService } from "@app/auth/services/auth-token.service";

@Injectable({
  providedIn: "root",
})
export class UserGuard implements CanActivate {
  constructor(
    private storeService: StoreService,
    private userService: UserService,
    private router: Router,
    private authTokenService: AuthTokenService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.storeService.getSource().getValue()) {
      return of(true);
    }

    return new Observable((observer) => {
      this.userService.show("current", { include: ",,stores" }).subscribe((user) => {
        this.userService.setUser(user);
        this.storeService.getStore().subscribe((store) => {
          this.storeService.setStore(store);
          return observer.next(true);
        });
      }, (error) => {
        console.error("UserAutoLogout:", error);
        this.authTokenService.clear()
        let redirect = state.url !== "/" ? state.url : "/";
        this.router.navigateByUrl(`/login?redirectTo=${redirect}`);
        return observer.next(false);
      });
    });
  }
}
