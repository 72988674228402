import { Component } from "@angular/core";

import { GoogleAnalyticsConfigurationService } from "@app/shared/services/google_analytics_configuration.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "google-config-modal",
  styleUrls: ["./google_config_modal.component.scss"],
  templateUrl: "./google_config_modal.component.html",
})
export class GoogleConfigModalComponent {
  loadingData = true;
  done = false;
  errors: any;
  googleConfig: any;

  constructor(
    private activeModal: NgbActiveModal,
    private googleAnalyticsConfigurationService: GoogleAnalyticsConfigurationService
  ) {}

  ngOnInit() {
    this.getGoogleAnalyticsConfiguration();
  }

  getGoogleAnalyticsConfiguration() {
    this.googleAnalyticsConfigurationService.show().subscribe(
      (response) => {
        this.googleConfig = response.data.google_configuration;
        if (
          this.googleConfig &&
          this.googleConfig.account_name &&
          this.googleConfig.web_property_name &&
          this.googleConfig.profile_name
        ) {
          this.done = true;
        }
        this.loadingData = false;
      },
      (response) => {
        this.errors = response.errors;
      }
    );
  }

  finish() {
    this.activeModal.close({ connected: true, errored: false });
  }

  reset() {
    this.googleAnalyticsConfigurationService.restart().subscribe((response) => {
      this.activeModal.close({ connected: false, errored: false });
    });
  }

  next() {
    this.loadingData = true;
    this.googleAnalyticsConfigurationService
      .update(this.updateParams())
      .subscribe(
        (response) => {
          this.getGoogleAnalyticsConfiguration();
        },
        (errors) => {
          this.errors = errors;
        }
      );
  }

  cancel() {
    if (this.done) {
      this.activeModal.dismiss();
    } else {
      this.activeModal.close({ connected: true, errored: true });
    }
  }

  updateParams() {
    return {
      google_configuration: {
        default_account_id: this.googleConfig.default_account_id,
        default_profile_id: this.googleConfig.default_profile_id,
        default_web_property_id: this.googleConfig.default_web_property_id,
      },
    };
  }
}
