<div class="modal-body">
  <div>
    <p *ngFor="let alert of alerts">
      <ngb-alert [type]="alert.type">{{alert.message}}</ngb-alert>
    </p>
  </div>
  <h3 class="modal-title text-center">
    Product Filters
  </h3>

  <div class="row mt-3">
    <div class="col-sm-12">
      <label>What products to display?</label>
      <ng-select [multiple]="false" [(ngModel)]="selectedRecommenderType" class="select-custom"
                 placeholder="Select an option" [items]="recommenderTypes" bindValue="value" bindLabel="value"
                 [clearable]="false"
                 [hideSelected]="false" [searchable]="false" [virtualScroll]="false"
                 [loading]="!showRecommenderTypeDropdown">
        <ng-template ng-option-tmp let-item="item">
          <div ngbPopover="{{item.popoverText}}" container="body" triggers="mouseenter:mouseleave">{{item.value}}</div>
        </ng-template>
      </ng-select>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 mt-3 pt-4" style="font-size:12px;">
      Excluding categories or SKUs may alter the accuracy of the recommendations. Best practice is to limit exclusions
      to 1 or 2 categories/SKUs.
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-sm-12">
      <label>Exclude SKUs</label>
    </div>
    <div class="col-sm-12 d-flex flex-column align-content-stretch">
      <button [disabled]="excludedSkuProductsLoading"
              type="button"
              (click)="openProductModal()"
              class="btn btn-outline-primary mt-2">
        Add SKU
        <i [class]="!excludedSkuProductsLoading ? 'fa fa-plus-circle' : 'fa fa-cog fa-spin'"></i>
      </button>
    </div>
    <div class="col-sm-12">
      <div *ngFor="let sku of excludedSkus">
        <div class='cursor-pointer' (click)="removeExcludedSku(sku)">
          <span>{{sku}} <i class="fa fa-minus-circle" style="color:red;"></i></span>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3 mb-2">
    <div class="col-sm-12">
      <label>Exclude Categories</label>
    </div>
    <div class="col-sm-12">
      <ng-select [multiple]="true" [(ngModel)]="excludedCategories" class="select-custom"
                 placeholder="Select an option" bindLabel="catName"
                 [items]="categories" [addTag]="false"
                 [clearSearchOnAdd]="true" [closeOnSelect]="false"
                 [hideSelected]="true" [searchable]="true" [virtualScroll]="true" [loading]="!categories">
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value" *ngFor="let item of items | slice:0:2">
            <span class="ng-value-label">{{item.catName}}</span>
          </div>
          <div class="ng-value" *ngIf="items.length > 2">
            <span class="ng-value-label">{{items.length - 2}} more...</span>
          </div>
        </ng-template>
      </ng-select>
    </div>
    <div class="col-sm-12">
      <div *ngFor="let category of excludedCategories">
        <div class='cursor-pointer' (click)="removeExcludedCategory(category)">
          <span>{{category.catName}} <i class="fa fa-minus-circle" style="color:red;"></i></span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer bg-secondary">
  <div class="container-fluid">
    <div class="row">
      <div class="col-6 d-flex justify-content-center">
        <button (click)="cancel()" class="btn btn-outline-primary px-5">
          Cancel
        </button>
      </div>
      <div class="col-6 d-flex justify-content-center">
        <button (click)="confirm()" class="btn btn-primary px-5">
          Accept
        </button>
      </div>
    </div>
  </div>
</div>
