import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { AuthService } from "@app/auth/services/auth.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "sb-email-verification-alert",
  styleUrls: ["./email_verification_alert.component.scss"],
  templateUrl: "./email_verification_alert.component.html",
})
export class EmailVerificationAlertComponent {
  constructor(
      private authService: AuthService,
      private modalService: NgbModal,
  ) {}
  errored = false;
  loading = false;

  @ViewChild("verificationEmailSentModal") verificationEmailSentModal;

  @Output()
  close = new EventEmitter();

  @Input()
  emailAddress: string;

  onClose() {
    this.errored = false;
    this.loading = false;
    this.close.emit();
  }

  onResendVerification() {
    this.errored = false;
    this.loading = true;
    this.authService
      .resendVerificationEmail()
      .subscribe((response) => {
        this.loading = false;
        if (response.ok) {
          this.modalService.open(this.verificationEmailSentModal, {
            size: "lg",
            backdrop: "static",
          });
          this.close.emit();
        } else {
          this.errored = true;
          console.error(response.error?.error || "An unexpected error has occurred.");
        }
      });
  }
}
