import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule, ErrorHandler } from "@angular/core";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';

import { KeyFormatInterceptor } from "@app/shared/interceptors/key_format_interceptor";
import { FormStatusService } from "@app/store_setup/services/form_status.service";
import { AppComponent } from "./app.component";

import { AppRoutes } from "./app.routes";
import { NavigationHeaderComponent } from "./shared/components/navigation_header/navigation_header.component";

// https://github.com/JsDaddy/ngx-mask
import { IConfig, NgxMaskModule } from "ngx-mask";
import { AuthInterceptor } from "@app/auth/services/auth.interceptor";
import { AuthTokenService } from "@app/auth/services/auth-token.service";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from "@environments/environment";
import { SharedModule } from "@sharedModule";

export const ngxMaskModuleOptions: Partial<IConfig> | (() => Partial<IConfig>) = {};

if (environment.bugsnagApiKey) {
  Bugsnag.start({
    apiKey: environment.bugsnagApiKey,
    releaseStage: environment.production ? 'production' : 'staging',
    enabledReleaseStages: [ 'production', 'staging' ],
    appVersion: environment.appVersion
  });
}

export function errorHandlerFactory() {
  if (!!environment.bugsnagApiKey) {
    return new BugsnagErrorHandler()
  } else {
    return new ErrorHandler();
  }
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, NavigationHeaderComponent],
  imports: [
    NgbModule,
    AppRoutes,
    BrowserModule,
    HttpClientModule,
    SharedModule,
    NgxMaskModule.forRoot(ngxMaskModuleOptions),
    BrowserAnimationsModule,
  ],
  providers: [
    FormStatusService,
    Title,
    { provide: HTTP_INTERCEPTORS, useClass: KeyFormatInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, deps: [AuthTokenService] },
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
  ],
})
export class AppModule { }
