import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "sb-page-not-found",
  styleUrls: ["./not_found.scss"],
  templateUrl: "./not_found.html",
})
export class NotFound {
  constructor(private titleService: Title) {}

  ngOnInit() {
    this.titleService.setTitle(
      "(404) Something's not quite right but the Springbots are on it"
    );
  }
}
