import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ContentBlock } from "@models/content_block";
import { Response } from "@models/response";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { environment } from "@environments/environment";

@Injectable()
export class ContentBlockService {
  private contentBlock: ContentBlock = new ContentBlock();
  private base = `${environment.apiUrl}/v2/content_blocks`;
  constructor(
    private httpClient: HttpClient,
    private deserializeService: DeserializeService,
  ) {}

  index(params?: any): Observable<Response<ContentBlock[]>> {
    return this.httpClient.get<ContentBlock[]>(this.base, { params }).pipe(
      mergeMap(async (contentBlocksResponse: any) => {
        const contentBlocks = this.deserializeService.deserialize(contentBlocksResponse);
        return new Response(contentBlocks, contentBlocksResponse.meta);
      }),
    );
  }

  show(id: string): Observable<ContentBlock> {
    return this.httpClient.get<ContentBlock>(`${this.base}/${id}`)
      .pipe(mergeMap(async (response) => {
        const contentBlockResponse = this.deserializeService.deserialize(response);
        return Object.assign(this.contentBlock, contentBlockResponse);
      }));
  }

  create(block: ContentBlock): Observable<ContentBlock> {
    return this.httpClient.post<ContentBlock>(
      this.base,
      block,
    ).pipe(mergeMap(async (response) => this.deserializeService.deserialize(response)));
  }

  update(id: string, params: any): Observable<ContentBlock> {
    return this.httpClient.patch<ContentBlock>(`${this.base}/${id}`, params).pipe(mergeMap(async (response) => this.deserializeService.deserialize(response)));
  }

  // doesn't delete, just disables.
  delete(blockId: string): Observable<ContentBlock> {
    const params = {
      disabled: true,
    };
    return this.httpClient.put<ContentBlock>(`${this.base}/${blockId}`, params)
      .pipe(mergeMap(async (response) => this.deserializeService.deserialize(response)));
  }
}
