import { EventEmitter, Output, Component, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "sb-stepper",
  styleUrls: ["./stepper.component.scss"],
  templateUrl: "./stepper.component.html",
})
export class StepperComponent {
  @Input()
  title: string;

  @Input()
  showPercentage: boolean;

  @Input()
  hideHeader: boolean = false;

  @Input()
  subtitles: [];

  @Input()
  clearThreshold: number;

  @Input()
  steps: any[];
  // example:
  // {
  //   text: "Connect your cart",
  //   icon: ['fas', 'link'],
  //   completed: false,
  //   link: "/store/integrations"
  //   weight: 20
  // },

  @Output()
  onClickStep = new EventEmitter();

  @Output()
  hideStepper = new EventEmitter();

  percentage: number = 0;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.getPercentage();
  }

  getPercentage(): void {
    if (this.steps.length > 0) {
      const totalStepWeights = this.steps.map((step) => step.weight).reduce((sum, p) => sum + p);
      const totalComplete = this.steps.map((step) => step.completed ? step.weight : 0).reduce((sum, p) => sum + p);
      this.percentage = Math.round((totalComplete / totalStepWeights) * 100);
    }
  }

  handleClickStep(step) {
    if (step.link && step.redirection) {
      this.router.navigateByUrl(step.link);
    } else {
      this.onClickStep.emit(step);
    }
  }

  handleClear() {
    this.hideStepper.emit();
  }
}
