import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UserAuthsService {
  constructor(private httpClient: HttpClient) {}

  index(params?: any) {
    return this.httpClient.get<any>("/integrations", { params });
  }
}
