import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SocialAccount } from "@models/social/social_account";
import { SocialBlast } from "@models/social/social_blast";
import { SocialBlastParams } from "@models/social/social_blast_params";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class SocialBlastService {
  constructor(
    private http: HttpClient,
    private deserializeService: DeserializeService,
  ) {}

  index(params?: any): Observable<SocialBlast[]> {
    return this.http.get(`${environment.apiUrl}/v2/social_hub/social_blasts`, { params }).pipe(
      mergeMap(async (response: any) => {
        const socialBlasts = this.deserializeService.deserialize(response);
        const socialBlastAttachments = this.deserializeService.deserialize(response.included);
        socialBlasts.forEach((socialBlast) => {
          const attachments = []
          socialBlast.socialBlastAttachments.forEach((attachment) => {
            attachments.push(socialBlastAttachments.find((a) => a.id.toString() === attachment.id.toString()));
          });
          socialBlast.socialBlastAttachments = attachments;
        });
        return socialBlasts;
      })
    );
  }

  show(id: string, params?: any): Observable<SocialBlast> {
    return this.http.get(`${environment.apiUrl}/v2/social_hub/social_blasts/${id}`, { params })
      .pipe(mergeMap(async (response) => this.deserializeService
        .deserialize(response)));
  }

  update(id: string, params: Partial<SocialBlastParams>): Observable<SocialBlast> {
    return this.http.put(`${environment.apiUrl}/v2/social_hub/social_blasts/${id}`, params)
      .pipe(mergeMap(async (response) => this.deserializeService
        .deserialize(response)));
  }

  destroy(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/v2/social_hub/social_blasts/${id}`);
  }

  post(params: Partial<SocialBlastParams>): Observable<SocialBlast> {
    return this.http.post(`${environment.apiUrl}/v2/social_hub/social_blasts`, params)
      .pipe(mergeMap(async (response) => this.deserializeService
        .deserialize(response)));
  }

  getProductImageSize(productId: string, imageUrl: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/v2/products/${productId}/product_image_size`, {
      params: { image_url: imageUrl },
    });
  }

  // returns deep clone of SocialBlast set to post *only* to the socialAccount
  // socialAccount == (facebook page, instagram account or twitter user)
  clonePostingToAccount(
    sourceSocialBlast,
    socialAccount: SocialAccount,
    stripIds = true,
  ): SocialBlast {
    const blastProviderUpdate: Partial<SocialBlast> = {
      useProviderFacebook: false,
      useProviderInstagram: false,
      useProviderTwitter: false,
      facebookPageIds: [],
      instagramAccountIds: [],
      twitterUserIds: [],
    };

    switch (socialAccount.platform) {
      case "facebook":
        blastProviderUpdate.facebookPageIds = [socialAccount.id];
        blastProviderUpdate.useProviderFacebook = true;
        break;
      case "twitter":
        blastProviderUpdate.twitterUserIds = [socialAccount.id];
        blastProviderUpdate.useProviderTwitter = true;
        break;
      case "instagram":
        blastProviderUpdate.instagramAccountIds = [socialAccount.id];
        blastProviderUpdate.useProviderInstagram = true;
        break;
      default:
        // do nothing
    }
    if (stripIds) {
      const { id: sourceId, ...clone } = this.cloneDeep(sourceSocialBlast);
      clone.socialBlastAttachments = clone.socialBlastAttachments.map(({ id, ...rest }) => rest);
      return { ...clone, ...blastProviderUpdate };
    }
    return { ...this.cloneDeep(sourceSocialBlast), ...blastProviderUpdate };
  }

  cloneDeep(obj) {
    if(typeof obj !== 'object' || obj === null) {
      return obj;
    }

    if(obj instanceof Date) {
      return new Date(obj.getTime());
    }

    if(obj instanceof Array) {
      return obj.reduce((arr, item, i) => {
        arr[i] = this.cloneDeep(item);
        return arr;
      }, []);
    }

    if(obj instanceof Object) {
      return Object.keys(obj).reduce((newObj, key) => {
        newObj[key] = this.cloneDeep(obj[key]);
        return newObj;
      }, {})
    }
  }
}
