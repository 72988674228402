import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'content-selector-carousel',
  templateUrl: './content_selector_carousel.component.html',
  styleUrls: ['./content_selector_carousel.component.scss'],
})
export class ContentSelectorCarouselComponent {

  @Input()
  htmlContents: [];

  @Output()
  selectedContent = new EventEmitter();

  selectContent(e) {
    const getIndex = document.getElementsByClassName("carousel-item active")[0].querySelector("span.d-none").innerHTML;
    this.selectedContent.emit(parseInt(getIndex, 10));
  }
}
