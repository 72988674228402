import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CsvService {
  // TODO: Do this on the backend. This should be async and non-thread blocking.
  downloadCsv<T>(models: T[], name = "Download") {
    if (models.length === 0) {
      return;
    }

    const headers = Object.keys(models[0]).filter((header) => header !== "id");

    // title case
    const titleCaseHeaders = headers.map((header) => this.titleCase(header));

    let membershipsCsv = `${titleCaseHeaders.join(",")}\n`;

    for (const model of models) {
      for (const header of headers) {
        membershipsCsv += `${this.stripHtml(
          `${model[header] || ""}`.replace(/"/g, '""')
        )},`;
      }
      membershipsCsv += "\n";
    }

    const csvData = new Blob([membershipsCsv], { type: "text/csv" });
    const downloadLink = document.createElement("a");

    downloadLink.download = `${name} ${Date.now()}.csv`;
    downloadLink.href = window.URL.createObjectURL(csvData);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.remove();
  }

  /**
   * @param headers Array that contins the headers for the CSV
   * @param values Array of arrays for the values of the CSV file
   * @param name Name of the CSV file. Date string will be appended to it
   */
  downloadCsvCustom(headers: string[], values: string[][], name = "Download") {
    let csvString = `${headers.join(",")}\n`;
    values.forEach((row) => {
      csvString += `${row.join(",")}\n`;
    });

    const csvData = new Blob([csvString], { type: "text/csv" });
    const downloadLink = document.createElement("a");

    downloadLink.download = `${name} ${Date.now()}.csv`;
    downloadLink.href = window.URL.createObjectURL(csvData);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.remove();
  }

  private titleCase(header: string) {
    let out = header.replace(/^\s*/, ""); // strip leading spaces
    out = out.replace(/^[a-z]|[^\s][A-Z]/g, (word, offset) => {
      if (offset === 0) {
        return word.toUpperCase();
      }
      return `${word.substr(0, 1)} ${word.substr(1).toUpperCase()}`;
    });
    // adding double quotes will allow to keep the header in the same column.
    // if a header has a white space, it will break the header in an extra column. Ex: "Created at"
    return `"${out}"`;
  }

  private stripHtml(html: string) {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    const newText = tmp.textContent || tmp.innerText || "";
    return `"${newText.replace(/\n/g, "")}"`;
  }
}
