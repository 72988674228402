<div class="card text-right" style="max-width: 538px;">
  <div class="card-header">
    <fa-icon [icon]="['fas', 'times']" class="modal-close-icon p-2 float-right" (click)="close()"></fa-icon>
  </div>
  <iframe src="https://go.springbot.com/l/61912/2021-09-27/3sz1b2v" width="100%" height="400" type="text/html"
          allowTransparency="true" style="border:0" *ngIf="!department || department === 'sales'">
  </iframe>
  <iframe src="https://go.springbot.com/l/61912/2021-11-18/3t3m1g8" width="100%" height="400" type="text/html"
          allowTransparency="true" style="border:0" *ngIf="department === 'coaching'">
  </iframe>
</div>
