import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Response } from "@models/response";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { mergeMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { environment } from "@environments/environment";
import { StoreNotification } from "@models/store_notification";

@Injectable({
  providedIn: "root",
})
export class StoreNotificationsService {
  constructor(
    private httpClient: HttpClient,
    private http: HttpClient,
    private deserializeService: DeserializeService,
  ) {}

  private base = `${environment.apiUrl}/v2/store_notifications`;

  index(params?: any): Observable<Response<StoreNotification[]>> {
    return this.http.get<Response<StoreNotification[]>>(this.base, {params}).pipe(
      mergeMap(async (response: any) => {
        const notifications = this.deserializeService.deserialize(response);
        return new Response(notifications, response.meta);
      }),
    );
  }

  dismiss(notificationId: string): Observable<Response<any>> {
    return this.http.get<Response<any>>(`${this.base}/${notificationId}/dismiss`, {});
  }
}
