<div class="modal-icon-wrapper">
  <img class="modal-icon" src="https://springbot.s3.amazonaws.com/integrations/facebook.png">
</div>
<h4 class="modal-header-text text-center">
  Facebook Configuration
</h4>

<div *ngIf="loadingStore || loadingData" class="text-center">
  <div class="spacing"></div>
  <fa-icon [icon]="['fas', 'cog']" [spin]="true" size="2x" class="green-color"></fa-icon>
</div>

<div class="row">
  <div class="col-md-12" *ngIf="error" >
    <div class="alert alert-danger text-center">
      <fa-icon [icon]="['fas','exclamation-circle']" size="2x"></fa-icon>
      <p>
        We're sorry but something went wrong.
      </p>
      <p>
        The springbots are aware of the issue and are working to resolve it.
      </p>
    </div>
  </div>

  <div *ngIf="errors && errors.length > 0" class="col-md-12">
    <div class="alert alert-danger text-center" *ngFor="let error of errors; let i = index">
      <fa-icon [icon]="['fas','exclamation-circle']" size="2x"></fa-icon>
      <p>
        {{ error.message }}
      </p>
    </div>
  </div>
</div>

<p *ngIf="!loadingData && !loadingStore && !error && !!auth && auth.working && neededAuthStrings.length > 0"
   class="alert alert-info"
   style="text-align:center;">
  {{ "Please reconnect to Facebook to enable " + neededAuthStrings.join(" and ") }}
</p>

<p *ngIf="!loadingData && !loadingStore && !error && !authNeeded && neededSelectionStrings.length > 0"
   class="alert alert-info"
   style="text-align:center;">
  {{ "Please select a Facebook " + neededSelectionStrings.join(" and ") }}
</p>

<div *ngIf="!!auth && auth.enabled && auth.working && !loadingData && !loadingStore && !error"
     class="facebook-modal-form-wrapper">
  <div class="row">
    <div class="col-md-4">
      <img [src]="auth.pictureUrl" width="50">
    </div>
    <div class="col-md-8">
      <div class="text-right">
        <div *ngIf="auth.profile.name" class="text-right">
          <label class="form-label">Name</label><br>
          {{ auth.profile.name }}<br>
        </div>
        <div class="text-right">
          <div>
            <label class="form-label">Default Page</label><br>
            <select name="fbPage" class="form-control" *ngIf="!auth.page"
                    [(ngModel)]="selectedPageId"
                    (change)="updatePage()"
                    ngDefaultControl>
              <option disabled selected value="">
                Select an page
              </option>
              <option *ngFor="let page of auth.pages"
                      [ngValue]="page.id">
                {{ page.name }}
              </option>
            </select>
            <span *ngIf="auth.page">
              {{ facebookPage.name }}
              <i (click)="resetFacebookPage()" class="fas fa-undo-alt fb-reset-button"></i>
              <br>
            </span>
          </div>
          <div *ngIf="store.package && store.package.facebook_campaign_services">
            <label class="form-label">Ad Account</label>
            <select name="fbAdAccount" class="form-control" *ngIf="!auth.adAccount"
                    [(ngModel)]="selectedAdAccountId"
                    (change)="updateAdAccount()"
                    ngDefaultControl>
              <option disabled selected value="">
                Select an Ad Account
              </option>
              <option *ngFor="let account of auth.adAccounts"
                      [ngValue]="account.id">
                {{ account.name }}
              </option>
            </select>
            <i *ngIf="loadingAdAccount" class="fas fa-cog fa-spin text-primary"></i><br *ngIf="loadingAdAccount">
            <span *ngIf="auth.adAccount">{{ facebookAdAccount.name }}
              <i (click)="resetAdAccount()" class="fas fa-undo-alt fb-reset-button"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="!!auth && auth.enabled && !auth.working && !loadingData && !loadingStore && !error">
  <div class="col-md-12">
    <p class="alert alert-info">
      Hey there! We're having trouble connecting to your Facebook Account right now.
      This could be caused by the Facebook API being down, your account's password
      changing, or you revoked Springbot's access to your Facebook Account. If you've
      changed your password or revoked Springbot's access, you'll need to reauthenticate
      with Facebook. Click the button below to start that process.
    </p>
  </div>
</div>

<div class="modal-actions">
  <button (click)="cancel()" class="btn btn-outline-primary modal-action">Cancel</button>

  <button (click)="clearAuth()"
          class="btn btn-danger modal-action">
    Disconnect
  </button>
  <button (click)="save()" *ngIf="!errorState" class="btn btn-success modal-action">Finish</button>
</div>
