import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { SignUpForm } from "@models/sign_up_form";
import { SubscriberAttributeSet } from "@models/sign_up_forms/subscriber_attribute_set";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";

import defaultStyles from "@app/content_editor/components/web_content_editor/defaults/inner_styles.scss";

import gdprPopup from "../components/content_design_step/defaults/gdpr_popup.html";
import defaultPopup from "../components/content_design_step/defaults/popup.html";
import declassify from "declassify";
import * as juice from "juice";

import { StoreService } from "@app/shared/services/store.service";
import { StoreBrandingService } from "@app/shared/services/store_branding.service";
import { Response } from "@models/response";
import { SignUpFormTemplate } from "@models/sign_up_form_template";
import { Store } from "@models/store";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class SignUpFormsService {
  constructor(
    private httpClient: HttpClient,
    private storeService: StoreService,
    private storeBrandingService: StoreBrandingService,
    private deserializeService: DeserializeService,
  ) { }

  index(params?: any): Observable<SignUpForm[]> {
    return this.httpClient.get<SignUpForm[]>(`${environment.apiUrl}/v2/sign_up_forms`, { params }).pipe(
      mergeMap(async (response) => this.deserializeService.deserialize(response)),
    );
  }

  // TODO: Move defaults to top level creation.
  create(signUpForm: SignUpForm, store: Store): Observable<SignUpForm> {
    const popupContent = store.canUseDoNotProcessDetails ? gdprPopup : defaultPopup;
    const brandingCSS = this.storeBrandingService.toCSS(store, store.branding);

    signUpForm.content = signUpForm.content
      || this.prepareContent(popupContent, defaultStyles + brandingCSS);
    signUpForm.successContent = signUpForm.successContent || this.prepareContent(
      this.defaultSuccess(), defaultStyles + brandingCSS,
    );

    return this.httpClient.post<SignUpForm>(`${environment.apiUrl}/v2/sign_up_forms`, signUpForm).pipe(mergeMap(async (response) => this.deserializeService.deserialize(response)));
  }

  destroy(signUpForm: SignUpForm) {
    return this.httpClient.delete<SignUpForm>(`${environment.apiUrl}/v2/sign_up_forms/${signUpForm.id}`);
  }

  show(signUpFormId: number | string, params?: any): Observable<SignUpForm> {
    return this.httpClient.get<any>(`${environment.apiUrl}/v2/sign_up_forms/${signUpFormId}`, { params }).pipe(
      mergeMap(async (response) => this.deserializeService.deserialize(response)),
    );
  }

  getTemplates(params?: any): Observable<Response<SignUpFormTemplate[]>> {
    return this.httpClient.get(`${environment.apiUrl}/v2/sign_up_form_templates`, { params }).pipe(
      mergeMap(async (response: any) => {
        const templates = this.deserializeService.deserialize(response);
        return new Response(templates, response.meta);
      }),
    );
  }

  createTemplate(template: Partial<SignUpFormTemplate>): Observable<Response<SignUpFormTemplate>> {
    return this.httpClient.post(`${environment.apiUrl}/v2/sign_up_form_templates`, template).pipe(mergeMap(async (response: any) => {
      const templates = this.deserializeService.deserialize(response);
      return new Response(templates, response.meta);
    }));
  }

  destroyTemplate(template: SignUpFormTemplate) {
    return this.httpClient.delete(`${environment.apiUrl}/v2/sign_up_form_templates/${template.id}`);
  }

  update(signUpForm: SignUpForm): Observable<SignUpForm> {
    return this.httpClient.put<SignUpForm>(`${environment.apiUrl}/v2/sign_up_forms/${signUpForm.id}`, signUpForm).pipe(
      mergeMap(async (response) => this.deserializeService.deserialize(response)),
    );
  }

  defaultSuccess(): string {
    return `
      <div class="springbot-overlay springbot-background">
        <div class="cleanslate springbot-popup springbot-success branding-bodyColor-background branding-textColor-color branding-font-family">
          <h1 class="branding-textColor-color">Thanks!</h1>
        </div>
      </div>
    `
  }

  prepareContent(content: string, styleContent: string = defaultStyles) {
    // Remove the show class so it defaults
    const inlined = juice(content, {
      extraCss: styleContent,
    });

    return declassify.process(inlined, {
      ignore: [/springbot-/],
    });
  }

  saveSubscriberAttributeSet(signUpForm: SignUpForm) {
    const wrapper = document.createElement("div");
    wrapper.innerHTML = signUpForm.content;

    const subscriberAttributeSet = new SubscriberAttributeSet();
    subscriberAttributeSet.attributeItems = [];

    // Get a numerical representation of the popup id for unique sets.
    subscriberAttributeSet.attributeId = parseInt(signUpForm.id, 16) / 10e11;

    // Generate attribute items from all named inputs
    wrapper.querySelectorAll("input[name]").forEach((element) => {
      const name = element.getAttribute("name");
      if (!name || ["email", "first name", "last name"].includes(name.toLowerCase())) {
        return;
      }

      let type = element.getAttribute("type");
      if (type === "number") {
        type = "number";
      } else if (type === "date") {
        type = "day";
      } else if (type === "checkbox") {
        type = "checkbox";
      } else {
        type = "text";
      }

      subscriberAttributeSet.attributeItems.push({
        attributeCode: name.trim().toLowerCase().replace(/\s/g, "_"),
        dataType: type,
        label: name,
        options: [],
      });
    });

    // Generate attribute items from all
    wrapper.querySelectorAll("select").forEach((element) => {
      const name = element.getAttribute("name");
      if (!name) {
        return;
      }

      const options = [];
      element.querySelectorAll("option").forEach((option) => {
        options.push(option.value);
      });

      subscriberAttributeSet.attributeItems.push({
        attributeCode: name.toLowerCase().replace(/\s/g, "_"),
        dataType: "select",
        label: name,
        options,
      });
    });

    return this.httpClient.post<SubscriberAttributeSet>(`${environment.apiUrl}/v2/subscriber_attribute_set`,
      subscriberAttributeSet).toPromise();
  }

  // TODO: Do this in API interceptor.
  private serializeArrayParameters(signUpForm: SignUpForm) {
    let params = new HttpParams();
    for (const key of Object.keys(signUpForm)) {
      if (signUpForm[key] instanceof Array) {
        signUpForm[key].forEach((item) => {
          params = params.append(`${key.toString()}[]`, item);
        });
      } else {
        params = params.append(key.toString(), signUpForm[key]);
      }
    }
    return params;
  }
}
