import { AppPhoneNumber } from "@models/sms/app_phone_number";

export class SMSKeyword {
  id: number;
  actionId: number;
  storeId: number;
  keyword: string;
  description: string;
  frequency = 5;
  wizardStep: string;
  couponCampaignId = "";
  listId?: string;
  welcomeResponse = "Thanks for joining [[store name]]!";
  appPhoneNumber: AppPhoneNumber;
  termsUrl?: string;
  termsToken: string;
  privacyUrl = "";
  contactMethod?: string;
  storeName?: string;
  recipientCount?: number;
  unsubCount?: number;
  deleted?: boolean;
}
