<div class="sb-modal-content">
  <div class="modal-icon-wrapper">
    <img class="modal-icon" src="https://springbot.s3.amazonaws.com/integrations/recaptcha.svg">
  </div>
  <h4 class="modal-header-text text-center">
    Recaptcha Configuration
  </h4>

  <div *ngIf="loadingStore" class="text-center">
    <div class="spacing"></div>
    <fa-icon [icon]="['fas', 'cog']" size="2x" [spin]="true" class="green-color"></fa-icon>
  </div>

  <div *ngIf="!loadingStore">
    <div class="col-md-12">
      <div class="form-group">
        <label>
          Google Secret Key (required)
        </label>
        <input type="text"
               name="google-secret-key"
               class="form-control"
               [(ngModel)]="store.googleSecretKey">
      </div>
      <div class="form-group">
        <label>
          Google Site Key (required)
        </label>
        <input type="text"
               name="google-site-key"
               class="form-control"
               [(ngModel)]="store.googleSiteKey">
      </div>
    </div>

    <div class="col-md-12">
      <ol>
        <li><small>Go to the <a target="_blank" href="https://www.google.com/recaptcha/admin#list">Google reCAPTCHA site</a>.</small></li>
        <li><small>Enter a label, and choose "reCAPTCHA v2".</small></li>
        <li><small>Select "I'm not a robot" checkbox.</small></li>
        <li><small>Enter the domain of your store website on the first line of the domain's text input.</small></li>
        <li><small>Add "app.springbot.com" on the second line.</small></li>
        <li><small>Click register.</small></li>
        <li><small>Copy and paste each key into their respective inputs.</small></li>
      </ol>
    </div>

  </div>
</div>

<div class="modal-actions">
  <button class="btn btn-secondary modal-action" (click)="cancel()">Cancel</button>
  <button class="btn btn-success modal-action" (click)="save()">Save</button>
</div>
