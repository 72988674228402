import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Response } from "@models/response";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { environment } from "@environments/environment";
import { GeneratedText } from "@models/generated_text";

@Injectable({
  providedIn: "root",
})

export class TextGeneratorService {
  private base = `${environment.apiUrl}/v2/text_generator`;
  private httpOptions = {
    headers: new HttpHeaders({
      Accept: "text/csv; charset=utf-8, application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    }),
  };

  constructor(
    private http: HttpClient,
    private deserializeService: DeserializeService,
  ) {
  }

  index(params: any): Observable<Response<GeneratedText[]>> {
    const path = this.base;
    return this.http.get<GeneratedText[]>(path, {}).pipe(mergeMap(async (response: any) => {
      const generatedTexts = this.deserializeService.deserialize(response);

      return new Response(generatedTexts, response.meta);
    }));
  }

  create(generatedText: GeneratedText): Observable<GeneratedText> {
    const path = this.base;
    return this.http.post<GeneratedText>(path, generatedText).pipe(
      mergeMap(async (response) => this.deserializeService.deserialize(response)),
    );
  }

  // Use this endpoint to update fields and regenerate the MJML.
  update(generatedText: GeneratedText): Observable<GeneratedText> {
    const path = `${this.base}/${generatedText.id}`;
    return this.http.put<GeneratedText>(path, generatedText, this.httpOptions).pipe(
      mergeMap(async (response) => this.deserializeService.deserialize(response)),
    );
  }

  // Poll this endpoint until the status is 'finished'.
  show(id: string): Observable<any> {
    const path = `${this.base}/${id}`;
    return this.http.get<GeneratedText>(path, {}).pipe(
      mergeMap(async (response) => this.deserializeService.deserialize(response)),
    );
  }

  // Poll this endpoint until the status is 'finished'.
  limit(): Observable<any> {
    const path = `${this.base}/limit`;
    return this.http.get<any>(path, {}).pipe(
      mergeMap(async (response) => this.deserializeService.deserialize(response)),
    );
  }
}
