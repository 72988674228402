import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UniversalErrorService {
  constructor() {}

  extractErrorMessages(resp: any): string[] {
    let errorMessages = [];
    const defaultErrorMessage = "Oops! Something went wrong!";
    if (resp.status == 500) {
      console.error("Unexpected error handled", resp);
      errorMessages.push(defaultErrorMessage);
    } else if (resp.error?.errors && resp.error?.errors[0]?.type ) {
      console.log(resp.error?.errors[0]);
      errorMessages.push(resp.error.errors[0]?.type);
    } else if (!!resp.error?.errors) {
      console.log(resp.error?.errors);
      errorMessages = errorMessages.concat(this.extractErrorMessages(resp.error?.errors));
    } else if (!resp && errorMessages.indexOf(defaultErrorMessage) < 0) {
      console.error("Undefined error handled", resp);
      errorMessages.push(defaultErrorMessage);
    } else if (typeof resp == 'object' && typeof resp.length == 'number') {
      resp.forEach((err) => {
        errorMessages = errorMessages.concat(this.extractErrorMessages(err));
      });
    } else if (resp.errors && typeof resp.errors == 'object' && typeof resp.errors.length == 'number') {
      resp.errors.forEach((err) => {
        errorMessages = errorMessages.concat(this.extractErrorMessages(err));
      });
    } else if (resp.error && typeof resp.error == 'object' && typeof resp.error.length == 'number') {
      resp.error.forEach((err) => {
        errorMessages = errorMessages.concat(this.extractErrorMessages(err));
      });
    } else if (typeof resp == 'string') {
      console.log(resp);
      errorMessages.push(resp);
    } else if (typeof resp.error == 'string') {
      errorMessages.push(resp.error);
    } else if (typeof resp.detail == 'string') {
      errorMessages.push(resp.detail);
    } else if (typeof resp.statusText == 'string') {
      console.log(resp.statusText);
      errorMessages.push(defaultErrorMessage);
    } else {
      console.error("Unmatched error response:", resp);
    }
    return errorMessages;
  }
}
