<ng-container>
  <div class="modal-body text-center">
    <img class="pb-3" src="https://s3.amazonaws.com/springbot-assets/images/Alert_icon_red2.svg" width="50">
    <p class="font-weight-bold pb-0 mb-1" style="font-size: 18px;">Your Springbot Payment Has Been Declined</p>
    <p>
      Your most recent payment of {{ (creditCardFailedAmount / 100) | currency:(currencyCode || 'USD'):'symbol':'1.2-2' }}
      was declined. Please update your billing information.
    </p>
  </div>
  <div class="modal-footer bg-secondary d-flex" >
    <button class="btn btn-primary btn-md" style="font-size: 16px;" (click)="goToBilling()">Update Billing</button>
  </div>
</ng-container>
