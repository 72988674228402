<div class="image-upload" (drop)="onDropFile($event)" (dragover)="allowDrop($event)"
  multiple="1" selectable="1" accept="image/*">
  <ng-container *ngIf="displayUploadedImage; else simpleUpload">
    <div class="row w-100" *ngIf="!uploading" (click)="uploading = true">
      <div>
        <div class="col-md-8 col-sm-12">
          <img class="image-upload__image" [src]="image">
        </div>
        <div class="image-upload__icon col-md-4 col-sm-12 d-flex flex-column justify-content-center align-items-center">
          <fa-icon [style.fontSize]="iconSize" [icon]="['fas', 'upload']"></fa-icon>
          <div [style.fontSize]="fontSize">{{prompt}}</div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="uploading">
      <label class="flex-column text-center">
        <fa-icon [style.fontSize]="iconSize" [icon]="['fas', 'upload']"></fa-icon><br>
        <span [style.fontSize]="fontSize">Drag a background image here to upload or click to browse for it.</span>
        <input class="d-none" type="file" accept="{{accept}}" (change)="onSelectFiles($event)">
      </label>
    </div>
  </ng-container>
</div>
<div [style.fontSize]="fontSize" class="image-upload__helper-text" *ngIf="helperText">
  <p>{{ helperText }}</p>
</div>

<ng-template #simpleUpload>
  <div class="d-flex flex-column justify-content-center align-items-center cursor-pointer">
    <label class="flex-column text-center cursor-pointer">
      <fa-icon [icon]="['fas', 'upload']" [style.fontSize]="iconSize" class="cursor-pointer"></fa-icon><br>
      <span [style.fontSize]="fontSize">{{prompt}}</span>
      <input class="d-none cursor-pointer" accept="{{accept}}" type="file" (change)="onSelectFiles($event)">
    </label>
  </div>
</ng-template>
