<div class="sb-modal-container">
  <div class="sb-modal-info">
    <div class="text-center">
      <img *ngIf="!useFontawesomeIcon" [src]="icon" class="sb-modal-icon">
      <div class="py-2"><fa-icon [class]="icon"></fa-icon></div>
    </div>
    <div class="sb-modal-body-container text-center">
      <h4 class="sb-modal-title" id="modal-basic-title" *ngIf="attributes?.title">
        {{attributes?.title}}
      </h4>
      <div class="sb-modal-content">
        <span [innerHTML]="attributes?.subtext"></span>
        <div *ngIf="attributes?.listText.length === 2">
          <li *ngIf="attributes.listText[0].text.length > 0"
              class="sb-modal-text-align-left list-margin-top">
            {{attributes.listText[0].text}}
          </li>
          <li *ngIf="attributes.listText[1].text.length > 0"
              class="sb-modal-text-align-left">
            {{attributes.listText[1].text}}
          </li>
        </div>
        <div *ngIf="attributes?.listText.length === 1">
          <li *ngIf="attributes.listText[0].text.length > 0"
              class="sb-modal-text-align-left list-margin-top">
            {{attributes.listText[0].text}}
          </li>
        </div>
      </div>

      <label class="custom-checkbox sb-modal-text-align-right checkbox-bottom-margin"
           *ngIf="checkboxNeeded">
        <form novalidate
              name="checkboxForm" class="checkbox-form-container">
          <div class="custom-control custom-checkbox">
              <input class="custom-control-input" name="checkbox" id="checkbox" type="checkbox" [(ngModel)]="checkboxChecked" required>
              <label class="cursor-pointer" for="checkbox">{{ attributes.checkboxText }}</label>
          </div>
        </form>
      </label>

      <div class="checkbox-bottom-margin"
           *ngIf="dropdownNeeded">
        <div class="dropdown-menu-container" ngbDropdown>
          <label ngbDropdownToggle>
            <div class="placeholder"
                 (click)="toggleDropdown()">
              <div class="placeholder-text">
                {{dropdownSelection || 'Choose list'}}
              </div>
              <div *ngIf="!dropdownExpanded" class="caret-expanded"></div>
              <div *ngIf="dropdownExpanded" class="caret-collapsed"></div>
            </div>
          </label>
          <div ngbDropdownMenu class="dropdown-menu">
            <button *ngFor="let item of attributes.dropdownItems"
                    class="dropdown-item dropdown-text"
                    (click)="dropdownSelection = item">
              {{item}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="sb-modal-footer modal-confirm-ctas bg-secondary">
    <div class="row " *ngIf="attributes?.buttons.length === 3">
      <div class="col-sm-4 sb-modal-text-align-right">
        <button type="button"
                data-dismiss="modal"
                (click)="closeModal(attributes.buttons[0].name)"
                class="btn btn-secondary">
          {{attributes.buttons[0].name}}
        </button>
      </div>
      <div class="col-sm-4">
        <button type="button"
                data-dismiss="modal"
                (click)="closeModal(attributes.buttons[1].name)"
                class="btn btn-secondary">
          {{attributes.buttons[1].name}}
        </button>
      </div>
      <div class="col-sm-4 sb-modal-text-align-left">
        <button type="button"
                data-dismiss="modal"
                (click)="closeModal(attributes.buttons[2].name)"
                class="btn btn-primary"
                [disabled]="checkboxNeeded && !checkboxChecked">
          {{attributes.buttons[2].name}}
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-center" *ngIf="attributes?.buttons.length === 2">
      <div class="mr-1">
        <button type="button"
                data-dismiss="modal"
                (click)="closeModal(attributes.buttons[0].name)"
                class="btn btn-secondary">
          {{attributes.buttons[0].name}}
        </button>
      </div>
      <div class="ml-1">
        <button type="button"
                data-dismiss="modal"
                (click)="closeModal(attributes.buttons[1].name)"
                class="btn btn-primary"
                [disabled]="checkboxNeeded && !checkboxChecked">
          {{attributes.buttons[1].name}}
        </button>
      </div>
    </div>

    <div *ngIf="attributes?.buttons.length === 1">
      <button type="button"
              data-dismiss="modal"
              (click)="closeModal(attributes.buttons[0].name);"
              class="btn btn-primary"
              [disabled]="checkboxNeeded && !checkboxChecked">
        {{attributes.buttons[0].name}}
      </button>
    </div>
  </div>
</div>
