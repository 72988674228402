import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  private storage: Storage;

  constructor() {
    this.checkStorageAvailability();
  }

  private checkStorageAvailability() {
    try {
      localStorage.setItem("__storage_test__", "test");
      localStorage.removeItem("__storage_test__");
      this.storage = localStorage;
    } catch (e) {
      // localStorage not available, fall back to sessionStorage
      this.storage = sessionStorage;
    }
  }

  setItem(key: string, value: any) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  getItem(key: string): any {
    const item = this.storage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  removeItem(key: string) {
    this.storage.removeItem(key);
  }

  clear() {
    this.storage.clear();
  }
}
