import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NotFound } from "@app/shared/components/not_found/not_found";
import { DashboardComponent } from "@app/dashboard/dashboard.component";
import { UserGuard } from "@app/shared/guards/user.guard";
import { MainMenuComponent } from "@app/shared/components/layouts/main_menu.component";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        canActivate: [UserGuard],
        component: MainMenuComponent,
        children: [
          {
            component: DashboardComponent,
            path: "",
          },
          {
            component: DashboardComponent,
            path: "registration_complete",
          }
        ],
      },
      {
        path: "dashboard",
        redirectTo: "/",
        pathMatch: "full",
      },
      {
        path: "",
        loadChildren: () =>
          import("./auth/auth.module").then((m) => m.AuthModule),
      },
      {
        path: "store",
        loadChildren: () =>
          import("./store_setup/store_setup.module").then((m) => m.StoreSetupModule),
      },
      {
        path: "",
        loadChildren: () =>
          import("./stores/stores.module").then((m) => m.StoresModule),
      },
      {
        path: "sms",
        loadChildren: () =>
          import("./sms/sms.module").then((m) => m.SMSModule),
      },
      {
        path: "terms",
        loadChildren: () =>
          import("./sms/sms.module").then((m) => m.SMSModule),
      },
      {
        path: "sms_campaigns",
        loadChildren: () =>
          import("./sms_campaigns/sms_campaigns.module").then((m) => m.SMSCampaignsModule),
      },
      {
        path: "segments",
        loadChildren: () =>
          import("./segments/segments.module").then((m) => m.SegmentsModule),
      },
      {
        path: "forms",
        loadChildren: () =>
          import("./sign_up_forms/sign_up_forms.module").then((m) => m.SignUpFormsModule),
      },
      {
        path: "automations",
        loadChildren: () =>
          import("./automations/automations.module").then((m) => m.AutomationsModule),
      },
      {
        path: "ads",
        loadChildren: () =>
          import("./ads/ads.module").then((m) => m.AdsModule),
      },
      {
        path: "email_campaigns",
        loadChildren: () =>
          import("./email_campaigns/email_campaigns.module").then((m) => m.EmailCampaignsModule),
      },
      {
        path: "email_templates",
        loadChildren: () =>
          import("./email_templates/email_templates.module").then((m) => m.EmailTemplatesModule),
      },
      {
        path: "sign_up_forms_templates",
        loadChildren: () =>
          import("./sign_up_forms_templates/sign_up_forms_templates.module").then((m) => m.SignUpFormsTemplatesModule),
      },
      {
        path: "content_blocks",
        loadChildren: () =>
          import("./content_blocks/content_blocks.module").then((m) => m.ContentBlocksModule),
      },
      {
        path: "onsite_abandonment",
        loadChildren: () =>
          import("./exchange/exchange.module").then((m) => m.ExchangeModule),
      },
      {
        path: "unique_coupon_campaigns",
        loadChildren: () =>
          import("./unique_coupon_campaigns/unique_coupon_campaigns.module").then((m) => m.UniqueCouponCampaignsModule),
      },
      {
        path: "performance",
        loadChildren: () =>
          import("./reports/reports.module").then((m) => m.ReportsModule),
      },
      {
        path: "onsite_abandonment",
        loadChildren: () =>
          import("./onsite_abandonment/onsite_abandonment.module").then((m) => m.OnsiteAbandonmentModule),
      },
      {
        path: "social",
        loadChildren: () =>
          import("./social/social.module").then((m) => m.SocialModule),
      },
      {
        path: "admin",
        loadChildren: () =>
          import("./admin/admin.module").then((m) => m.AdminModule),
      },
      {
        path: "",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "brand_scraping",
        loadChildren: () =>
          import("./brand_scraping/brand_scraping.module").then((m) => m.BrandScrapingModule),
      },
      {
        component: NotFound,
        path: "**",
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutes {}
