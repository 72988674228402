<button [popoverClass]="'network-select-popover'"
        [ngbPopover]="socialNetworkSelectContent"
        [autoClose]="'outside'"
        (hidden)="onClose()"

        placement="bottom" class="btn btn-secondary font-weight-normal w-100">
  {{this.loading ? 'Choose' : this.selectedCount }} Social Network{{ this.selectedCount !== 1 ? 's' : '' }}
  <fa-icon [icon]="['fas', 'caret-down']" class="blue-color mt-1"></fa-icon>
</button>

<ng-template #socialNetworkSelectContent>
  <ul *ngIf="!loading" class="list-group">
    <li *ngIf="selectedCount === maxSelect" class="list-group-item-info rounded">
      <div class="d-flex justify-content-center rounded">
        <span>max selected</span>
      </div>
    </li>
    <li *ngIf="selectedCount === 0" class="list-group-item-warning rounded">
      <div class="d-flex justify-content-center rounded">
        <span>0 selected</span>
      </div>
    </li>
    <li class="list-group-item border-0" *ngIf="!maxSelect || socialAccounts.length <= maxSelect">
      <div class="d-flex justify-content-start align-items-center text-dark" >
        <input type="checkbox" class="mr-1" [(ngModel)]="allSelected"
               (ngModelChange)="onToggleSelectAll()">
        All Networks
      </div>
    </li>
    <li *ngFor="let socialAccount of socialAccounts" class="list-group-item border-0">
      <div class="d-flex justify-content-start align-items-center"
           [ngClass]="maxSelect && !socialAccount.selected && selectedCount === maxSelect ? 'text-muted' : 'text-dark'">
        <input type="checkbox" class="mr-1" [disabled]="maxSelect && !socialAccount.selected && selectedCount === maxSelect"
               [(ngModel)]="socialAccount.selected"
               (ngModelChange)="updateSelectedAttrs()">
        <fa-icon [icon]="['fab', '' + socialAccount.platform + '-square']" class="mr-2"
           [ngClass]="{
            'text-facebook': socialAccount.selected && socialAccount.platform === 'facebook',
            'text-twitter': socialAccount.selected && socialAccount.platform === 'twitter',
            'text-instagram': socialAccount.selected && socialAccount.platform === 'instagram'
            }"></fa-icon>
        {{ socialAccount.nickname || socialAccount.username || socialAccount.name }}
      </div>
    </li>
  </ul>
  <div *ngIf="loading" class="my-3 d-flex justify-content-center align-items-center">
    <fa-icon [icon]="['fas', 'cog']" size="2x" [spin]="true" class="green-color"></fa-icon>
  </div>
</ng-template>
