<div class="sb-modal-content google-config-form">
  <div class="modal-icon-wrapper">
    <img class="modal-icon" src="https://springbot.s3.amazonaws.com/integrations/ga.png">
  </div>
  <h4 class="modal-header-text">
    Google Analytics Configuration
    <fa-icon [icon]="['fas', 'check']" class="green-color" *ngIf="done"></fa-icon>
  </h4>

  <div class="google-modal-error-text" *ngFor="let error of errors">
    {{ error.message }}
  </div>

  <div *ngIf="loadingData" class="text-center modal-loading">
    <fa-icon [icon]="['fas', 'cog']" size="2x" [spin]="true" class="green-color"></fa-icon>
  </div>

  <div class="form-group" *ngIf="!!googleConfig">
    <label>User Name</label>
    <div class="google-config-setting">
      {{ googleConfig.user_name }}
    </div>
  </div>

  <div class="form-group" *ngIf="!!googleConfig">
    <label>Email</label>
    <div class="google-config-setting">
      {{ googleConfig.user_email }}
    </div>
  </div>

  <div class="form-group" *ngIf="!!googleConfig">
    <label>Account</label>
    <div class="google-config-setting">
      {{ googleConfig.account_name }}
    </div>

    <select class="form-control" *ngIf="!!googleConfig && !googleConfig.account_name" [disabled]="!googleConfig.accounts"
            [(ngModel)]="googleConfig.default_account_id" #defaultAccountId="ngModel"
            (change)="next()">
      <option *ngFor="let account of googleConfig.accounts; let i = index" [value]="googleConfig.accounts[i].id">
        {{ googleConfig.accounts[i].name }}
      </option>
    </select>
    <div class="alert alert-danger accounts-error" role="alert" *ngIf="googleConfig.accounts && googleConfig.accounts.length <= 0 && !loadingData">
      There are no Google Analytics accounts associated with your Google account.
    </div>
  </div>

  <div class="form-group" *ngIf="!!googleConfig">
    <label>Web Property</label>
    <div class="google-config-setting">
      {{ googleConfig.web_property_name }}
    </div>

    <select class="form-control" *ngIf="!!googleConfig && !googleConfig.web_property_name" [disabled]="!googleConfig.web_properties"
            [(ngModel)]="googleConfig.default_web_property_id" #webPropertyId="ngModel"
            (change)="next()">
      <option *ngFor="let prop of googleConfig.web_properties; let i = index" [value]="googleConfig.web_properties[i].id">
        {{ googleConfig.web_properties[i].name }}
      </option>
    </select>
    <div class="alert alert-danger accounts-error" role="alert" *ngIf="googleConfig.web_properties && googleConfig.web_properties.length <= 0 && !loadingData">
      You have not created a Web Property for your {{ googleConfig.account_name }} account.
    </div>
  </div>

  <div class="form-group" *ngIf="!!googleConfig">
    <label>Profile</label>
    <div class="google-config-setting">
      {{ googleConfig.profile_name }}
    </div>

    <select class="form-control" *ngIf="!!googleConfig && !googleConfig.profile_name" [disabled]="!googleConfig.profiles"
            [(ngModel)]="googleConfig.default_profile_id"
            (change)="next()">
      <option *ngFor="let profile of googleConfig.profiles; let i = index" [value]="googleConfig.profiles[i].id">
        {{ googleConfig.profiles[i].name }}
      </option>
    </select>
    <div class="alert alert-danger accounts-error" role="alert" *ngIf="googleConfig.profiles && googleConfig.profiles.length <= 0 && !loadingData">
      You have not yet created a profile for {{ googleConfig.profile_name }}.
    </div>
  </div>
</div>

<div class="modal-actions">
  <button class="btn btn-secondary modal-action" (click)="cancel()">Cancel</button>
  <button class="btn btn-danger modal-action" (click)="reset()">Disconnect</button>
  <button class="btn btn-primary modal-action" [disabled]="!done" (click)="finish()">Finish</button>
</div>
