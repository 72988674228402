<div class="position-relative mt-2">
  <label>{{label}}</label>
  <div class="d-flex justify-content-between align-items-center border p-1 cursor-pointer"
       (click)="active = true">
    <span></span>
    <form *ngIf="active else promptDiv" autocomplete="off">
      <input type="text"
             [placeholder]="prompt"
             class="form-control form-control-sm"
             #searchInput
             [(ngModel)]="searchText"
             [ngModelOptions]="{standalone: true}"
             (focusout)="active = false;"
             (keydown.enter)="selectLocation()"
             (keydown.arrowDown)="nextLocation()"
             (keydown.arrowUp)="previousLocation()"
             (keyup)="updateSearch($event)">
    </form>
    <ng-template #promptDiv>
      <div (click)="active = true;"
           id="prompt-{{locationType}}"
          class="py-1" >{{prompt}}</div>
    </ng-template>
    <fa-icon [icon]="['fas', 'plus-circle']" class="green-color"></fa-icon>
  </div>
  <div *ngIf="locationResults.length > 0 && active"
       class="border position-absolute bg-white z-index-2000 w-100 cursor-pointer">
    <div [ngClass]="{'bg-primary text-white': location.id === currentLocation?.id }"
         *ngFor="let location of locationResults"
         (mouseover)="currentLocation = location"
         (mousedown)="selectLocation(location)">
      {{ displayString(location) }}
    </div>
  </div>
</div>
