import { Component, Input } from "@angular/core";
import { NavLink } from "@models/navlink";

@Component({
  selector: "sb-breadcrumbs",
  styleUrls: ["./breadcrumbs.component.scss"],
  templateUrl: "./breadcrumbs.component.html",
})
export class BreadcrumbsComponent {
  @Input()
  links: NavLink[];
}
