import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GoogleAnalyticsConfigurationService {
  private showUri = "/features/google_analytics/config.json";
  private updateUri = "/features/google_analytics/config/update_configuration";
  private restartUri = "/features/google_analytics/config/restart.json";

  private readonly headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({
      Accept: "application/json",
    });
  }

  show(): Observable<any> {
    return this.http.get<any>(this.showUri);
  }

  restart(): Observable<any> {
    return this.http.get<any>(this.restartUri);
  }

  update(params): Observable<any> {
    const { headers } = this;
    return this.http.put<any>(this.updateUri, params, { headers });
  }
}
