<div class="sb-modal-content">
  <div class="modal-icon-wrapper">
    <img class="modal-icon" src="https://springbot.s3.amazonaws.com/integrations/instagram.svg">
  </div>
  <h4 class="modal-header-text">
    Instagram Configuration
  </h4>

  <div *ngIf="loadingData" class="text-center">
    <div class="spacing"></div>
    <i class="fas fa-2x fa-cog fa-spin text-primary"></i>
  </div>

  <div class="row" *ngIf="errored && pages && pages.length > 0">
    <div class="col-md-12">
      <div class="alert alert-danger text-center">
        <i class="fas fa-exclamation-circle fa-2x"></i>
        <p>
          We're sorry but something went wrong.
        </p>
        <p>
          The springbots are aware of the issue and are working to resolve it.
        </p>
      </div>
    </div>
  </div>

  <p *ngIf="!loadingData && !errored && connected && (!pages || pages.length === 0)"
     class="alert alert-info text-center">
    Not seeing any Facebook pages you can connect to?
    Your Instagram account needs to be set up as a Professional account and connected to a Facebook page first.
    Click
    <a href="https://help.instagram.com/502981923235522?fbclid=IwAR0b469oWTWSdBAT10nECC3Si7s9SlH8Gk038IZ0ly-zZrICbXWsl2as_Y4"
       target="_blank">here</a>
    to learn how you can convert your Instagram account into a Professional account.
  </p>

  <div *ngIf="!loadingData && !errored && pages" class="pt-3">
    <div *ngIf="!fbConnected" class="text-center">
      <strong>Facebook Connection Required</strong><br>
      <span>In order to connect to Instagram, you must first be connected to Facebook.</span>
    </div>

    <div *ngIf="fbConnected" class="row">
      <div class="col-md-4">
        <img *ngIf="profile" [src]="profile.image" width="50">
      </div>
      <div class="col-md-8">
        <div *ngIf="profile" class="text-right">
          <div *ngIf="profile.name" class="text-right">
            <strong>Name</strong><br>
            {{ profile.name }}<br>
          </div>
          <div *ngIf="profile.nickname" class="text-right">
            <strong>Nickname</strong><br>
            {{ profile.nickname }}<br>
          </div>
          <div *ngIf="profile.email" class="text-right">
            <strong>Email</strong><br>
            {{ profile.email }}<br>
          </div>
        </div>

        <div class="text-right">
          <strong>Instagram Page</strong><br>
          <select (change)="updateInstagramPage($event.target.value)"
                  [disabled]="loadingIgPage"
                  *ngIf="!selectedPage"
                  class="form-control">
            <option disabled selected value="">
              Select a page
            </option>
            <option *ngFor="let page of pages; let i = index"
                    [value]="page.id">
              {{ page.name }}
            </option>
          </select>
          <span *ngIf="selectedPage">
            {{ selectedPage.name }}
            <i (click)="resetInstagramPage()" class="fas fa-undo-alt text-success cursor-pointer"></i>
            <br>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-actions">
  <button class="btn btn-outline-primary modal-action" (click)="cancel()">Cancel</button>
  <button (click)="clearAuth()"
          [disabled]="loadingData || errored || !fbConnected"
          class="btn btn-danger modal-action">
    Disconnect
  </button>
  <button class="btn btn-primary modal-action" (click)="finish()" [disabled]="loadingData || errored || !fbConnected">Finish</button>
</div>
