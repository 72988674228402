import { Component, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "credit-card-failed",
  styleUrls: ["./credit_card_failed.component.scss"],
  templateUrl: "./credit_card_failed.component.html",
})
export class CreditCardFailedComponent {
  creditCardFailedAmount = 0;
  currencyCode = "";

  constructor(private activeModal: NgbActiveModal, private router: Router) {}

  goToBilling(): void {
    this.router.navigateByUrl("account").then(() => {
      this.activeModal.close();
    });
  }
}
