import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { DateParserFormatterService } from "@app/shared/services/date_parser_formatter.service";
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import { PlacementArray } from "@ng-bootstrap/ng-bootstrap/util/positioning";

import "./date_range.component.scss";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "date-range",
  styleUrls: ["./date_range.component.scss"],
  templateUrl: "./date_range.component.html",
  providers: [
    { provide: NgbDateParserFormatter, useClass: DateParserFormatterService },
  ],
})
export class DateRangeComponent {
  @Output()
  startDateChange = new EventEmitter<Date>();

  @Input()
  windowLimit: "future" = null;

  @Input()
  v2Toggle = false;

  @Input()
  startDate: Date;

  @Output()
  endDateChange = new EventEmitter<Date>();

  @Input()
  endDate: Date;

  @Input()
  labelColor: string;

  @Input()
  hideDate = false;

  readonly today: NgbDate = this.calendar.getToday();
  readonly sevenDaysAgo: NgbDate = this.calendar.getPrev(
    this.calendar.getToday(),
    "d",
    7
  );
  readonly thirtyDaysAgo: NgbDate = this.calendar.getPrev(
    this.calendar.getToday(),
    "d",
    30
  );
  readonly ninetyDaysAgo: NgbDate = this.calendar.getPrev(
    this.calendar.getToday(),
    "d",
    90
  );
  readonly yearToDate: NgbDate = new NgbDate(this.today.year, 1, 1);
  readonly sevenDaysFromNow: NgbDate = this.calendar.getNext(
    this.calendar.getToday(),
    "d",
    7
  );
  readonly thirtyDaysFromNow: NgbDate = this.calendar.getNext(
    this.calendar.getToday(),
    "d",
    30
  );
  readonly ninetyDaysFromNow: NgbDate = this.calendar.getNext(
    this.calendar.getToday(),
    "d",
    90
  );
  readonly yearFromNow: NgbDate = this.calendar.getNext(
    this.calendar.getToday(),
    "d",
    365
  );
  readonly yearInMilliseconds = 31536000000;
  dateInPast = {
    seven: this.sevenDaysAgo,
    thirty: this.thirtyDaysAgo,
    ninety: this.ninetyDaysAgo,
    year: this.yearToDate,
  };

  dateInFuture = {
    seven: this.sevenDaysFromNow,
    thirty: this.thirtyDaysFromNow,
    ninety: this.ninetyDaysFromNow,
    year: this.yearFromNow,
  };

  startInfinity = false;
  endInfinity = false;
  fromInfinity = false;
  toInfinity = false;
  loading = true;
  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  popoverPlacement: PlacementArray = [
    "bottom",
    "bottom-left",
    "bottom-right",
    "top",
    "top-left",
    "top-right",
    "left",
    "left-top",
    "left-bottom",
    "right",
    "right-top",
    "right-bottom",
  ];

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) {}

  ngOnInit() {
    this.setupDates();
  }

  setupDates() {
    this.startDate =
      this.startDate || this.getDateFromValue(this.thirtyDaysAgo);
    this.endDate = this.endDate || this.getDateFromValue(this.today);
    this.fromDate = NgbDate.from(
      this.formatter.parse(this.startDate.toDateString())
    );
    this.toDate = NgbDate.from(
      this.formatter.parse(this.endDate.toDateString())
    );
    this.setInfinity();
    this.loading = false;
  }

  ngOnChanges(change: SimpleChanges): void {
    if (
      change.fromDate ||
      change.toDate ||
      change.startDate ||
      change.endDate
    ) {
      this.setInfinity();
    }
    if (change.windowLimit) {
      this.setupDates();
    }
  }

  onDateSelection(date: NgbDate) {
    if (this.windowLimit === "future" && date.before(this.today)) {
      return;
    }
    if (!this.fromDate && !this.toDate) {
      // If the calendar is clear, select the date
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      // If the calendar has a start date, select the end date
      this.toDate = date;
    } else {
      // If the calendar has both, reset the start date to the end date.
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentDate: NgbDate, input: string): NgbDate {
    const parsed = this.formatter.parse(input);
    if (!parsed) {
      return currentDate;
    }
    const newDate = NgbDate.from(parsed);
    const isDateInLimit =
      this.windowLimit !== "future" || newDate.after(this.today);
    return this.calendar.isValid(newDate) && isDateInLimit
      ? NgbDate.from(parsed)
      : currentDate;
  }

  setWindow(windowSize: string): void {
    if (this.windowLimit === "future") {
      this.fromDate = this.today;
      this.toDate = this.dateInFuture[windowSize];
    } else {
      this.toDate = this.today;
      this.fromDate = this.dateInPast[windowSize];
    }
    this.setInfinity();
  }

  onConfirm(popover: any) {
    this.startDate = this.getDateFromValue(this.fromDate);
    this.endDate = this.getDateFromValue(this.toDate);
    this.startDateChange.emit(this.startDate);
    this.endDateChange.emit(this.endDate);
    popover.close();
  }

  onCancel(popover: any) {
    this.fromDate = NgbDate.from(
      this.formatter.parse(this.startDate.toDateString())
    );
    this.toDate = NgbDate.from(
      this.formatter.parse(this.endDate.toDateString())
    );
    popover.close();
  }

  windowIsSelected(windowSize: string): boolean {
    if (this.windowLimit === "future") {
      return this.dateInFuture[windowSize] === this.toDate;
    }
    return this.dateInPast[windowSize] === this.fromDate;
  }

  private getDateFromValue(value: NgbDate): Date {
    return new Date(value.year, value.month - 1, value.day);
  }

  private setInfinity(): void {
    this.startInfinity = this.isInfinityAway(this.startDate);
    this.endInfinity = this.isInfinityAway(this.endDate);
    this.fromInfinity = this.isInfinityAway(this.fromDate);
    this.toInfinity = this.isInfinityAway(this.toDate);
  }

  // a date more than 50 years in the past or
  // future will display a negative infinity or positive infinity
  private isInfinityAway(date: NgbDate | Date): boolean {
    date = !date ? new Date() : date;
    const selectedYear = date instanceof Date ? date.getFullYear() : date.year;
    return Math.abs(selectedYear - new Date().getFullYear()) > 50;
  }
}
