import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, take } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class AWSFileService {
  constructor(private http: HttpClient) { }

  // used to upload edited image file to AWS
  getBlobFrom(dataURL: string): Blob {
    const data = dataURL.split(',');
    const byteStr = window.atob(data[1]);
    const type = data[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteStr.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteStr.length; i++) {
      ia[i] = byteStr.charCodeAt(i);
    }

    return new Blob([ab], { type });
  }

  prepareUniqueFileNameFor(fileName: string): string {
    let uniqueFileName = fileName.replace(/ /g, '_');
    uniqueFileName = this.insertRandomStringInto(uniqueFileName);
    return this.removeSpecialCharactersFrom(uniqueFileName);
  }

  prepareFile(file: File): File {
    const fileName = this.prepareUniqueFileNameFor(file.name);
    return new File([file], fileName, { type: file.type });
  }

  prepareFileFromDataUrl(dataUrl: string, name: string, fileType: string): File {
    const fileBlob: Blob = this.getBlobFrom(dataUrl);
    return this.prepareFileFromBlob(fileBlob, name, fileType);
  }

  prepareFileFromBlob(blob: Blob, name: string, fileType: string): File {
    const fileName = this.prepareUniqueFileNameFor(name);
    return new File([blob], fileName, { type: fileType });
  }

  // returns an image file from upload, drag & drop, or Blob generated by image editor
  prepareFileFromEvent(event: InputEvent | DragEvent): File {
    let file: File;
    if (event instanceof DragEvent) { // file from drag and drop
      file = event.dataTransfer.files[0];
    } else { // file from browse
      const { files } = event.target as HTMLInputElement;
      if (files.length === 0) {
        return;
      }
      file = files[0];
    }
    return this.prepareFile(file);
  }


  // This is used to fetch the dataUrl specifically for images in the old bucket.
  // We can discontinue this code in the future, 2025 should be a good time to do this.
  prepareFileFromUrl(url: string): Observable<File> {
    return new Observable((subscriber) => {
      const fileType = url.split('.').pop();
      let filename = url.split('/').pop().split('?')[0];
      filename = this.prepareUniqueFileNameFor(filename);
      this.imageUrlToBase64(url).then((dataUrl) => {
        console.log(dataUrl);
         subscriber.next(this.prepareFileFromDataUrl(dataUrl, filename, `image/${fileType}`));
      });
    });
  }

  private insertRandomStringInto(fileName: string): string {
    return fileName.split('.').join(`-${Math.random().toString(36).substr(6)}.`);
  }

  // https://docs.aws.amazon.com/AmazonS3/latest/dev/UsingMetadata.html#object-keys
  private removeSpecialCharactersFrom(fileName: string): string {
    return fileName.replace(/[&\/\\#,+$~%'="|@:;`*?^<[\]\>{}]/g, "_");
  }

  async imageUrlToBase64(url: string): Promise<string> {
    url = url.replace("images.sbassets.net", "s3.amazonaws.com/springbot_www_production");
    const res = await fetch(url);
    const blob = await res.blob();

    return new Promise((resolve, reject) => {
      const reader  = new FileReader();
      reader.addEventListener("load", function () {
        let response;
        if (typeof reader.result !== 'string') {
          let binary = '';
          const bytes = new Uint8Array(reader.result);
          for (let i = 0; i < bytes.byteLength; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          response = window.btoa(binary);
        } else {
          response = reader.result;
        }
        return resolve(response);
      }, false);

      reader.onerror = () => {
        return reject();
      };
      reader.readAsDataURL(blob);
    })
  }
}
