import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ChartsService } from "@app/shared/services/charts.service";
import { formatDate } from "@angular/common";

import { StoreService } from "@app/shared/services/store.service";
import { ChartFilter } from "@models/automations/chart_filter";
import { Store } from "@models/store";
import { ChartDataset, ChartOptions, TimeUnit } from "chart.js";

@Component({
  selector: "subscribers-chart",
  styleUrls: ["./subscribers_chart.component.scss"],
  templateUrl: "./subscribers_chart.component.html",
})
export class SubscribersChartComponent {
  startDate: Date;
  endDate: Date;

  @Output()
  startDateChange = new EventEmitter<any>();

  @Output()
  dateChanged = new EventEmitter<any>();

  data: ChartDataset[];
  store: Store;
  activeSubscribersCount: number;
  options: ChartOptions;
  chartUnit: TimeUnit = "day";
  loadingSubscriberGrowthGraph: boolean = true;
  filter: ChartFilter;

  constructor(
      private chartsService: ChartsService,
      private storeService: StoreService
  ) {
  }

  ngOnInit() {
    this.store = this.storeService.getSource().value;
    this.startDate = new Date();
    this.endDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - 90);
    this.filter = new ChartFilter({ startDate: this.startDate.toString(), endDate: this.endDate.toString() });
    this.getChartData();
  }

  getChartData() {
    this.loadingSubscriberGrowthGraph = true;
    this.filter.startDate = this.startDate.toISOString();
    this.filter.endDate = this.endDate.toISOString();
    this.dateChanged.emit(this.filter);
    this.setChartOptions();
    this.chartsService
        .getSubscribersData(this.filter)
        .subscribe((subscribersResponse: any) => {
          const data = Object.keys(subscribersResponse.data)
              .map((key) => ({x: new Date((key)).getTime(), y: subscribersResponse.data[key]}))
              .sort((a, b) => (a.x < b.x ? -1 : 1));

          this.data = [
            {
              data,
              tension: 0,
              pointRadius: 3,
              borderColor: "green",
              backgroundColor: "rgb(194,229,199)",
              pointBackgroundColor: "green",
            },
          ];

          this.activeSubscribersCount = subscribersResponse.active_subscribers_count;
          this.loadingSubscriberGrowthGraph = false;
        });
  }

  setChartOptions() {
    this.options = {
      plugins: {
        tooltip: {
          callbacks: {
            title: (context) => {
              return formatDate(context[0].parsed.x, "MMM dd, yyyy", "en-US");
            }
          }
        }
      },
      scales: {
        x: {
          time: {
            unit: this.chartUnit,
          },
          type: "time",
        },
        y: {
          beginAtZero: true,
        },
      },
    };
  }
}
