import { Injectable } from "@angular/core";
import { CustomSocialIcon, StoreBranding } from "@models/store_branding";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalDialogueContent } from "@app/shared/components/sb_modal_dialogue/sb_modal_dialogue";
import { Modal } from "@models/modal_info";

// this class serves to replace branding merge
// tags with hard-coded values in a GrapesJS generated email's
// html or mjml, depending on it's call location.
// Additionally, it checks for malformed branding merge tags
// publicly callable functions are #replace, #findBrokenMergeTags
@Injectable({
  providedIn: "root",
})
export class EditorMergeTagService {

  modal: Modal;

  constructor(private modalService: NgbModal) {}

  openInvalidMergeTagsModal(): Promise<string> {
    this.modal = new Modal();
    this.modal.buttons = [{ name: "Ok" }];
    this.modal.subtext = "Oops! The tags [[store_address]] and [[unsubscribe]] are a fixed field that automatically fills in specific data from your store's settings. We've reverted it back to ensure your emails work as intended.";
    this.modal.title = "";
    this.modal.icon = "warning.png";
    const modalRef = this.modalService.open(ModalDialogueContent);
    modalRef.componentInstance.attributes = this.modal;
    return modalRef.result;
  }

  replace(mergeTags: StoreBranding, html: string): string {
    mergeTags = this.setDefaultValues(mergeTags);
    html = this.replaceSimpleMergeTags(mergeTags, html);
    html = this.replaceCustomSocialIcons(mergeTags.customSocialIcons, html);
    return this.replaceSocialModuleIconSize(mergeTags.socialIconSize, html);
  }

  updateSocialModule(mergeTags, mjml: string): string {
    if (mergeTags && (mergeTags.facebook_icon_custom_url || mergeTags.facebookIconCustomUrl)) {
      const sandbox = document.createElement("div");
      sandbox.innerHTML = mjml;
      const modules = sandbox.querySelectorAll("mj-social");
      const templateUpdated = this.getSocialModuleTemplate(mergeTags);
      modules.forEach((element) => {
        element.replaceWith(templateUpdated);
      });

      return this.replaceSpecialChars(sandbox.innerHTML.trim());
    }
    return mjml;
  }

  updateNavbarModule(mergeTags, mjml: string): string {
    if (mergeTags && (mergeTags.navlinks || mergeTags.nav_links)) {
      const sandbox = document.createElement("div");
      sandbox.innerHTML = mjml;
      const modules = sandbox.querySelectorAll("mj-navbar");
      const templateUpdated = this.getNavbarModuleTemplate(mergeTags);
      modules.forEach((element) => {
        element.replaceWith(templateUpdated);
      });

      return this.replaceSpecialChars(sandbox.innerHTML.trim());
    }
    return mjml;
  }

  // returns the complete branding merge tag for the first broken or complete merge tag found
  findMergeTag(storeBranding: StoreBranding, content: string): string {
    const mergeTagString = this.unBracedMergeTags(storeBranding).find((tag) => new RegExp(`{*${tag}}*`).test(content));
    return mergeTagString ? `{{${mergeTagString}}}` : null;
  }

  // returns an array of all merge tags that were not correctly populated for a given component
  // caused by incomplete tags not located by grapes
  findBrokenMergeTags(mergeTags: StoreBranding, html: string): string[] {
    return Object.keys(mergeTags).reduce((brokenMergeTags, mergeTag) => {
      const brokenMergeTag = this.findBrokenMergeTagFor(mergeTag, html);
      return brokenMergeTag ? brokenMergeTags.concat(brokenMergeTag) : brokenMergeTags;
    }, []);
  }

  private findBrokenMergeTagFor(tag: string, html: string): string {
    const tagText = tag.replace(/[{}]/gi, "");
    const brokenTagVariants = [`{{${tagText}}`, `{${tagText}}}`, `{{${tagText}`, `{${tagText}}`, `${tagText}}}`];
    return html ? brokenTagVariants.find((brokenTag) => html.includes(brokenTag)) : "";
  }

  // TODO: find error in here, something is wrong!
  private replaceSimpleMergeTags(mergeTags: StoreBranding, html: string): string {
    return this.simpleMergeTagsMatrix(
      mergeTags,
    ).reduce((resultHTML, [mergeTagKey, mergeTagValue]) => {
      return resultHTML.replace(
        this.mergeTagRegExp(this.camelToSnakeCase(mergeTagKey)),
        this.cssMergeTagValue(mergeTagValue),
      );
    }, html);
  }

  // returns an array of tuples from the key value pairs in branding where
  // 1 - key is a merge tag, not an unrelated model attribute
  // 2 - value is a number or string primitive, not an Array or Object
  private simpleMergeTagsMatrix(mergeTags: StoreBranding): [string, string | number][] {
    return Object.entries(mergeTags).filter(([mergeTagKey, mergeTagValue]) => {
      return this.isAttributeMergeTag(mergeTagKey) && ["string", "number"].includes(typeof mergeTagValue);
    });
  }

  // certain merge tags are saved as strings rather than css values
  // converts all merge tag values to valid, insertable css values
  private cssMergeTagValue(mergeTagValue: string | number): string {
    switch (mergeTagValue) {
      case "Squared":
        return "0px";
      case "Rounded":
        return "4px";
      default:
        return mergeTagValue.toString(); // for the few cases where it's a number
    }
  }

  private isAttributeMergeTag(mergeTagKey: string): boolean {
    return !["id", "store_id", "storeId"].includes(mergeTagKey);
  }

  private mergeTagRegExp(mergeTagKey: string): RegExp {
    return new RegExp(`{{${mergeTagKey}}}`, "g");
  }

  private replaceCustomSocialIcons(customIcons: CustomSocialIcon[], html: string): string {
    customIcons.forEach(({ name, url, imageUrl }: CustomSocialIcon) => {
      name = name.toLowerCase();
      html = html.replace(this.mergeTagRegExp(`${name}-href`), url);
      html = html.replace(this.mergeTagRegExp(`${name}-icon`), imageUrl);
    });
    return html;
  }

  // Corrects mjml plugin error that leaves social-icon-size with NaN values
  private replaceSocialModuleIconSize(socialIconSize: number, html: string): string {
    return html.replace(/(width|height)="NaN"/g, `$1="${socialIconSize}"`);
  }

  private camelToSnakeCase(str: string): string {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  }

  private setDefaultValues(mergeTags: StoreBranding): StoreBranding {
    Object.entries(mergeTags).forEach(([key, value]) => {
      // zero's are acceptable values for certain keys
      const defaultValue = mergeTags.defaults && mergeTags.defaults[key];
      value = (!!value || value === 0) ? value : defaultValue;
      mergeTags[key] = value;
    });

    return mergeTags;
  }

  private getNavbarModuleTemplate(mergeTags) {
    return `
      <mj-navbar padding-left="25px" padding-right="25px" padding-top="3px" padding-bottom="3px" background-color="{{body_color}}" 
      align="center" ico-align="center" ico-color="#000000" ico-font-size="30px" base-url="" hamburger="hamburger">
        ${this.getMjLinkTemplate(mergeTags.navlinks)}
      </mj-navbar>
    `;
  }

  private getMjLinkTemplate(links) {
    let template = "";
    links.forEach((link) => {
      template += `
      <mj-navbar-link href="${link.url || "http://www.example.com"}"
        padding-top="10px" padding-left="25px"
        padding-bottom="10px" padding-right="25px"
        color="{{link_color}}"
        font-family="{{font_family}}"
        font-size="13px"
        font-weight="400"
        line-height="22px"
        text-transform="none">${link.text}
      </mj-navbar-link>
    `;
    });
    return template.trim();
  }

  private getSocialModuleTemplate(mergeTags) {
    const socialMedia = ["facebook", "instagram", "linkedin", "pinterest", "twitter", "youtube"];
    const borderRadius = "{{social_icon_border_radius}}px ".repeat(4).trim();

    let socialElements = socialMedia.reduce((result, social) => {
      const href = mergeTags?.[`${social}IconCustomUrl`];
      if (href === "" || href === "disabled") {
        return result;
      }
      return `${result}
      <mj-social-element name="${social}" href="{{${social}_icon_custom_url}}" background-color="{{${social}_icon_background_color}}" 
      src="{{social_icon_base_url}}${social}.png"> </mj-social-element>`;
    }, "");

    if (mergeTags.customSocialIcons?.length) {
      socialElements = mergeTags.customSocialIcons.reduce((result, icon, index) => {
        const iconNumber = index + 1;
        const name = `custom${iconNumber}`;
        return `${result}
        <mj-social-element name="${name}" href="{{${name}-href}}" background-color="transparent" 
        src="{{${name}-icon}}"> </mj-social-element>`;
      }, socialElements);
    }
    const template = `
      <mj-social mode="horizontal" font-family="{{font_family}}" icon-size="{{social_icon_size}}px" border-radius="${borderRadius}" 
      padding-top="3px" padding-bottom="3px" padding-left="25px" padding-right="25px">
      ${socialElements}
      </mj-social>
    `;

    return template.trim();
  }

  private replaceSpecialChars(code: string): string {
    return code
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">");
  }

  // returns array of merge tags with no curly braces from StoreBranding
  // eg. ['font_family', 'link_color', ...]
  private unBracedMergeTags(storeBranding: StoreBranding): string[] {
    const nonBrandingAttributes = ['id', 'state', 'store_id', 'created_at', 'updated_at'];
    return Object.keys(storeBranding)
      .filter((key) => !nonBrandingAttributes.includes(key))
      .map((key) => this.camelToSnakeCase(key));
  }
}
