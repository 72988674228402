import {Component, Input} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { S3UploadApiService } from "@app/shared/services/s3_upload_api.service";
import {MjmlGeneratorService} from "@app/shared/services/mjml_generator_service";
import {GeneratedMjml} from "@models/generated_mjml";
import { TextGeneratorService } from "@app/shared/services/text_generator_service";
import { Product } from "@models/product";
import {
  ProductModalService
} from "@app/content_editor/components/email_content_editor/services/product_modal.service";
import { GeneratedText } from "@models/generated_text";

@Component({
  selector: "ai-designer-modal",
  templateUrl: "./ai_designer_modal.component.html",
  styleUrls: ["./ai_designer_modal.component.scss"],
})
export class AiDesignerModalComponent {
  // A set of rules to tell ChatGPT how to ensure the MJML is compatible with the Grapes Editor.
  mjmlRules = `
    We are using mjml-browser version 4.8.0.
    All content should be inside either a one column, or two column section.
    Do not set a width on columns. Let them be responsive.
  `;

  @Input()
  contentType: string = "template";

  @Input()
  campaignId: string;

  selectedTheme: string = "Newsletter";
  featuredImageUrl: string = "";
  backgroundImageUrl: string = "";
  selectedListTouched = false;
  loadingFeaturedImageUrl: boolean = false;
  loadingBackgroundImageUrl: boolean = false;
  themeOptions: string[] = [
    "Newsletter",
    "Seasonal Sale",
    "Summer Sale",
    "Winter Clearance",
    "Spring Offers",
    "Autumn Specials",
    "Product Launch",
    "Product Update",
    "Event Announcement",
    "Event Reminder",
    "Black Friday Sale",
    "Cyber Monday Deals",
    "Valentine's Specials",
    "Easter Deals",
    "Mother's Day Sale",
    "Father's Day Offer",
    "Back to School Sale",
    "Halloween Special",
    "Thanksgiving Offers",
    "Christmas Sale",
    "New Year Deals",
    "Anniversary Sale",
    "Flash Sale",
    "Clearance Sale",
    "Customer Appreciation",
    "Referral Program",
    "Membership Renewal",
    "Loyalty Program Updates",
    "Survey/Feedback Request",
    "Webinar Invitation",
    "Course/Training Enrollment",
    "Re-engagement Campaign",
    "Welcome Email",
    "Thank You Email",
    "Affiliate Program",
    "Other"
  ];
  samplePrompts = [
    { label: "Product Launch", prompt: "Design a vibrant, single-column email template featuring a large hero image at the top, followed by short text introducing a new product, and a clear call-to-action button for a special launch offer."},
    { label: "Monthly Newsletter", prompt: "Create a multi-section, two-column newsletter template with a header for company news, a middle section for featured articles, and a footer with contact info and social media links." },
    { label: "Event Invitation", prompt: "Generate a festive, single-column event invitation email with a bold banner image, event details in eye-catching typography, and an RSVP button at the bottom." },
    { label: "Holiday Newsletter", prompt: "Create a Holiday Newsletter with two sections-- the top section should include a warm seasonal greeting to the reader, and the bottom section should contain information for a Christmas sale." },
  ]
  customTheme: string = '';
  designVision: string = '';
  maxLength: number = 280;
  requestLimit: number = 20;
  requestsThisMonth: number = 0;
  remainingRequests: number = 0;
  remoteWorking: boolean = true;
  contentFormat: string = "mjml";
  selectedProduct: Product;
  productOrPromptError = false;

  constructor(
    public activeModal: NgbActiveModal,
    private mjmlGeneratorService: MjmlGeneratorService,
    private textGeneratorService: TextGeneratorService,
    private s3: S3UploadApiService,
    private productModalService: ProductModalService,
    ) {}

  ngOnInit() {
    if (this.contentType === "template" || this.contentType === "section" || this.contentType === "mj-text") {
      this.contentFormat = "mjml";
    } else {
      this.contentFormat = "text";
    }
    this.themeOptions.sort((a, b) => {
      if(a === "Other") return 1;   // If a is "Other", it should come after b
      if(b === "Other") return -1;  // If b is "Other", it should come after a
      return a.localeCompare(b);    // Otherwise, compare alphabetically
    });
    this.mjmlGeneratorService.limit().subscribe((data) => {
      this.requestLimit = data.requestLimit;
      this.requestsThisMonth = data.requestsThisMonth;
      // temporarily
      // this.remoteWorking = data.remoteWorking;
      this.remoteWorking = true;
      // end
      const remaining = this.requestLimit - this.requestsThisMonth;
      if (remaining <= 0) {
        this.remainingRequests = 0;
      } else {
        this.remainingRequests = remaining;
      }
    });
  }

  ngOnChanges(): void {
    if (this.contentType === "template" || this.contentType === "section" || this.contentType === "mj-text") {
      this.contentFormat = "mjml";
    } else {
      this.contentFormat = "text";
    }
  }

  onSubmit(): void {
    if (this.contentType === "template" || this.contentType === "section" || this.contentType === "mj-text") {
      this.generateTemplate();
    } else if (this.contentType === "social_post" && !this.selectedProduct && this.designVision.length <= 0) {
      this.productOrPromptError = true;
      setTimeout(() => {
        this.productOrPromptError = false;
      }, 5000)
    } else {
      this.generateText();
    }
  }

  generateTemplate() {
    const params = {
      theme: this.selectedTheme == 'Other' ? this.customTheme : this.selectedTheme,
      featured_image_url: this.featuredImageUrl,
      background_image_url: this.backgroundImageUrl,
      prompt: this.designVision,
      mjml_rules: this.mjmlRules,
      campaign_id: this.campaignId,
    }
    this.mjmlGeneratorService.create(new GeneratedMjml(params)).subscribe((generatedMjml) => {
      this.activeModal.close(generatedMjml);
    });
  }

  generateText(): void {
    const params = {
      prompt: this.designVision,
      productId: this.selectedProduct?.id,
      maxLength: this.maxLength,
      contentType: this.contentType,
    }
    this.textGeneratorService.create(new GeneratedText(params)).subscribe((generatedText) => {
      this.activeModal.close(Object.assign({}, generatedText, { selectedProduct: this.selectedProduct }));
    });
  }

  openProductSelectModal(): void {
    this.productModalService.openProductSearchModal(this.selectedProduct).then((product) => {
      this.selectedProduct = product;
    }, (error) => {
      console.error(error);
    });
  }

  async handleImageSelect(file: File, fileType: string): Promise<any> {
    const logoTooLarge = (file.size / 1024 / 1024) > 5;
    const logoFileTypeIncorrect = !this.isImage(file);
    if (logoTooLarge) {
      alert("Image must be less than 1024x1024 pixels");
    } else if (logoFileTypeIncorrect) {
      alert("File extension must be .jpg or .png");
    } else {
      this.loadingFeaturedImageUrl = fileType === "featuredImageUrl";
      this.loadingBackgroundImageUrl = fileType === "backgroundImageUrl";
      this.s3.uploadImage(file)
        .subscribe({
          next: (response) => {
            this.handleUploadResponse(response, fileType);
          },
          error: (error) => {
            console.error(error);
            this.loadingFeaturedImageUrl = false;
            this.loadingBackgroundImageUrl = false;
            alert("An error occurred uploading the image.")
          }
        });
    }
  }

  private isImage(file: File) {
    return !!(file.type.includes("jpeg") || file.type.includes("png"));
  }

  private handleUploadResponse(data: any, fileType: string) {
    this.loadingFeaturedImageUrl = false;
    this.loadingBackgroundImageUrl = false;
    if (fileType == 'featuredImageUrl') {
      this.featuredImageUrl = data.url;
    } else {
      this.backgroundImageUrl = data.url;
    }
  }
}
