import { Component } from "@angular/core";

import { StoreService } from "@app/shared/services/store.service";
import { Store } from "@models/store";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { take } from "rxjs/operators";

@Component({
  selector: "captcha-config-modal",
  styleUrls: ["./captcha_config_modal.component.scss"],
  templateUrl: "./captcha_config_modal.component.html",
})
export class CaptchaConfigModalComponent {
  loadingStore = true;
  store: Store;
  private errors = [];
  private storeObserver = {
    next: (store) => {
      this.store = store;
      this.loadingStore = false;
    },
    error: (error) => {
      this.errors = [error];
    },
  };

  constructor(
    private activeModal: NgbActiveModal,
    private storeService: StoreService
  ) {}

  ngOnInit() {
    this.getStore();
  }

  save() {
    this.storeService.updateStore(this.storeParams()).subscribe((response) => {
      this.activeModal.close(response);
    });
  }

  cancel() {
    this.activeModal.dismiss();
  }

  private getStore(): void {
    this.storeService.getStore().pipe(take(1)).subscribe(this.storeObserver);
  }

  private storeParams() {
    return {
      googleSecretKey: this.store.googleSecretKey,
      googleSiteKey: this.store.googleSiteKey,
    };
  }
}
