import { Base } from "@models/base";

export class SubTemplate extends Base {
  backgroundColor: string;
  buttonAlignment: string;
  buttonBorderColor: string;
  buttonBorderStyle: string;
  buttonColor: string;
  buttonFont: string;
  buttonFontColor: string;
  buttonFontSize: string;
  buttonHeight: number;
  buttonStyle: string;
  buttonText?: string;
  buttonWidth: number;
  columns: number;
  previewCount?: number;
  productImageSize: string;
  productsOrder: string;
  recommenderConfiguration?: {
    excludedSkus: [];
    excludedCategories: [];
    recommenderType: string;
    topSellingTimePeriod: number;
    sortByMetric: string;
  };

  shownProducts: number;
  singleColumnProducts: boolean;
  styleConfiguration: {
    tablePadding: number;
    tableBorder: number;
    tableWidth: string;
    tableBorderCollapse: string;
  };

  template: string;
  type?: string;
  mjmlTemplate?: string;
  textAlignment?: string;
  containerTemplate?: string;
  containerMjmlTemplate?: string;

  set(key, value) {
    this[this.snakeToCamel(key)] = value;
  }

  get(key) {
    return this[this.snakeToCamel(key)];
  }

  // Because the editor is upgraded from angular.js and uses snake_case everywhere.
  snakeToCamel(str) {
    return str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());
  }
}
