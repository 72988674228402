import { SignUpFormTemplate } from "@models/sign_up_form_template";
import { Base } from "./base";
import { Store } from "./store";

export class SignUpForm extends Base<SignUpForm> {
  store: Store;
  hasCaptcha: boolean;
  embedded: boolean;
  state: string;
  name: string;
  content: string;
  mobileContent: string;
  successContent: string;
  mobileSuccessContent: string;
  behavior: string;
  listId: string;
  includedPages: string[];
  excludedPages: string[];
  active: string;
  listIds: string[];
  stateDisplayName: string;
  identifier: string;
  templateId: number;
  signUpFormTemplate?: SignUpFormTemplate;
}
