import { Component, Input } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "sb-footer",
  styleUrls: ["./sb-footer.component.scss"],
  templateUrl: "./sb-footer.component.html",
})
export class SbFooterComponent {
  @Input()
  storeId: number;

  @Input()
  fixedBottom = false;

  currentVersion: string = environment.appVersion;
  currentYear: number = new Date().getFullYear();

  constructor () { }

  ngOnInit() { }
}
