<ng-container>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-10 d-flex flex-column align-items-center text-center">
          <fa-icon [icon]="['fas', 'map-marker-alt']" size="3x" class="mb-3"></fa-icon>
          <h2>Twitter Location</h2>
        </div>
      </div>
      <div *ngIf="!twitterAuthed" class="d-flex justify-content-center text-danger small">
        Please connect a twitter account to continue.
      </div>
      <div class="row mt-3 justify-content-center">
        <div class="col-9 d-flex position-relative align-items-center">
          <input #placeSearch class="w-100 form-control" placeholder="Search" [disabled]="!twitterAuthed"/>
        </div>
      </div>
      <div class="row mt-3 justify-content-center">
        <div class="col-9">
          <label for="twitterPlaces">
            <fa-icon [icon]="['fab', 'twitter-square']" class="text-info pr-1"></fa-icon>
            Select Location
          </label>
          <select id="twitterPlaces" name="twitterPlaces" [(ngModel)]="selectedPlaceId" (change)="updateSelectedPlace()">
            <option [value]="place.id" *ngFor="let place of places">{{ place.name }}</option>
          </select>
        </div>
      </div>
      <div class="row mt-3 mb-2 justify-content-center">
        <div class="col-9 d-flex">
          <i *ngIf="selectedPlace">{{selectedPlace.name}}&nbsp;<fa-icon
                  [icon]="['fas', 'times-circle']" class="text-muted hover-dark cursor-pointer"
                  (click)="selectedPlace = null"></fa-icon>
          </i>
          <span *ngIf="!selectedPlace">&nbsp;</span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer bg-secondary">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6 d-flex justify-content-center">
          <button (click)="activeModal.dismiss()" class="btn btn-secondary px-5">
            Cancel
          </button>
        </div>
        <div class="col-6 d-flex justify-content-center">
          <button (click)="accept()" class="btn btn-primary px-5" [disabled]="!twitterAuthed">
            Accept
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
