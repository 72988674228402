<div class="warning-notification-banner fade show w-100">
  <div class="row">
    <div class="col-md-10">
      <fa-icon [icon]="['fas', 'exclamation-circle']"></fa-icon>
      Email features will be limited until you verify your email address. (Check your inbox and spam/junk folders)
      <button type="button" class="btn btn-link p-0 glacier-light-color" [disabled]="loading" (click)="onResendVerification()">Resend verification</button> to {{ emailAddress }}
      <fa-icon [icon]="['fas', 'cog']" [spin]="true" *ngIf="loading" class="green-color"></fa-icon>
    </div>
    <div class="col-md-2 small text-right pr-4">
      <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</div>

<ng-template #verificationEmailSentModal let-modal>
  <div class="card">
    <div class="card-header font-weight-bold text-center py-1 px-2">
      <fa-icon *ngIf="!errored" [icon]="['fas', 'envelope']" size="3x" class="mt-5 mb-4 glacier-color pull-left"></fa-icon>
      <fa-icon *ngIf="errored" [icon]="['fas', 'exclamation-triangle']" size="3x" class="mt-5 mb-4 glacier-color pull-left"></fa-icon>
      <h4 class="m-auto" *ngIf="!errored">Verification Email Sent</h4>
      <h4 class="m-auto" *ngIf="errored">Something went wrong</h4>
    </div>
    <div class="card-body d-flex flex-column align-items-center justify-content-center my-3">
      <p *ngIf="!errored" class="card-text text-center px-4">
        Search your inbox and spam/junk folders for an email from
        <strong>support@springbot.com</strong>
      </p>
      <p *ngIf="errored" class="card-text text-center px-4">
        If this problem persists, please contact
        <strong>support@springbot.com</strong>
      </p>
    </div>
    <div class="card-footer bg-secondary d-flex justify-content-center align-items-center">
      <button (click)="modal.close()" class="btn btn-outline-primary mx-2 pr-4">Close</button>
    </div>
  </div>
</ng-template>
