import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EmailListSubscriber } from "@models/email_lists/email_list_subscriber";
import { SMSKeyword } from "@models/sms/sms_keyword";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { environment } from "@environments/environment";

@Injectable()
export class SMSKeywordService {
  private keyword: SMSKeyword = new SMSKeyword();
  private base = `${environment.apiUrl}/v2/sms/keywords`;

  constructor(
    private httpClient: HttpClient,
    private deserializeService: DeserializeService,
  ) {}

  create(params: any): Observable<SMSKeyword> {
    this.keyword = new SMSKeyword();
    return this.httpClient.post<SMSKeyword>(this.base, params)
      .pipe(mergeMap(async (response) => {
        const keywordResponse = this.deserializeService.deserialize(response);
        const cleanedKwResponse = Object.entries(keywordResponse)
          .filter(([_k, value]) => value !== "") // remove all properties with null string value
          .reduce((result, [key, value]) => {
            result[key] = value;
            return result;
          }, {});
        return Object.assign(this.keyword, cleanedKwResponse);
      }));
  }

  destroy(keywordId: number) {
    return this.httpClient.delete<SMSKeyword>(`${this.base}/${keywordId}`);
  }

  update(keyword: SMSKeyword): Observable<SMSKeyword> {
    const params = {
      welcomeResponse: keyword.welcomeResponse,
      couponCampaignId: keyword.couponCampaignId,
      listId: keyword.listId,
      frequency: keyword.frequency,
      storeName: keyword.storeName,
      description: keyword.description,
      keyword: keyword.keyword,
      termsUrl: keyword.termsUrl,
      privacyUrl: keyword.privacyUrl,
      wizardStep: keyword.wizardStep,
      contactMethod: keyword.contactMethod,
      deleted: keyword.deleted,
    };

    return this.httpClient.put<SMSKeyword>(`${this.base}/${keyword.id}`, params)
      .pipe(mergeMap(async (response) => {
        const keywordResponse = this.deserializeService.deserialize(response);
        return Object.assign(this.keyword, keywordResponse);
      }));
  }

  index(params): Observable<SMSKeyword[]> {
    return this.httpClient.get<SMSKeyword[]>(this.base, { params }).pipe(
      mergeMap(async (response) => {
        let keywordsResponse = this.deserializeService.deserialize(response);
        keywordsResponse = keywordsResponse.map(
          (keyword) => Object.assign(new SMSKeyword(), keyword),
        );
        return keywordsResponse;
      }),
    );
  }

  show(keywordId, params?: any): Observable<SMSKeyword> {
    return this.httpClient.get<SMSKeyword>(`${this.base}/${keywordId}`, params)
      .pipe(mergeMap(async (response) => {
        const keywordResponse = this.deserializeService.deserialize(response);
        return Object.assign(this.keyword, keywordResponse);
      }));
  }

  getTermsData(termsToken: string, params?: any) {
    return this.httpClient.get<any>(`${this.base}/terms_data/${termsToken}`, params)
      .pipe(mergeMap(async (response) => {
        const keywordResponse = this.deserializeService.deserialize(response[1]);
        const extraResponse = JSON.parse(response[0]);
        return [extraResponse, Object.assign(this.keyword, keywordResponse)];
      }));
  }

  sendS3Info(params?: any) {
    return this.httpClient.post<any>(`${this.base}/s3_upload`, params)
      .pipe(mergeMap(async (response) => {
        const keywordResponse = this.deserializeService.deserialize(response);
        return response;
      }));
  }

  addSubscriber(subscriber: EmailListSubscriber, keywordId: string): Observable<any> {
    const params = {
      email: subscriber.email,
      first_name: subscriber.firstName,
      last_name: subscriber.lastName,
      mobile_phone: subscriber.mobilePhone,
      keyword_id: keywordId,
    };

    return this.httpClient.post<EmailListSubscriber>(`${this.base}/add_subscriber`, params);
  }
}
