import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppDashboard } from "@models/dashboard/app_dashboard";
import { ChannelStats } from "@models/dashboard/channel_stats";
import { StoreStats } from "@models/dashboard/store_stats";
import { TopProductsData } from "@models/dashboard/top_products_data";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class AppDashboardService {
  constructor(
    private httpClient: HttpClient,
    private deserializeService: DeserializeService,
  ) {}

  show(params?: any): Observable<AppDashboard> {
    return this.httpClient.get<AppDashboard>(`${environment.apiUrl}/v2/app_dashboard`, { params })
      .pipe(mergeMap(async (response) => this.deserializeService.deserialize(response)));
  }

  storeStats(params: any): Observable<StoreStats> {
    return this.httpClient.get<StoreStats>(`${environment.apiUrl}/v2/app_dashboard/store_stats`, { params })
      .pipe(mergeMap(async (response) => this.deserializeService.deserialize(response)));
  }

  channelStats(params: any): Observable<ChannelStats> {
    return this.httpClient.get<ChannelStats>(`${environment.apiUrl}/v2/app_dashboard/channel_stats`, { params })
      .pipe(mergeMap(async (response) => this.deserializeService.deserialize(response)));
  }

  topProducts(params: any): Observable<TopProductsData> {
    return this.httpClient.get<TopProductsData>(`${environment.apiUrl}/v2/app_dashboard/top_products`, { params })
      .pipe(mergeMap(async (response) => this.deserializeService.deserialize(response)));
  }
}
