import {
  Component, ElementRef, EventEmitter, HostListener, Output,
} from "@angular/core";
import { Option } from "@models/option";

// this component exists only to visually match the facebook_location_selector
// preferentially use the bootstrap angular drop down component whenever possible
// https://ng-bootstrap.github.io/#/components/dropdown/examples
@Component({
  selector: "facebook-age-dropdown",
  styleUrls: ["./facebook_age_dropdown.component.scss"],
  templateUrl: "./facebook_age_dropdown.component.html",
})
export class FacebookAgeDropdownComponent {
  @Output()
  notify: EventEmitter<number> = new EventEmitter<number>();

  options: Option[] = [
    { name: "everyone", value: null },
    { name: "13+", value: 13 },
    { name: "17+", value: 17 },
    { name: "18+", value: 18 },
    { name: "19+", value: 19 },
    { name: "21+", value: 21 },
  ];

  default: Option = this.options[0];
  selected: Option;
  dropdownExpanded = false;

  constructor(private eRef: ElementRef) {}

  @HostListener("document:click", ["$event"])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.dropdownExpanded = false;
    }
  }

  toggleDropdown() {
    this.dropdownExpanded = !this.dropdownExpanded;
  }

  select(option: Option) {
    this.selected = option;
    this.notify.emit(option.value);
    this.toggleDropdown();
  }
}
