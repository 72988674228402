<div class="modal-body modal-confirm text-center">
  <h3>Product Image</h3>
  <small>Select an image or upload a new one</small>
  <p *ngFor="let alert of alerts">
    <ngb-alert [type]="alert.type">{{alert.message}}</ngb-alert>
  </p>
  <div class="image-select-modal d-flex justify-content-center mt-2">
    <div *ngFor='let imageUrl of productImageUrls' class="m-2 card">
      <img [src]="imageUrl" (click)="selectImage(imageUrl)"
           class="rounded cursor-pointer" style="height: 130px; width: 130px; cursor: pointer;" />
      <i *ngIf="selectedImageUrl === imageUrl"
         class="fas fa-check-circle position-absolute text-primary"
         style="top: 5px; right: 5px;"></i>
    </div>

    <div class="m-2 card bg-light"
         style="height: 130px; width: 130px; cursor: pointer;"
         (mouseenter)="hovering = true" (mouseleave)="hovering = false">
      <ng-container *ngIf="fileUrl && !hovering">
        <img [src]="fileUrl" class="rounded" style="height: 100%; width: 100%; object-fit: fill;" />
        <i *ngIf="uploadSelected"
           class="fas fa-check-circle position-absolute text-primary"
           style="top: 5px; right: 5px;"></i>
      </ng-container>
      <image-upload *ngIf="!fileUrl || hovering"
                    [displayUploadedImage]="false"
                    [prompt]="'drag and drop image or click to select'"
                    [fontSize]="'12px'"
                    [iconSize]="'1.6rem'"
                    [accept]="'image/png, image/jpg, image/jpeg'"
                    (upload)="upload($event.files[0])">
      </image-upload>
    </div>
  </div>
</div>
<div class="modal-footer bg-secondary">
  <div class="row" style="width:100%">
    <div class="col-md-6 text-left">
      <button type="button"
              class="btn btn-outline-primary"
              (click)="selectNewProduct()">
        Change Product
      </button>
    </div>

    <div class="col-md-6 text-right">
      <button type="button"
              class="btn btn-outline-primary mr-3"
              (click)="close()">
        Cancel
      </button>
      <button type="submit"
              class="btn btn-primary"
              [disabled]="!selectedImageUrl || uploading"
              (click)="save()">
        Save
      </button>
    </div>
  </div>
</div>
