import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { mergeMap } from "rxjs/operators";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class ChartsService {
  constructor(
    private httpClient: HttpClient,
    private deserializeService: DeserializeService,
  ) {}

  getTopProductsData(topProductsFilter: any) {
    return this.httpClient
      .get(`${environment.apiUrl}/v2/charts/top_products`, { params: topProductsFilter })
      .pipe(
        mergeMap(async (productsResponse) =>
          this.deserializeService.deserialize(productsResponse)
        )
      );
  }

  getEmailCampaignsData(emailCampaignsFilter: any) {
    return this.httpClient.get(`${environment.apiUrl}/v2/charts/email_campaigns`, {
      params: emailCampaignsFilter,
    });
  }

  getPerformanceData(performanceFilter: any) {
    return this.httpClient.get(`${environment.apiUrl}/v2/charts/performance`, {
      params: performanceFilter,
    });
  }

  getSubscribersData(subscribersFilter: any) {
    return this.httpClient.get(`${environment.apiUrl}/v2/charts/subscribers`, {
      params: subscribersFilter,
    });
  }
}
