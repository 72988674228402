import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ContentBlockService } from "@app/shared/services/content_block.service";
import { EditorMergeTagService } from "@app/content_editor/services/editor_merge_tag.service";
import { TriggeredEmailTemplateService } from "@app/shared/services/triggered_email_template.service";
import { TriggeredEmailsService } from "@app/automations/services/triggered_emails.service";
import { Store } from "@models/store";
import { StoreBranding } from "@models/store_branding";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import mjml2html from "mjml-browser";
import { forkJoin, Observable } from "rxjs";
import { mergeMap, take } from "rxjs/operators";
import { TriggeredEmailTemplate } from "@models/triggered_email_template";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class StoreBrandingService {
  storeBrandingUrl = `${environment.apiUrl}/v2/store_branding`;
  type = "branding";

  defaultFooterContent = `<mj-column>
        <mj-text font-family="'Georgia',serif" color="#33B319" line-height="1" css-class="mj-text-break">
          <p style="text-align: center;">
            <span style="color:#000000;">[[store_address]]<br></span>
          </p>
          <p style="text-align: center;">
            <span style="color:#000000;">[[unsubscribe]]</span>
            <br>
          </p>
        </mj-text>
      </mj-column>
    </mj-section>`;

  constructor(
    private http: HttpClient,
    private triggeredEmailTemplateService: TriggeredEmailTemplateService,
    private triggeredEmailsService: TriggeredEmailsService,
    private editorMergeTagService: EditorMergeTagService,
    private contentBlockService: ContentBlockService,
    private deserializeService: DeserializeService,
  ) {
  }

  get(params: { defaults?: string } = null): Observable<StoreBranding> {
    return this.http.get<any>(
      this.storeBrandingUrl,
      { params },
    ).pipe(
      mergeMap(async (response) => this.deserializeService.deserialize(response))
    );
  }

  next(): Observable<StoreBranding> {
    return this.update({ next_step: true }).pipe(
      mergeMap(async (response) => this.deserializeService.deserialize(response))
    );
  }

  previous(): Observable<StoreBranding> {
    return this.update({ previous_step: true }).pipe(
      mergeMap(async (response) => this.deserializeService.deserialize(response))
    );
  }

  update(requestBody: Record<string, unknown>): Observable<any> {
    return this.http.patch<any>(this.storeBrandingUrl, requestBody);
  }

  toCSS(store: Store, branding: StoreBranding, important = false, brandedHeader = true): string {
    const importantSuffix = important ? "!important" : "";
    let css = "";
    const colors = ["bodyColor", "buttonBorderColor", "buttonColor", "buttonTextColor", "headerColor",
      "instagramIconBackgroundColor", "linkColor", "linkedinIconBackgroundColor",
      "pinterestIconBackgroundColor", "textColor", "twitterIconBackgroundColor",
      "primaryColor", "facebookIconBackgroundColor"];

    Object.entries(branding).forEach(([key, value]) => {
      if (colors.includes(key)) {
        css += `.branding-${key}-background { background-color: ${value} ${importantSuffix}; } `;
        css += `.branding-${key}-color { color: ${value} ${importantSuffix}; } `;
        css += `.branding-${key}-border { border-color: ${value} ${importantSuffix}; } `;
      }
    });
    if (brandedHeader) {
      css += `h1 { color: ${branding.textColor} ${importantSuffix}; } `;
    }
    css += `.branding-font-family { font-family: ${branding.fontFamily} ${importantSuffix}; } `;
    css += `.branding-buttonBorderRadius { border-radius: `;
    css += `${branding.buttonBorderStyle === "Rounded" ? "4" : "0"}px ${importantSuffix}; } `;
    css
      += `.branding-buttonBorderColor {
        border: 1px solid ${branding.buttonBorderColor} ${importantSuffix};
        box-shadow: 0 0 0 0;
      } `;

    return css;
  }

  /* updates branding for all default generated content
     - master template
     - default content blocks
     - Triggered Emails contained in flows v1 and v2 as "action nodes"

     optional argument: hardcodeMJMLBrandingInTriggers when true
     - finalizes the branding for all action nodes
     - replaces the merge tags in the mjml with their current branding value */
  updateHTMLTemplates(branding: StoreBranding = null,
    hardcodeMJMLBrandingInTriggers = false): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      const update = (newBranding) => {
        const observables = forkJoin([
          this.updateMasterTemplate(newBranding),
          this.updateContentBlocks(newBranding),
          this.updateTriggersCreatedByDefault(newBranding, hardcodeMJMLBrandingInTriggers),
        ]);
        observables.pipe(
          take(1),
        ).subscribe({
          next: (res) => {
            subscriber.next(true);
            subscriber.complete();
          },
          error: (errors) => {
            console.error(errors);
            subscriber.next(false);
            subscriber.complete();
          },
        });
      };
      if (branding) {
        update(branding);
      } else {
        this.get().subscribe({
          next: (res) => {
            update(res);
          },
          error: (errors) => {
            subscriber.error(false);
            subscriber.complete();
          },
        });
      }
    });
  }

  private masterTemplateErrorMessage(): string {
    return `<strong>Your master template is failing a validation that needs to be fixed before branding can be updated.</strong>
      A successful save of the master template will resolve the issue.
      <br>
      <a class="btn btn-primary" href="/automations/header_and_footer">
        Update Master Template
      </a>
    `;
  }

  private templateErrorMessage(): string {
    return `<strong>One or more of your Automation templates are failing a validation that needs to be fixed before branding can be updated.</strong>
      <br>
      <a class="btn btn-primary" href="/automations/flows">
        Update Automations
      </a>
    `;
  }

  private ensureMjmlHasCanSpam(template: TriggeredEmailTemplate): string {
    if (!!template.mjmlContent || template.mjmlContent == "" || this.hasCanSpam(template) || template.useStoreTemplate) {
      return template.mjmlContent;
    } else {
      return this.addCanSpamFooter(template)
    }
  }

  private addCanSpamFooter(template: TriggeredEmailTemplate): string {
    return template.mjmlContent.replace("</mj-body>", `${this.defaultFooterContent}</mj-body>`)
  }

  private hasCanSpam(template: TriggeredEmailTemplate): boolean {
    const hasUnsubLink = template.mjmlContent.indexOf('[[unsubscribe]]') > 0;
    const hasStoreAddress = template.mjmlContent.indexOf('[[store_address]]') > 0;

    return hasUnsubLink && hasStoreAddress;
  }

  private updateMasterTemplate(branding: StoreBranding): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      this.triggeredEmailTemplateService.getTemplate()
        .subscribe({
          next: (template: TriggeredEmailTemplate) => {
            const mjmlContent = this.ensureMjmlHasCanSpam(template);
            const mjmlAndHtmlUpdated = (
              this.mjml2htmlAndReplaceMergeTags(mjmlContent, branding, true, true)
            );
            if (mjmlAndHtmlUpdated) {
              const params = {
                template: this.prepareMasterTemplateHTMLContent(mjmlAndHtmlUpdated.html, branding),
                mjml_content: mjmlAndHtmlUpdated.mjml,
              };
              this.triggeredEmailTemplateService
                .updateMasterTemplate(template.id, params).pipe(take(1)).subscribe({
                  next: (res) => {
                    subscriber.next(true);
                    subscriber.complete();
                  },
                  error: () => {
                    subscriber.error([this.masterTemplateErrorMessage()]);
                    subscriber.complete();
                  },
                });
            } else {
              subscriber.error(false);
              subscriber.complete();
            }
          },
          error: (errors) => {
            subscriber.error(errors);
            subscriber.complete();
          },
        });
    });
  }

  private updateContentBlocks(branding) {
    return new Observable<boolean>((subscriber) => {
      const observables = [];
      const filter = { default_ones: true };
      this.contentBlockService.index(filter).pipe(take(1)).subscribe((response) => {
        const contentBlocks = response.data;

        // bypass update if defaults not found / destroyed
        if (!contentBlocks || contentBlocks.length === 0) {
          subscriber.next(true);
          subscriber.complete();
        }

        contentBlocks.forEach((contentBlock) => {
          const mjmlAndHtmlUpdated = (
            this.mjml2htmlAndReplaceMergeTags(contentBlock.mjmlContent, branding, true, true)
          );
          if (mjmlAndHtmlUpdated) {
            const params = {
              content: mjmlAndHtmlUpdated.html,
              mjml_content: mjmlAndHtmlUpdated.mjml,
            };
            observables.push(this.contentBlockService.update(contentBlock.id, params));
          }
        });
        const makeApiCalls = forkJoin(observables);
        makeApiCalls.pipe(take(1)).subscribe(() => {
          subscriber.next(true);
          subscriber.complete();
        }, (errors) => {
          subscriber.error(errors);
          subscriber.complete();
        });
      }, (errors) => {
        subscriber.error(errors);
        subscriber.complete();
      });
    });
  }

  private updateTriggersCreatedByDefault(
    branding: StoreBranding,
    hardcodeMJMLBranding = false,
  ): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      const observables = [];
      this.triggeredEmailTemplateService.getCreatedByDefault()
        .subscribe((templates: TriggeredEmailTemplate[]) => {
          // bypass update if defaults not found / destroyed
          if (!templates || templates.length === 0) {
            subscriber.next(true);
            subscriber.complete();
          }

          templates.forEach((template) => {
            const params = {};
            let subtemplateObj = null;
            if (template?.cartTemplate) {
              subtemplateObj = this.prepareSubtemplateHtmlsWithBranding(template?.cartTemplate, "mj-cart", branding);
              if (subtemplateObj) {
                Object.assign(params, { cart_template: subtemplateObj });
                subtemplateObj = null;
              }
            }

            if (template?.productRecommendationsTemplate) {
              subtemplateObj = this.prepareSubtemplateHtmlsWithBranding(
                template?.productRecommendationsTemplate, "mj-product-recommendations", branding,
              );
              if (subtemplateObj) {
                Object.assign(params, { product_recommendations_template: subtemplateObj });
                subtemplateObj = null;
              }
            }

            if (template?.purchasedCartTemplate) {
              subtemplateObj = this.prepareSubtemplateHtmlsWithBranding(
                template?.purchasedCartTemplate, "mj-purchased-cart", branding,
              );
              if (subtemplateObj) {
                Object.assign(params, { purchased_cart_template: subtemplateObj });
                subtemplateObj = null;
              }
            }
            const mjmlContent = this.ensureMjmlHasCanSpam(template);
            const mjmlAndHtmlUpdated = this.mjml2htmlAndReplaceMergeTags(mjmlContent,
              branding, true, true, hardcodeMJMLBranding);
            if (mjmlAndHtmlUpdated) {
              Object.assign(
                params,
                {
                  content: mjmlAndHtmlUpdated.html,
                  mjml_content: mjmlAndHtmlUpdated.mjml,
                },
              );
              observables.push(this.triggeredEmailsService.update(template.id, params));
            }
          });

          forkJoin(observables).subscribe((responses) => {
            subscriber.next(true);
            subscriber.complete();
          }, (error) => {
            subscriber.error(error);
          });
        }, (errors) => {
          subscriber.error(errors);
          subscriber.complete();
        });
    });
  }

  // prepares product and container HTML with branding applied on them for subtemplates
  // returns false if it runs into an error while generating the HTML
  private prepareSubtemplateHtmlsWithBranding(
    subtemplate: any, subtemplateType: string, branding: StoreBranding,
  ): any | boolean {
    const templateMjml = `<mjml><mj-body>${subtemplate.mjmlTemplate}</mj-body></mjml>`;
    let containerMjml = `<mjml><mj-body>${subtemplate.containerMjmlTemplate}</mj-body></mjml>`;

    if (["mj-product-recommendations", "mj-purchased-cart"].includes(subtemplateType)) {
      const containerMjmlSandbox = document.createElement("div");
      containerMjmlSandbox.innerHTML = containerMjml;
      // You don't need any of these in product reco modules, they're the product templates
      containerMjmlSandbox.querySelectorAll("mj-section").forEach((section) => {
        section.remove();
      });
      containerMjml = containerMjmlSandbox.innerHTML;
    }

    let templateHtml = "";
    let containerHtml = "";

    try {
      templateHtml = this.editorMergeTagService.replace(branding,
        mjml2html(templateMjml, { noMigrateWarn: true })?.html);
      containerHtml = this.editorMergeTagService.replace(branding,
        mjml2html(containerMjml, { noMigrateWarn: true })?.html);
    } catch (e) {
      return false;
    }

    const templateSandbox = document.createElement("div");
    const containerSandbox = document.createElement("div");
    templateSandbox.innerHTML = templateHtml;
    containerSandbox.innerHTML = containerHtml;

    // Hopefully it's always the case that the first div is the div we need...
    const templateEl = templateSandbox.querySelector("div");
    const containerEl = containerSandbox.querySelector("div");

    if (!templateEl || !containerEl) {
      return false;
    }

    const imgNode = templateEl.querySelector("img");
    if (imgNode) {
      imgNode.width = 250;
      imgNode.src = "[[image url]]";
      const tdElement = imgNode.parentElement.parentElement;
      tdElement.style.width = "250px";
    }
    templateHtml = templateEl.outerHTML;
    containerEl.style.maxWidth = "600px";
    containerEl.setAttribute("data-gjs-type", subtemplateType);
    // ¯\_(ツ)_/¯
    containerHtml = containerEl.outerHTML?.replace(/NaNpx/g, "600px");

    return { template: templateHtml, container_template: containerHtml };
  }

  private mjml2htmlAndReplaceMergeTags(mjml,
    mergeTags,
    updateSocialModule = false,
    updateNavbarModule = false,
    hardcodeMJMLBranding = false): { mjml: string, html: string } | void {
    if (updateSocialModule) {
      mjml = this.editorMergeTagService.updateSocialModule(mergeTags, mjml);
    }
    if (updateNavbarModule) {
      mjml = this.editorMergeTagService.updateNavbarModule(mergeTags, mjml);
    }
    const obj = mjml2html(mjml, { noMigrateWarn: true });
    if (obj && obj.html) {
      return {
        mjml: (hardcodeMJMLBranding ? this.editorMergeTagService.replace(mergeTags, mjml) : mjml),
        html: this.editorMergeTagService.replace(mergeTags, obj.html),
      };
    }
    return null;
  }

  private prepareMasterTemplateHTMLContent(content: string, branding: StoreBranding) {
    const el = new DOMParser().parseFromString(content, "text/html").querySelector("html");
    const before = el.querySelector(".mj-body");
    const fontFamily = branding.fontFamily || "Arial";
    const linkColor = branding.linkColor || "blue";

    if (before && before.firstElementChild) {
      const previewLink = document.createElement("table");
      previewLink.setAttribute("style", "width:100%;margin: 0px auto;max-width: 600px");
      previewLink.innerHTML = `
        <tbody>
          <tr>
            <td style="text-align:right">
              <a style="font-size:10px;font-family:${fontFamily};color:${linkColor};"
                target='_blank' href='{{preview_url}}'>
                View in browser
              </a>
            </td>
          </tr>
        </tbody>
      `;
      el.querySelector(".mj-body").insertBefore(previewLink, before.firstElementChild);
    }

    // this is needed for cart/product reco modules
    // since cart/reco modules are parsed in the backend
    const columnStyleRules = `
        @media only screen and (min-width:480px) {
          .mj-column-per-100 { width:100%!important; }
        }
        @media only screen and (min-width:480px) {
          .mj-column-per-50 { width:50%!important; }
        }
        @media only screen and (min-width:480px) {
          .mj-column-per-33 { width:33%!important; }
        }
        @media only screen and (min-width:480px) {
          .mj-column-per-25 { width:25%!important; }
        }
    `;

    if (!el.querySelector("head").innerHTML.includes(columnStyleRules)) {
      const styleTag = document.createElement("style");
      styleTag.innerHTML = columnStyleRules;
      el.querySelector("head").appendChild(styleTag);
    }

    return el.outerHTML;
  }
}
