<ng-container>
  <div class="row mt-5">
    <div class="col-12">
      <p class="text-white"><fa-icon [icon]="['fab', 'facebook']"></fa-icon> Facebook </p>
    </div>
  </div>

  <div class="row no-gutters">
    <div class="col-12 bg-light border rounded py-2">
      <div class="d-flex align-items-center justify-content-between px-3">
        <div class="d-flex align-items-center">
          <img class="img-fluid border rounded-circle size-50px mr-2" [src]="facebookPage.image"
               alt="fb page image"/>
          <div>
            <p class="ml-0 mb-0 font-weight-bolder">{{facebookPage.name}}<br>
              <span class="medium-gray-color font-weight-lighter">Just Now &middot; <fa-icon [icon]="['fas', 'user-friends']"></fa-icon></span>
            </p>
          </div>
        </div>
        <fa-icon [icon]="['fas', 'ellipsis-h']" class="medium-gray-color"></fa-icon>
      </div>
      <p class="mb-1 px-3" [ngClass]="{'fz-1-6-rem': attachedFiles.length === 0}" [innerHTML]="displayContent() || 'Enter post content'"></p>
      <ng-container *ngFor="let file of attachedFiles">
        <img class="w-100" [src]="file.imageUrl" *ngIf="!file.imageContentType.includes('video')">
        <video controls class="embed-responsive"
               *ngIf="file.imageContentType.includes('video')">
          <source class="embed-item"
                  [src]="file.imageUrl"
                  [type]="file.imageContentType">
        </video>
      </ng-container>
      <div class="medium-gray-color d-flex justify-content-around border-top mt-2 pt-2">
        <div><fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon> Like</div>
        <div><fa-icon [icon]="['fas', 'comment']"></fa-icon> Comment</div>
        <div *ngIf="attachedFiles.length > 0"><fa-icon [icon]="['fas', 'share']"></fa-icon> Share</div>
      </div>
    </div>
  </div>
  <div class="row no-gutters" *ngIf="attachedFiles.length > 0 && croppingWarning">
    <div class="col-12 bg-light-blue d-flex justify-content-between rounded align-items-center p-3 mt-2">
      <span><fa-icon [icon]="['fas', 'exclamation-circle']"></fa-icon> Cropping of images maybe slightly different
      </span>
      <fa-icon [icon]="['fas', 'times']" (click)="croppingWarningChange.emit(false);"></fa-icon>
    </div>
  </div>
</ng-container>
