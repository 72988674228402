import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Response } from "@models/response";
import { InstagramAccount } from "@models/social/instagram_account";
import { InstagramPost } from "@models/social/instagram_post";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class InstagramService {
  constructor(
    private http: HttpClient,
    private deserializeService: DeserializeService,
  ) {}

  getAccount(): Observable<InstagramAccount> {
    return this.http.get(`${environment.apiUrl}/v2/social_hub/instagram_accounts`)
      .pipe(mergeMap(async (response) => this.deserializeService
        .deserialize(response)));
  }

  getPosts(params: any): Observable<Response<InstagramPost[]>> {
    return this.http.get(`${environment.apiUrl}/v2/social_hub/instagram_posts`, { params })
      .pipe(mergeMap(async (postResponse: any) => {
        const posts = this.deserializeService.deserialize(postResponse) as InstagramPost[];

        return new Response<InstagramPost[]>(posts, postResponse.meta);
      }));
  }

  // targetId: thing being commented on, can be post or another comment
  // reply: true if comment is a reply to another comment
  createComment(targetId: string, message: string, reply = false): Observable<any> {
    return this.http.post(`${environment.apiUrl}/v2/social_hub/instagram_comments`, { targetId, message, reply });
  }
}
