
<!-- https://www.bootdey.com/snippets/view/Shop-Order-Tracking -->
<div class="px-1 mt-3">
  <div class="card mb-1">
    <div class="row m-0 p-2 text-white text-lg blue-background rounded-top" *ngIf="!hideHeader && (title || showPercentage)">
      <div class="col-10" *ngIf="title">
        {{ title }} - {{ percentage }}%
      </div>
      <div class="col-2 text-right">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="handleClear()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="d-flex flex-wrap flex-sm-nowrap justify-content-between py-3 px-2 bg-secondary" *ngIf="subtitles && subtitles.length > 0">
      <div class="w-100 text-center py-1 px-2" *ngFor="let sub of subtitles">
        <span class="text-medium">
          {{ sub }}
        </span>
      </div>
    </div>
    <div class="card-body">
      <div class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
        <div class="step mb-2" *ngFor="let step of steps"
             [attr.id]="'sb-nav__' + step.text.toLowerCase().split(' ').join('-') + '__dropdown'"
             [ngClass]="{ 'completed': step.completed }"
             (click)="handleClickStep(step)">
          <div class="step-icon-wrap">
            <div class="step-icon">
              <fa-icon [icon]="step.icon" size="md"></fa-icon>
            </div>
          </div>
          <h4 class="step-title">
            {{ step.text }}
          </h4>
          <hr class="show-on-mobile">
        </div>
      </div>
    </div>
  </div>
</div>
