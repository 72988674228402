<div class="sb-modal-content">
  <div class="modal-icon-wrapper">
    <img class="modal-icon" src="https://springbot.s3.amazonaws.com/integrations/retargeting.png">
  </div>
  <h4 class="modal-header-text text-center">
    AdRoll Configuration
  </h4>

  <div *ngIf="loadingData" class="text-center">
    <div class="spacing"></div>
    <fa-icon [icon]="['fas', 'cog']" size="2x" [spin]="true" class="green-color"></fa-icon>
  </div>

  <div *ngIf="!loadingData">
    <div *ngIf="errorState" class="text-center">
      <p *ngIf="requiresTrialUpgrade" class="adroll-modal-error-text">
        Upgrade your package to get access to this exciting feature.
        Click <a href="/upgrade">Here</a> to Upgrade now or
        <a href="https://go.springbot.com/contact-us-shopify-spb"
           target="_blank">contact us
        </a> for more information
      </p>
      <p *ngIf="requiresPackageUpgrade" class="adroll-modal-error-text">
        AdRoll is not available in your current package.
        Please contact <a href="mailto:help@springbot.com" target="_blank">help@springbot.com</a>
        for more information.
      </p>
    </div>

    <div *ngIf="!errorState">
      <div class="form-group">
        <label>
          Custom Checkout URL (optional)
        </label>
        <input type="text"
               name="checkout_url"
               class="form-control"
               [(ngModel)]="adrollConfiguration['custom_checkout_url']">
      </div>
      <div class="form-group">
        <label>
          Facebook Page URL (optional)
        </label>
        <input type="text"
               name="checkout_url"
               placeholder="http://www.facebook.com/myPage"
               class="form-control"
               [(ngModel)]="adrollConfiguration['facebook_page_url']">
      </div>

      <div class="form-group">
        <label>
          Instagram Account ID (optional)
        </label>
        <input type="text"
               name="instagram_account_id"
               placeholder="1234567890"
               class="form-control"
               [(ngModel)]="adrollConfiguration['instagram_account_id']">
      </div>
    </div>
  </div>
</div>

<div class="modal-actions">
  <button class="btn btn-secondary modal-action" (click)="cancel()">Cancel</button>
  <button *ngIf="!errorState" class="btn btn-success modal-action" (click)="save()" [disabled]="loadingData">Save</button>
</div>
