import {
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

@Component({
  selector: "image-upload",
  styleUrls: ["./image_upload.component.scss"],
  templateUrl: "./image_upload.component.html",
})
export class ImageUploadComponent {
  uploading = false;

  @Input()
  displayUploadedImage = true;

  @Input()
  prompt = "Upload a different image";

  @Input()
  fontSize = "14px";

  @Input()
  iconSize = "2rem";

  @Input()
  accept = "image/*";

  @Input()
  image =
    "https://springbot-assets.s3.us-east-1.amazonaws.com/images/email_popups/imageUploadBackground.png";

  @Input()
  helperText: string;

  @Output()
  upload = new EventEmitter();

  files: File[] = [];

  onDrop(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }

  allowDrop(event: Event): void {
    event.preventDefault();
  }

  onSelectFiles(event: Event): void {
    const inputElement = event.target as HTMLInputElement; // required for typescript
    this.upload.emit({ files: inputElement.files });
    inputElement.value = ""; // resets so you can select the same file twice
    this.uploading = false;
  }

  onDropFile(event: DragEvent): void {
    event.preventDefault();
    this.upload.emit({ files: event.dataTransfer.files });
    this.uploading = false;
  }
}
