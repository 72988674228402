import { Component } from "@angular/core";

import { AuthorizationService } from "@app/shared/services/authorization.service";
import { StoreService } from "@app/shared/services/store.service";
import { Store } from "@models/store";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { take } from "rxjs/operators";
import { Authorization } from "@models/authorization";

@Component({
  selector: "facebook-config-modal",
  styleUrls: ["./facebook_config_modal.component.scss"],
  templateUrl: "./facebook_config_modal.component.html",
})

export class FacebookConfigModalComponent {
  errorState: boolean;
  loadingData = true;
  loadingStore = true;
  store: any;
  auth: Authorization;
  connected: boolean;
  errors = [];
  authNeeded: boolean;
  neededAuthStrings = []; // used specifically for alert messages
  neededSelectionStrings = []; // used specifically for alert messages
  badgeStatus = null;
  featureCount = null;
  error = false;
  formState = null;
  adDDTouched = false; // ad dropdown touched
  facebookAdAccount = null;
  pageDDTouched = false; // page dropdown touched
  adsFeatureActivated: boolean;
  messengerFeatureActivated: boolean;
  loadingAdAccount: boolean;
  facebookPage = null;
  selectedPageId: string;
  selectedAdAccountId: string;

  constructor(private activeModal: NgbActiveModal,
              private authorizationService: AuthorizationService,
              private storeService: StoreService) {
  }

  ngOnInit() {
    this.getStore();
    this.getAuth();
  }

  save() {
    this.activeModal.close({ connected: true, errored: false });
  }

  cancel() {
    this.activeModal.dismiss();
  }

  isAdActivatedAndAuthed(): boolean {
    return !!this.auth && this.adsFeatureActivated && this.auth.adsEnabled;
  }

  // returns true if springbot fb ad feature is activated but not authorized
  isAdAuthNeeded(): boolean {
    if (!this.adsFeatureActivated) {
      return false;
    }
    return !this.auth.adsEnabled;
  }

  // returns true if springbot fb messenger feature is activated but not authorized
  isMessengerAuthNeeded(): boolean {
    if (!this.messengerFeatureActivated) {
      return false;
    }
    return !this.auth.messengerEnabled;
  }

  // boolean to determine if ANY authorizations are needed
  isAuthNeeded() {
    return !this.auth || !this.auth.enabled || !this.auth.working
        || this.isMessengerAuthNeeded() || this.isAdAuthNeeded();
  }

  // ex: a feature was recently turned on, but authorization has not been given
  isPartiallyAuthed() {
    return !!this.auth && this.auth.enabled && this.auth.working
        && (this.isMessengerAuthNeeded() || this.isAdAuthNeeded());
  }

  // you only need an ad account set if the ad feature is activated
  isUpdateNeeded() {
    if (!this.facebookPage) {
      return true;
    } if (!this.adsFeatureActivated) {
      return false;
    }
    return !this.facebookAdAccount;
  }

  // selection for ad account is intentionally post ad authorization
  getNeededSelectionStrings(): string[] {
    const neededSelections = [];
    if (this.auth && !this.facebookPage) {
      neededSelections.push("Page");
    }
    if (this.isAdActivatedAndAuthed() && !this.facebookAdAccount && this.adsFeatureActivated) {
      neededSelections.push("Ad Account");
    }
    return neededSelections;
  }

  // for the user prompt "Please reconnect to Facebook to enable..."
  getNeededAuthStrings(): string[] {
    const authsNeededStrings = [];
    const basicAuth = this.auth.enabled && this.auth.working;

    if (basicAuth && this.messengerFeatureActivated && !this.auth.messengerEnabled) {
      authsNeededStrings.push("Messenger");
    }
    if (basicAuth && this.adsFeatureActivated && !this.auth.adsEnabled) {
      authsNeededStrings.push("Ads");
    }
    return authsNeededStrings;
  }

  // returns the three possible badge statuses
  getBadgeStatus() {
    if (this.isAuthNeeded()) {
      return "issue";
    }
    if (this.isUpdateNeeded()) {
      return "update";
    }
    return "connected";
  }

  setFacebookPage(): void {
    if (this.auth && this.auth.page) {
      this.facebookPage = this.auth.page;
    }
  }

  // sets the initial facebook ad account from facebook api data
  setFacebookAdAccount(): void {
    if (this.auth && this.auth.adAccount) {
      const { adAccount, adAccounts } = this.auth;
      this.facebookAdAccount = adAccounts.find(({ id }) => id === adAccount.id);
    }
  }

  resetFacebookPage() {
    this.facebookPage = null;
    this.auth.page = null;
  }

  resetAdAccount() {
    this.facebookAdAccount = null;
    this.auth.adAccount = null;
  }

  clearAuth() {
    this.authorizationService.destroy("facebook").subscribe((response) => {
      this.activeModal.close({ connected: false, errored: false, data: response });
    });
  }

  updateAdAccount() {
    const params = {
      facebook_ad_account: this.selectedAdAccountId,
    };

    this.loadingAdAccount = true;
    this.storeService.updateStore(params).subscribe((response) => {
      this.formState = "success";
      this.getAuth();
      setTimeout(() => this.formState = null, 3000);
    }, (response) => {
      this.errors = response.errors;
      this.formState = "error";
    });
  }

  updatePage() {
    const params = {
      facebook_page: this.selectedPageId,
    };

    this.storeService.updateStore(params).subscribe((response) => {
      this.formState = "success";
      this.getAuth();
      setTimeout(() => this.formState = null, 3000);
    }, (response) => {
      this.errors = response.errors;
      this.formState = "error";
      setTimeout(() => this.formState = null, 3000);
    });
  }

  private postAuthSuccess() {
    this.setFacebookPage();
    this.setFacebookAdAccount();
    // derive boolean values
    this.authNeeded = this.isAuthNeeded();
    // set strings and messages for user auth guidance
    this.neededAuthStrings = this.getNeededAuthStrings();
    this.neededSelectionStrings = this.getNeededSelectionStrings();
    this.badgeStatus = this.getBadgeStatus();
    this.featureCount = [
      this.adsFeatureActivated,
      this.messengerFeatureActivated,
    ].filter((bool) => bool).length;
  }

  private getAuth(): void {
    this.authorizationService.getAuthorization("facebook").subscribe((auth) => {
      this.connected = !!(auth.enabled);
      this.errors = auth.errors;
      this.auth = auth;
      this.loadingData = false;
      this.loadingAdAccount = false;
      this.postAuthSuccess();
    }, (error) => {
      this.loadingData = false;
      this.loadingAdAccount = false;
    });
  }

  private getStore(): void {
    this.storeService.getStore().pipe(take(1)).subscribe((store: Store) => {
      this.store = store;
      this.loadingStore = false;
    }, (error) => {
      this.errors = [error];
      this.loadingStore = false;
    });
  }
}
