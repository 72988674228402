import { NodePath } from "./node_path";

export class Node {
  childrenIds?: number[];
  id?: number;
  flowId: number;
  nodableId?: number;
  nodableType?: string;
  parentId?: number;
  path?: NodePath;
  nodable?: any;
}
