import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";

import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { BillingAccount } from "@models/billing_account";
import { Response } from "@models/response";
import { environment } from "@environments/environment";
import { ScriptService } from "@app/shared/services/script.service";

const CHARGEBEE_SCRIPT_PATH = 'https://js.chargebee.com/v2/chargebee.js';
declare const Chargebee;

interface Status extends Object {
  status: string;
}

@Injectable({
  providedIn: "root",
})
export class BillingAccountService {
  readonly renderer: Renderer2;

  private billingAccountSource$ = new BehaviorSubject<any | null>(null);
  private chargebeeSource$ = new BehaviorSubject<any | null>(null);
  private chargebeePortalSessionSource$ = new BehaviorSubject<any | null>(null);
  private chargebeePortalInstanceSource$ = new BehaviorSubject<any | null>(null);

  // Event Emitters
  private onLoadEmitterSubject: Subject<any> = new Subject<any>();
  readonly onLoadEmitter$ = this.onLoadEmitterSubject.asObservable();

  private onPortalLoadedEmitterSubject: Subject<any> = new Subject<any>();
  readonly onPortalLoadedEmitter$ = this.onPortalLoadedEmitterSubject.asObservable();

  private onPortalCloseEmitterSubject: Subject<any> = new Subject<any>();
  readonly onPortalCloseEmitter$ = this.onPortalCloseEmitterSubject.asObservable();

  constructor(
      private http: HttpClient,
      private scriptService: ScriptService,
      rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  initializeBilling(): Observable<any> {
    return new Observable<any>((observer) => {
      this.show().subscribe((account) => {
        this.setBillingAccountSource(account);
        this.portalSession().subscribe((response) => {
          this.setChargebeePortalSessionSource(response);
          this.configureChargebee().subscribe((response) => {
            observer.next({
              billingAccount: this.billingAccountSource$.value,
              chargebee: this.chargebeeSource$.value,
              chargebeePortalSession: this.chargebeePortalSessionSource$.value,
            });
          },(error) => {
            observer.error(error);
          });
        }, (error) => {
          observer.error(error);
        });
      }, (error) => {
        observer.error(error);
      });
    });
  }

  show(params?: any): Observable<BillingAccount> {
    const path = `${environment.apiUrl}/v2/billing_account`;

    return this.http.get<Response<BillingAccount>>(path, { params }).pipe(
      map((response) => {
        this.setBillingAccountSource(response.data);
        return response.data;
      }),
    );
  }

  update(billing: BillingAccount): Observable<Status> {
    const path = `${environment.apiUrl}/v2/billing_account`;

    return this.http.put<Status>(path, billing).pipe(map((response) => response));
  }

  purchaseHistory(params?: any) {
    const path = `${environment.apiUrl}/v2/billing_account/purchase_history`;
    return this.http.get<any>(path, { params }).pipe(
      catchError((err) => of({ isError: true, error: err })),
    );
  }

  hostedCheckout(params?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const path = `${environment.apiUrl}/v2/billing_account/hosted_checkout`;
      this.http.get<any>(path, { params }).subscribe((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  portalSession(): Observable<any> {
    const path = `${environment.apiUrl}/v2/billing_account/portal_session`;
    return this.http.get<any>(path, {}).pipe(
        catchError((err) => of({ isError: true, error: err })),
    );
  }

  downgradeToFree(params?: any): Observable<any> {
    const path = `${environment.apiUrl}/v2/billing_account/downgrade_to_free`;
    return this.http.get<any>(path, { params }).pipe(
        catchError((err) => of({ isError: true, error: err })),
    );
  }

  setBillingAccountSource(billingAccount: any | null): void {
    console.log("Setting Billing Account")
    this.billingAccountSource$.next(billingAccount);
  }

  getBillingAccountSource(): BehaviorSubject<any | null> {
    return this.billingAccountSource$.getValue();
  }

  setChargebeeSource(chargebee: any | null): void {
    console.log("Setting Chargebee")
    this.chargebeeSource$.next(chargebee);
  }

  getChargebeeSource(): BehaviorSubject<any | null> {
    return this.chargebeeSource$.getValue();
  }

  setChargebeePortalSessionSource(chargebeePortalSession: any | null): void {
    console.log("Setting Chargebee Portal Source")
    this.chargebeePortalSessionSource$.next(chargebeePortalSession);
  }

  getChargebeePortalSessionSource(): BehaviorSubject<any | null> {
    console.log("Setting Chargebee Session Source")
    return this.chargebeePortalSessionSource$;
  }

  setChargebeePortalInstanceSource(chargebeePortalInstance: any | null): void {
    console.log("Setting Chargebee Portal Instance Source")
    this.chargebeePortalInstanceSource$.next(chargebeePortalInstance);
  }

  getChargebeePortalInstanceSource(): BehaviorSubject<any | null> {
    return this.chargebeePortalInstanceSource$;
  }

  openChargebeePortal(sectionType: string): BehaviorSubject<any> {
    this.chargebeePortalInstanceSource$.getValue().open(this.chargebeeCallbacks(),
      {
        sectionType: Chargebee.getPortalSections()[sectionType],
        params: {
          subscriptionId: this.billingAccountSource$.getValue().subscriptionId
        }
      });
    return this.chargebeePortalInstanceSource$;
  }

  configureChargebee(): Observable<any> {
    return new Observable<any>((observer) => {
      const start = new Date();
      const scriptElement = this.scriptService.loadJsScript(this.renderer, CHARGEBEE_SCRIPT_PATH);
      scriptElement.onload = () => {
        this.setChargebeeSource(Chargebee.init({
          site: this.billingAccountSource$.getValue().chargbeeSiteName,
          enableGATracking: true,
          enableGTMTracking: true,
        }));

        this.chargebeeSource$.getValue().setPortalSession(() => {
          return new Promise((resolve) => {
            resolve(this.chargebeePortalSessionSource$.getValue());
          });
        });

        this.setChargebeePortalInstanceSource(this.chargebeeSource$.getValue().createChargebeePortal());

        setTimeout(() => {
          const end = new Date();
          const time = end.getTime() - start.getTime();
          Chargebee.registerAgain();
          this.chargebeeSource$.getValue().setPortalCallbacks(this.chargebeeCallbacks());
          console.debug('Chargebee loaded:', time / 100, 'seconds');
          this.onLoadEmitterSubject.next(true);
          observer.next(true);
        }, 1);
      };
      scriptElement.onerror = () => {
        console.error('Could not load the Chargebee Script!');
        observer.error(false);
      };
    });
  }

  chargebeeCallbacks(): any {
    return {
      loaded: (data) => {
        this.onPortalLoadedEmitterSubject.next(data);
      },
      close: (data) => {
        this.onPortalCloseEmitterSubject.next(data);
      },
      visit: (sectionName) => {
        // Optional
        // called whenever the customer navigates across different sections in portal
      },
      paymentSourceAdd: () => {
        // Optional
        // called whenever a new payment source is added in portal
      },
      paymentSourceUpdate: () => {
        // Optional
        // called whenever a payment source is updated in portal
      },
      paymentSourceRemove: () => {
        // Optional
        // called whenever a payment source is removed in portal.
      },
      subscriptionChanged: (data) => {
        // Optional
        // called whenever a subscription is changed
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionCustomFieldsChanged: (data) => {
        // Optional
        // called whenever a subscription custom fields are changed
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionCancelled: (data) => {
        // Optional
        // called when a subscription is cancelled
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionPaused: (data) => {
        // Optional
        // called when a subscription is Paused.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionResumed: (data) => {
        // Optional
        // called when a paused subscription is resumed.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      scheduledPauseRemoved: (data) => {
        // Optional
        // called when the schedule to pause a subscription is removed for that subscription.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      scheduledCancellationRemoved: (data) => {
        // Optional
        // called when the schedule to cancel a subscription is removed for that subscription.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionReactivated: (data) => {
        // Optional
        // called when a cancelled subscription is reactivated.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
    };
  }
}
