import { DynamicSegmentSyncChecks } from "@models/dynamic_segment_sync_checks";
import { SmsStoreSettings } from "@models/sms/store_settings";
import { StoreBranding } from "@models/store_branding";
import { TriggeredEmailConfiguration } from "@models/triggered_emails/triggered_email_configuration";
import { Base } from "@models/base";

interface EmailSettings {
  defaultSenderName: string;
  defaultCustomerName: string;
  defaultFromEmail: string;
  verifiedEmail: boolean;
  oneClickUnsubscribe: boolean;
  enableDoubleOptIn: boolean;
  instagramAuthUri: string;
  engagementThreshold: number;
}

interface StoreAdminDetails {
  ownerEmail: string;
  prattlerToken: string;
  apiToken: string;
  adrollEid: string;
  lastLogin: string;
  fullSyncStart: string;
  fullSyncEnd: string;
  farmInit: string;
  chimpSyncStart: string;
  chimpSyncEnd: string;
  onboardingState: string;
  important: boolean;
  accountManager: string;
  matchaClientToken: string;
  adrollWorking: boolean;
  status: string;
  intercomUrl?: string;
  allSubscribersCsvUrl?: string;
}

interface BillingPackage {
    adSpend: number;
    adsIndustryRestricted: boolean;
    aiRecommendationsEnabled: boolean;
    billingPaused: boolean;
    createdAt: Date;
    segmentsEnabled: boolean;
    customSegmentLimit: number;
    customSegmentsEnabled: boolean;
    dataRateLimit: number;
    dataUnits: number;
    dynamicAdsEnabled: boolean;
    dynamicSegmentLimit: number;
    emailCampaignServices: boolean;
    emailSendLimit: number;
    emailSendsAddonCount: number;
    trialEmailSendCap: number;
    trialEmailsSent: number;
    facebookCampaignServices: boolean;
    limitedAutomations: boolean;
    multivariateTestingEnabled: boolean;
    oaIndustryRestricted: boolean;
    onsiteAbandonmentEnabled: boolean;
    onsiteAbandonmentSendLimit: number;
    oaSendsAddonCount: number;
    packageId: string;
    packageName: string;
    packageVersion: string;
    subscriptionStatus: string;
    smsIndustryRestricted: boolean;
    smsKeywordLimit: number;
    smsMessageLimit: number;
    staticAdsEnabled: boolean;
    storeId: number;
    uniqueCouponCodesEnabled: boolean;
    updatedAt: Date;
    renewalDate: Date;
    managedDomainEnabled: boolean;
    managedDomainTrialStartedAt: Date;
}

export class Store extends Base {
  // Store settings
  name: string;
  url: string;
  emailProviderName: string;
  setupWizardState: string;
  adrollChannelId: number;
  isUsingSpringbotProvider: boolean;
  requiresDoubleOptIn: boolean;
  requiresMasterTemplateUpgrade: boolean; // TODO: LEE_TODO: actually get this data
  authorizedToUseCoupons: boolean;
  googleSiteKey: string;
  googleSecretKey: string;
  guid: string;
  smileIsConnected: boolean;
  smsNumber: string;
  storeOptedIn: boolean;
  // address fields
  streetAddress: string;
  address: string;
  aptSuiteNum: string;
  postalCode: string;
  country: string;
  stateProvince: string;
  city: string;
  currencyCode: string;
  timeZone: string;
  timeZoneUtcOffset: number;
  createdAt: Date;
  gdprSystemWideOptInEnabled: boolean;
  removeCustomerDataEnabled: boolean;
  // orderStatuses merged with defaults for the store platform
  unmappedStatuses: string[];
  mergeOrderStatuses: {
    ignores?: string[];
    sales?: string[];
    cancels?: string[];
  };
  triggeredEmailConfiguration: TriggeredEmailConfiguration;
  // needed for setup wizard do not live in a specific back-end model
  emailSettings: EmailSettings;
  branding: StoreBranding;
  smsStoreSettings: SmsStoreSettings;
  setupWizard: boolean;
  smsMarketingKeywordLimit: number;
  smsMarketingMessageLimit: number;
  canUpdatePreviewEmailRecipients: boolean;
  redirHostname: string;
  hasAbrs: boolean;
  production: boolean;
  dynamicSegmentSyncChecks: DynamicSegmentSyncChecks;
  googleDataStudioUrl: string;
  accountKey: string;
  storeOwnerConfirmed: boolean;
  allSubscribersCount: number;

  // Store Toggles
  canUseBackInStockTriggers: boolean;
  canUseOnsiteAbandonment: boolean;
  canUseOnsiteAbandonmentBeta: boolean;
  canUseSMSMarketing: boolean;
  canUseSMSAutomations: boolean;
  canUseDoNotProcessDetails: boolean;
  canUseCustomUserRoles: boolean;
  canUseGdprSystemWideOptIn: boolean;
  frontendBetaEnabled: boolean;
  frontendBetaForced: boolean;
  allowNewBillingPackages: boolean;
  canUseAdroll: boolean;
  canSendEmailPreviews: boolean;
  managedDomainEnabled: boolean;

  // Store Cart Integration
  platformConnected: boolean;
  platformName: string;
  platformPlanName: string;

  // Packages
  package: BillingPackage;
  hasMatcha: boolean;
  isCreditCardFailed: boolean;
  creditCardFailedAmount: number;
  hasTrialPackage: boolean;
  emailSendsThisMonth: number;

  // Admin Details
  pluginVersion: string;
  ownerId: string;
  greenlightsScore: number;
  adrollOrganizationName: string;
  accountManager: string;
  fullSyncStartedAt: Date;
  fullSyncEndedAt: Date;
  churnedAt: Date;
  harvesting: boolean;

  adminDetails?: StoreAdminDetails;
  adminNotes?: string;
  customShortUrl?: string;
  important?: boolean;
  termsOfServiceEnabled?: boolean;
  emailCampaignServices?: boolean;
  openApiKeyEnabled?: boolean;
  emergingMarketers?: boolean;
  expandingMarketers?: boolean;
  recaptchaEnabled?: boolean;
  removeCustomerData?: boolean;
  doNotProcessDetails?: boolean;
  customUserRoles?: boolean;
  gdprSystemWideOptIn?: boolean;
  smsMarketing?: boolean;
  smsAutomations?: boolean;
  springbotAdsEnabled?: boolean;
  canUseBlockConfigurableProducts?: boolean;
  sendsTriggersWithSubuser?: boolean;
  backInStockTriggerEnabled?: boolean;
  displayOnboardingChecklist?: boolean;
  aiDesignerEnabled?: boolean;
  storeTrialAttributes?: Record<string, any>;

  suspended: boolean;
  shadowBanned: boolean;

  milestones: {
    cachedAt: Date;
    hasSentSocialPosts: boolean;
    hasCreatedCouponCampaigns: boolean;
    hasSentEmailCampaign: boolean;
    hasProducts: boolean;
    hasCategories: boolean;
    hasTags: boolean;
    hasActions: boolean;
    hasKeywords: boolean;
    hasActivatedAutomations: boolean;
    firstEmailCampaignSentAt: Date;
    latestUnsentDraftCreatedAt: Date;
    firstAutomationActivatedAt: Date;
    firstSignupFormActivatedAt: Date;
    firstUpgradedToPayingPackageAt: Date;
    allSubscribersCount: number;
    lastEmailCampaignSentAt: Date
  }

  // remove these!!
  globalEmailCustomEditorEnabled: boolean;
  googleDataStudioEnabled: boolean;
  canUseSpringbotAds: boolean;
  canUseNewAdroll: boolean;

  get isTrial(): boolean {
    return this.package.packageId === 'springbot-free';
  }
}
