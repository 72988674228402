import { Injectable } from "@angular/core";
import { SocialBlastAttachment } from "@models/social/social_blast_attachment";
import { Observable } from "rxjs";

interface SocialToValidate {
  facebook: boolean;
  instagram: boolean;
  twitter: boolean;
}

@Injectable({
  providedIn: "root",
})
export class SocialBlastAttachmentService {
  validVideoTypes = ["video/mp4"];
  validJPEGTypes = ["image/jpg", "image/jpeg"];
  validImageTypes = ["image/jpeg", "image/jpg", "image/png"];
  validFileTypes = ["image/jpeg", "image/jpg", "image/png", "video/mp4", "image/gif"];
  errors: string[] = [];
  socialToValidate: SocialToValidate;
  attachments: SocialBlastAttachment[];
  attachedGIF: SocialBlastAttachment;
  attachedVideo: SocialBlastAttachment;

  validateAll(attachments: SocialBlastAttachment[],
    socialToValidate: SocialToValidate): Observable<boolean> {
    this.attachments = attachments;
    this.socialToValidate = socialToValidate;
    this.errors = [];
    this.attachedVideo = this.getAttachedVideo();
    this.attachedGIF = this.getAttachedGIF();

    if (this.attachedVideo) {
      return this.validateAttachedVideo();
    } if (this.attachedGIF) {
      return this.validateAttachedGIF();
    }
    return this.validateAttachedImages();
  }

  preValidate(
    file,
    attachments: SocialBlastAttachment[],
    socialToValidate: SocialToValidate,
  ): Observable<boolean> {
    return this.validateAll([...attachments, this.convertFileToAttachment(file)], socialToValidate);
  }

  private validateAttachedVideo(): Observable<boolean> {
    return new Observable((subscriber) => {
      if (this.attachments.length > 1) {
        this.errors.push("Video posts must only contain 1 file");
      }
      if (this.videoIsWrongType()) {
        this.errors.push("Videos files must be mp4");
      }
      if (this.videoIsTooLarge()) {
        this.errors.push(this.videoSizeErrorMessage());
      }
      if ((this.socialToValidate.instagram || this.socialToValidate.twitter)
           && this.errors.length === 0) {
        this.getVideo().then((video) => {
          if (this.socialToValidate.instagram) {
            this.validateInstagramVideo(video);
          }
          if (this.socialToValidate.twitter) {
            this.validateTwitterVideo(video);
          }

          if (this.errors.length > 0) {
            subscriber.error(this.errors);
          } else {
            subscriber.next(true);
          }

          this.revokeObjectURLS();
          subscriber.complete();
        }, (failureReason: string) => {
          subscriber.error([failureReason]);
          this.revokeObjectURLS();
          subscriber.complete();
        });
      } else {
        if (this.errors.length > 0) {
          subscriber.error(this.errors);
        } else {
          subscriber.next(true);
        }

        this.revokeObjectURLS();
        subscriber.complete();
      }
    });
  }

  private validateAttachedGIF(): Observable<boolean> {
    const twitterMaxGifSizeInKb = 15000;
    return new Observable((subscriber) => {
      if (this.attachments.length > 1) {
        this.errors.push("GIF posts must contain only 1 file");
      }
      if (this.socialToValidate.instagram) {
        this.errors.push("Instagram does not allow GIF's to be posted");
      }
      if (this.attachedGIF.imageFileSize > (twitterMaxGifSizeInKb * 1000)) {
        this.errors.push(`Twitter GIF this.attachments must be smaller than ${twitterMaxGifSizeInKb / 1000} MB`);
      }

      if (this.errors.length > 0) {
        subscriber.error(this.errors);
      } else {
        subscriber.next(true);
      }

      this.revokeObjectURLS();
      subscriber.complete();
    });
  }

  private validateTwitterVideo(video: HTMLVideoElement): void {
    this.validateVideoDuration(video, 0.5, 30, "Twitter");
  }

  private validateInstagramVideo(video: HTMLVideoElement): void {
    this.validateVideoDuration(video, 3, 60, "Instagram");
    const videoAspectRatio = video.videoWidth / video.videoHeight;
    if (videoAspectRatio < (4 / 5)) {
      this.errors.push("Instagram video proportion is too narrow. Aspect ratio must be from 16:9 (landscape) to 4:5 (portrait)");
    } else if (videoAspectRatio > (16 / 9)) {
      this.errors.push("Instagram video proportion is too wide. Aspect ratio must be from 16:9 (landscape) to 4:5 (portrait)");
    }
  }

  private validateVideoDuration(video: HTMLVideoElement, minLength: number, maxLength: number, platform: "Twitter" | "Instagram"): void {
    if (video.duration > maxLength) {
      this.errors.push(`${platform} videos cannot be longer than ${maxLength} seconds`);
    } else if (video.duration < minLength) {
      this.errors.push(`${platform} videos must be longer than ${minLength} seconds`);
    }
  }

  private videoSizeErrorMessage(): string {
    let socialBrand: string;

    if (this.socialToValidate.twitter) {
      socialBrand = 'Twitter';
    } else if (this.socialToValidate.instagram) {
      socialBrand = 'Instagram';
    } else {
      socialBrand = 'Facebook';
    }

    return `${socialBrand} videos must be less than ${this.maxVideoSize() / 1000} MB`;
  }

  private validateAttachedImages(): Observable<boolean> {
    return new Observable((subscriber) => {
      if (this.hasLinkedProductAndAttachedImages()) {
        this.errors.push("Posts with linked products cannot contain other attachments");
      }
      if (this.hasMissingImageSource()) {
        this.errors.push("Image files must be jpeg, png or gif");
      }
      if (this.hasTooLargeImageFiles()) {
        this.errors.push(this.fileSizeErrorMessage());
      }
      if (this.hasTooManyImageAttachments()) {
        this.errors.push(this.imageCountErrorMessage());
      }
      if (this.socialToValidate.instagram && this.isNotJPEG(this.attachments[0])) {
        this.errors.push("Image must be a jpeg to post to instagram");
      }
      this.getImages().then((images: HTMLImageElement[]) => {
        images.forEach((image, index) => {
          if (this.socialToValidate.instagram && index === 0) {
            // instagram only posts the first image
            this.validateInstagramImage(image);
          }
          if (this.socialToValidate.facebook) {
            this.validateFacebookImage(image);
          }
          if (this.socialToValidate.twitter) {
            this.validateTwitterImage(image);
          }
        });
        this.errors.length > 0 ? subscriber.error(this.errors) : subscriber.next(true);
      }).catch((failureReason: string) => {
        subscriber.error([failureReason]);
      }).finally(() => {
        this.revokeObjectURLS();
        subscriber.complete();
      });
    });
  }

  private validateFacebookImage(image: HTMLImageElement): void {
    if (image.naturalHeight > 30000 || image.naturalWidth > 30000) {
      this.errors.push("facebook images should be less than 30,000 pixels in any dimension");
    }
    if ((image.naturalHeight * image.naturalWidth) > 80000000) {
      this.errors.push("facebook images should be less than 80,000,000 pixels in total size");
    }
  }

  private validateInstagramImage(image: HTMLImageElement): void {
    const imageRatio = image.naturalWidth / image.naturalHeight;
    if (imageRatio < (4 / 5)) {
      this.errors.push("Instagram image proportion is too narrow. Aspect ratio must be from 1.91:1 (landscape) to 4:5 (portrait)");
    } else if (imageRatio > 1.91) {
      this.errors.push("Instagram image proportion is too wide. Aspect ratio must be from 1.91:1 (landscape) to 4:5 (portrait)");
    }
  }

  private validateTwitterImage(image: HTMLImageElement): void {
    if (image.naturalHeight < 4 || image.naturalWidth < 4) {
      this.errors.push("Twitter image must be greater than 4 pixels in any dimension");
    }
    if (image.naturalHeight > 8192 || image.naturalWidth > 8192) {
      this.errors.push("Twitter image must be less than 8192 pixels in any dimension");
    }
  }

  private isNotJPEG(attachment: SocialBlastAttachment): boolean {
    if (attachment.imageContentType === "product_image") {
      return /.*[jpg|jpeg].*/.exec(attachment.imageUrl).length === 0;
    }
    return !this.validJPEGTypes.includes(attachment.imageContentType);
  }

  private hasTooManyImageAttachments(): boolean {
    return this.attachments.length > this.maxImageAttachmentCount();
  }

  private fileSizeErrorMessage(): string {
    let socialBrand: string;
    if (this.socialToValidate.facebook) {
      socialBrand = "Facebook";
    } else if (this.socialToValidate.twitter) {
      socialBrand = "Twitter";
    } else {
      socialBrand = "Instagram";
    }
    return `${socialBrand} files must be less than ${this.maxImageSize() / 1000} MB`;
  }

  private videoIsWrongType(): boolean {
    const videoContentType = this.getAttachedVideo().imageContentType;
    return !this.validVideoTypes.includes(videoContentType);
  }

  private videoIsTooLarge(): boolean {
    return this.getAttachedVideo().imageFileSize > this.maxVideoSize() * 1000;
  }

  private hasLinkedProductAndAttachedImages(): boolean {
    const hasLinkedProducts = this.attachments.some(({ productId }) => !!productId);
    return hasLinkedProducts && this.attachments.length > 1;
  }

  private hasTooLargeImageFiles(): boolean {
    const maxImageSizeKB = this.maxImageSize();
    return this.attachments.some(({ imageFileSize }) => (
      imageFileSize && imageFileSize > maxImageSizeKB * 1000
    ));
  }

  private hasMissingImageSource(): boolean {
    return this.attachments.some((attachment) => (
      !(attachment.productUrl || attachment.file)
      && !this.validImageTypes.includes(attachment.imageContentType)
    ));
  }

  // returns a temporary SocialBlastAttachment to handle file pre-validation
  private convertFileToAttachment(file: File): SocialBlastAttachment {
    return {
      imageFileName: file.name,
      imageFileSize: file.size,
      imageUpdatedAt: "",
      imageContentType: this.getContentType(file.type),
      file,
      imageUrl: URL.createObjectURL(file),
      temporary: true,
    };
  }

  // performs memory clean-up for any object urls made for temporary attachment validations
  private revokeObjectURLS(): void {
    this.attachments.filter(({ temporary }) => temporary)
      .forEach(({ imageUrl }) => {
        URL.revokeObjectURL(imageUrl);
      });
  }

  private getAttachedVideo(): SocialBlastAttachment {
    return this.attachments.find(({ imageContentType }) => imageContentType.includes("video"));
  }

  private getAttachedGIF(): SocialBlastAttachment {
    return this.attachments.find(({ imageContentType }) => imageContentType === "image/gif");
  }

  private maxImageAttachmentCount(): number {
    if (this.isOnlyValidatingInstagram()) {
      return 1;
    } if (this.socialToValidate.twitter) {
      return 4;
    }
    return 5; // facebook
  }

  // Instagram only allows posting 1 attachment
  // if only posting to instagram there must only be 1 attachment
  // if posting to instagram and another platform,
  // instagram only gets the first attachment
  private isOnlyValidatingInstagram(): boolean {
    return this.socialToValidate.instagram
      && !this.socialToValidate.twitter
      && !this.socialToValidate.facebook;
  }

  private imageCountErrorMessage(): string {
    const maxImageCount = this.maxImageAttachmentCount();
    let socialBrand: string;
    if (maxImageCount === 1) {
      socialBrand = "Instagram";
    } else if (maxImageCount === 4) {
      socialBrand = "Twitter";
    } else {
      socialBrand = "Facebook";
    }
    return `${socialBrand} must have no more than ${maxImageCount} attached images`;
  }

  private maxImageSize(): number {
    if (this.socialToValidate.facebook) {
      return 4000; // 4MB
    } if (this.socialToValidate.twitter) {
      return 5000; // 5MB
    }
    return 8000; // 8MG (instagram)
  }

  // NOTE: technically the Facebook max is 10GB but that seems unreasonably large
  // and likely to fail or get interrupted
  // 100MB instagram max 500MB twitter max
  private maxVideoSize(): number {
    return this.socialToValidate.twitter ? 15000 : 100000; // 15MB twitter max, 100MB instagram max
  }

  private getContentType(type: string): string {
    if (["image/", "video/"].some((prefix) => type.startsWith(prefix))) {
      return type;
    } if (["gif", "png", "jpeg", "jpg"].includes(type)) {
      return `image/${type}`;
    } if (type === "mp4") {
      return "video/mp4";
    }
    return "INVALID";
  }

  private getImage(attachment: SocialBlastAttachment): Promise<HTMLImageElement> {
    const image = new Image();
    return new Promise((resolve, reject) => {
      image.onload = () => resolve(image);
      // eslint-disable-next-line prefer-promise-reject-errors
      image.onerror = () => reject("File cannot be processed correctly, please try again");
      image.src = attachment.imageUrl;
    });
  }

  private getImages(): Promise<HTMLImageElement[]> {
    let imagePromises;
    if (this.isOnlyValidatingInstagram()) {
      imagePromises = [this.getImage(this.attachments[0])];
    } else {
      imagePromises = this.attachments.map((attachment) => this.getImage(attachment));
    }
    return Promise.all(imagePromises);
  }

  private getVideo(): Promise<HTMLVideoElement> {
    const video: HTMLVideoElement = document.createElement("video");
    return new Promise((resolve, reject) => {
      video.preload = "metadata";
      video.onloadedmetadata = () => resolve(video);
      // eslint-disable-next-line prefer-promise-reject-errors
      video.onerror = () => reject("File cannot be be processed correctly, please try again");
      video.src = this.getAttachedVideo().imageUrl;
    });
  }
}
