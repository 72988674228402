import { Component, Input, ViewChild, ElementRef } from "@angular/core";
import { TwitterService } from "@app/social/services/twitter.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { fromEvent } from 'rxjs';import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter
} from "rxjs/operators";

@Component({
  selector: "twitter-locations",
  styleUrls: ["./twitter_locations.component.scss"],
  templateUrl: "./twitter_locations.component.html",
})
export class TwitterLocationsComponent {
  @ViewChild('placeSearch', { static: true }) placeSearch: ElementRef;

  @Input()
  selectedPlace: { id: string, name: string };

  @Input()
  selectedPlaceId: string;

  @Input()
  twitterAuthed: boolean;

  places: { id: string, name: string }[] = [];

  constructor(private twitterService: TwitterService,
    public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    fromEvent(this.placeSearch.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      }),
      filter(res => res.length > 2),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(this.getPlaces);

    if (this.selectedPlaceId && !this.selectedPlace?.name) {
      this.setSelectedPlace();
    }
  }

  updateSelectedPlace(): void {
    this.selectedPlace = this.places.find(({ id }) => id === this.selectedPlaceId);
  }

  getPlaces(searchText: string): void {
    this.twitterService.getPlaces({ query: searchText }).subscribe((places) => {
      this.places = places;
    });
  }

  setSelectedPlace(): void {
    this.twitterService.getPlace(this.selectedPlaceId).subscribe((twitterPlace) => {
      this.selectedPlace = twitterPlace;
    });
  }

  accept(): void {
    this.activeModal.close(this.selectedPlace);
  }
}
