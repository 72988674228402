import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { UserService } from "@app/shared/services/user.service";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class UserOwnerGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.userService
      .getSource()
      .asObservable()
      .pipe(
        filter((user) => user !== null),
        map((user) => {
          if (user.currentStore && !user.currentStore.canUseCustomUserRoles) {
            return true;
          }
          if (
            user.currentRole &&
            user.currentRole.role === "campaign_manager"
          ) {
            // TODO: Defer role checking to component
            this.router.navigateByUrl("/email_campaigns");
            return false;
          }
          return true;
        })
      );
  }
}
