import { Injectable } from "@angular/core";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { getCSRFToken } from "@app/shared/services/csrf_token.service";
import { TriggeredEmail } from "@models/triggered_email";
import { DeserializeService } from "@app/shared/services/deserialize.service";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class TriggeredEmailsService {

  constructor(
    private http: HttpClient,
    private deserializeService: DeserializeService,
  ) {}

  show(triggeredEmailId: number, params?: any): Observable<TriggeredEmail> {
    return this.http.get<TriggeredEmail>(`${environment.apiUrl}/v2/triggered_emails/${triggeredEmailId}`, {
      params,
    }).pipe(mergeMap(async (triggeredEmailsResponse) => {
      const triggeredEmail = this.deserializeService.deserialize(triggeredEmailsResponse) as TriggeredEmail;
      return triggeredEmail;
    }));
  }

  update(id, params): Observable<TriggeredEmail> {
    return this.http.patch<TriggeredEmail>(`${environment.apiUrl}/v2/triggered_emails/${id}`,
      { data: params });
  }
}
